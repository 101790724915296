import {
  FileOperationType,
  useGetPreSignedUrl,
  useFetchS3Contents,
  GetPresignedUrlRequest,
  useGetExecution,
} from 'src/hooks';
import { ComparisonGroupType, PolicyExecutionResponse, SourceType } from 'src/models';
import { Nullable } from 'src/types';
import { SERVICE } from 'src/paths';

export interface PolicyContentData {
  policyId: string;
  policyName: string;
  domains: string[];
  originalFilePath: string;
  originalContent?: string;
  editedContent?: string;
  editedFilePath?: string;
  group: ComparisonGroupType;
  type: SourceType;
}

export interface UsePolicyDetailsResult {
  responseState: {
    getExecutionResponse: Nullable<PolicyExecutionResponse>;
    policyContentData: PolicyContentData[];
  };
  isLoading: boolean;
  isError: boolean;
  error: unknown | null;
}

export const usePolicyDetails = (executionId: string, version: number): UsePolicyDetailsResult => {
  const {
    data: policyExecutionResponse,
    isLoading: isLoadingExecution,
    isError: isErrorExecution,
    error: executionError,
  } = useGetExecution({
    executionId,
    version,
  });

  const preSignedUrlRequests: GetPresignedUrlRequest[] =
    policyExecutionResponse?.policies.map((policy) => ({
      fileOperationType: FileOperationType.DOWNLOAD,
      service: SERVICE.PES,
      s3FilePath: policy.s3Path!,
    })) || [];

  if (policyExecutionResponse?.policies.some((policy) => policy.editedS3Path)) {
    preSignedUrlRequests.push(
      ...policyExecutionResponse.policies
        .filter((policy) => policy.editedS3Path)
        .map((policy) => ({
          fileOperationType: FileOperationType.DOWNLOAD,
          service: SERVICE.PES,
          s3FilePath: policy.editedS3Path!,
        })),
    );
  }

  const preSignedUrlQueries = useGetPreSignedUrl(preSignedUrlRequests);
  const isLoadingPreSignedUrls = preSignedUrlQueries.some((query) => query.isLoading);
  const isErrorPreSignedUrls = preSignedUrlQueries.some((query) => query.isError);

  const preSignedUrls = preSignedUrlQueries
    .reduce<string[]>((urlList, query) => {
      if (query.isSuccess) {
        // TODO: Fix the below issue
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        urlList.push(query.data.preSignedUrl);
      }
      return urlList;
    }, [])
    .filter((url): url is string => !!url);

  const contentQueries = useFetchS3Contents({ preSignedUrls });
  const isLoadingContents = contentQueries.some((query) => query.isLoading);
  const isErrorContents = contentQueries.some((query) => query.isError);

  const policyContentData: PolicyContentData[] =
    policyExecutionResponse?.policies.map((policy, index) => ({
      policyId: policy.policyId,
      domains: policy.domains,
      group: policy.comparisonGroup,
      type: policy.sourceType,
      policyName: policy.name,
      originalFilePath: policy.s3Path!,
      editedFilePath: policy.editedS3Path,
      originalContent: contentQueries[index]?.data,
      editedContent:
        policy.editedS3Path &&
        contentQueries[preSignedUrlRequests.findIndex((req) => req.s3FilePath === policy.editedS3Path)]?.data,
    })) || [];

  const isLoading = isLoadingExecution || isLoadingPreSignedUrls || isLoadingContents;
  const isError = isErrorExecution || isErrorPreSignedUrls || isErrorContents;
  const error =
    executionError ||
    preSignedUrlQueries.find((query) => query.isError)?.error ||
    contentQueries.find((query) => query.isError)?.error;

  return {
    responseState: {
      getExecutionResponse: policyExecutionResponse,
      policyContentData,
    },
    isLoading,
    isError,
    error,
  };
};
