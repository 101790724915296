import React from 'react';
import { Col, Row, View } from '@amzn/stencil-react-components/layout';
import { H2, Text } from '@amzn/stencil-react-components/text';
import { deserializeFromMarkdown, HtmlRenderer } from '@amzn/stencil-react-components/rte';
import { Card } from '@amzn/stencil-react-components/card';
import GEN_AI_LOGO from 'src/assets/GEN_AI_LOGO.svg';
import { Feedback } from 'src/components/feedback';
import { FeedbackUseCase } from 'src/models';

export interface ComparisonData {
  comparisonData: string;
  executionId?: string;
}

const PolicyAuditDetailsPage = ({ comparisonData, executionId }: ComparisonData) => {
  return (
    <Col width={'100%'}>
      <Row justifyContent="space-between" alignItems={'center'}>
        <Col width={'60%'}>
          <Row alignItems="center" gridGap="S100">
            <View width="1.5rem" height="1.5rem" justifyContent="center" alignItems="center">
              <img src={GEN_AI_LOGO} alt="GEN AI Logo" />
            </View>
            <H2>Policy Audit</H2>
          </Row>
          <Text margin={{ left: 'S100' }} fontStyle="italic" fontSize="T100" fontWeight="light">
            *This feature is powered by Generative AI and may not always be accurate.
          </Text>
        </Col>
        <Feedback feedbackUseCase={FeedbackUseCase.ASSESSMENT_DETAILS} referenceId={executionId} />
      </Row>
      <Card isElevated={true} margin={{ top: 'S300' }}>
        <Col padding={'1rem'} width="100%">
          <HtmlRenderer html={deserializeFromMarkdown(comparisonData) as string} />
        </Col>
      </Card>
    </Col>
  );
};

export default PolicyAuditDetailsPage;
