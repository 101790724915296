import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { TdElement, ThElement } from '@amzn/stencil-react-components/table';

export const StyledThElement = styledWithTheme(ThElement)(({ theme }) => ({
  backgroundColor: theme.selectors.color('neutral0'),
}));

const tdElementStyle = `
    border-top: none !important;
    border-bottom: 1px solid;
    border-color: #e8eaee;
  `;

export const StyledTdElement = styledWithTheme(TdElement)`
    ${tdElementStyle}
  `;
