import ExtendedRisk from 'src/components/dashboard/models/ExtendedRisk';
import { PolicyMetadata, Risk } from 'src/models';
import { AuditView } from 'src/components/riskdetails/constants';

export const populatePolicyDetails = (risks: Risk[] = [], policiesMetadata: PolicyMetadata[] = []): ExtendedRisk[] => {
  const policiesMetadataMap = new Map<string, PolicyMetadata>();
  policiesMetadata.forEach((policyMetadata: PolicyMetadata) => {
    policiesMetadataMap.set(policyMetadata.policyId, policyMetadata);
  });

  return risks.map((risk: Risk) => {
    const policyMetadata = policiesMetadataMap.get(risk.policyId);
    return {
      ...risk,
      policyName: policyMetadata?.name || '-',
      country: policyMetadata?.policyMetadataAttributes?.country || '-',
    };
  });
};

export const RISK_FIELD_AUDIT_VIEW_MAP: { [key: string]: AuditView } = {
  title: AuditView.TITLE,
  details: AuditView.DETAILS,
  status: AuditView.STATUS,
  priority: AuditView.PRIORITY,
  fixByDate: AuditView.FIX_BY_DATE,
};
