import React from 'react';
import { Chip, ChipBackgroundColor, ChipSize } from '@amzn/stencil-react-components/chip';
import { DetailedCheckbox } from '@amzn/stencil-react-components/form';
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H3 } from '@amzn/stencil-react-components/text';
import { ComparisonGroupType, PolicyMetadata } from 'src/models';
import { useCreateExecutionWorkflowContext } from '../context';

interface PolicyTileProps {
  viewMode?: boolean;
  checked?: boolean;
  group: ComparisonGroupType;
  policyMetadata: PolicyMetadata;
}

export const PolicyTile: React.FC<PolicyTileProps> = ({ viewMode, policyMetadata, group }) => {
  const { selectedPolicies, updateSelectedPolicies } = useCreateExecutionWorkflowContext();

  const isSelected = selectedPolicies[group].some((p) => p.policyId === policyMetadata.policyId);

  const PolicyTitle = () => {
    return (
      <Row justifyContent="space-between" margin="S100" width="100%">
        <Row margin={'auto 0px'}>
          <H3>{policyMetadata.name}</H3>
        </Row>
        <Row>
          <Spacer width={'S200'} />
          {policyMetadata?.domains?.map((domain) => (
            <>
              <Chip key={`${policyMetadata.policyId}-${domain}`} size={ChipSize.Small}>
                {domain}
              </Chip>
              <Spacer width={'S200'} />
            </>
          ))}
          <Chip size={ChipSize.Small} backgroundColor={ChipBackgroundColor.Blue}>
            {policyMetadata.sourceType}
          </Chip>
          <Spacer width={'S200'} />
        </Row>
      </Row>
    );
  };

  const PolicyDetails = () => {
    return (
      <Col margin="S100" width="100%">
        <Col minHeight={'S500'}>{policyMetadata.description}</Col>
      </Col>
    );
  };

  return (
    <Col margin="S200">
      <DetailedCheckbox
        checked={isSelected}
        disabled={viewMode}
        onChange={(event) => {
          updateSelectedPolicies(group, policyMetadata, event.target.checked);
        }}
        renderTitle={() => <PolicyTitle />}
        titleText={policyMetadata.name}
        value={policyMetadata.policyId}
        details={<PolicyDetails />}
      />
    </Col>
  );
};
