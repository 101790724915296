import React from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { DatePickerLayout, PopupDateRangePicker } from '@amzn/stencil-react-components/date-picker';
import { Label } from '@amzn/stencil-react-components/text';
import { AuditTableDataType, AuditTableHeaders, AuditView } from 'src/components/riskdetails/constants';
import { useAuditTab } from 'src/components/riskdetails/hooks/useAuditTab';
import { GetRiskResponse } from 'src/models';
import { Chip, ChipSize } from '@amzn/stencil-react-components/chip';
import { formatEnumString, RISK_PRIORITY_COLOR, RISK_STATUS_COLOR } from 'src/components/utils';
import { Table } from '@amzn/stencil-react-components/table';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { convertDateTimeToFormat, DateTimeFormats } from 'src/utils';
import RiskAuditTableTextElement from 'src/components/riskdetails/elements/RiskAuditTableTextElement';

interface RiskAuditTabProps {
  getRiskResponse: GetRiskResponse;
}

const getElementByStatusType = (field?: AuditView, value: string = '-') => {
  switch (field) {
    case AuditView.STATUS:
      return (
        <Chip size={ChipSize.Default} backgroundColor={RISK_STATUS_COLOR[value]}>
          {formatEnumString(value)}
        </Chip>
      );
    case AuditView.PRIORITY:
      return (
        <Chip size={ChipSize.Default} backgroundColor={RISK_PRIORITY_COLOR[value]}>
          {formatEnumString(value)}
        </Chip>
      );
    case AuditView.FIX_BY_DATE:
      return convertDateTimeToFormat(value, DateTimeFormats.DATE_TIME_DD_MMM_YYYY_HHMM_A);
    default:
      return RiskAuditTableTextElement({ content: value, field });
  }
};
// TODO: Remove the hard coded column widths.
export const AUDIT_COLUMNS = [
  {
    header: AuditTableHeaders.FIELD,
    accessor: ({ data }: { data: AuditTableDataType }) => data.field ?? '-',
    width: '15rem',
  },
  {
    header: AuditTableHeaders.ACTION,
    accessor: ({ data }: { data: AuditTableDataType }) => formatEnumString(data.type),
    width: '15rem',
  },
  {
    header: AuditTableHeaders.ORIGINAL_VALUE,
    accessor: ({ data }: { data: AuditTableDataType }) => getElementByStatusType(data.field, data.oldValue),
    width: '25rem',
  },
  {
    header: AuditTableHeaders.UPDATED_VALUE,
    accessor: ({ data }: { data: AuditTableDataType }) => getElementByStatusType(data.field, data.newValue),
    width: '25rem',
  },
  {
    header: AuditTableHeaders.UPDATED_AT,
    accessor: ({ data }: { data: AuditTableDataType }) =>
      convertDateTimeToFormat(data.updatedAt, DateTimeFormats.DATE_TIME_DD_MMM_YYYY_HHMM_A),
    width: '20rem',
  },
  {
    header: AuditTableHeaders.UPDATED_BY,
    accessor: ({ data }: { data: AuditTableDataType }) => data.updatedBy,
    width: '15rem',
  },
];

export const RiskAuditTab: React.FC<RiskAuditTabProps> = ({ getRiskResponse }) => {
  const { startDate, endDate, getAuditData, updateDate } = useAuditTab(getRiskResponse);

  const customRenderer = ({ htmlFor, labelText }: any) => (
    <Label htmlFor={htmlFor}>
      {labelText}
      <span aria-hidden={true}>*</span>
    </Label>
  );

  return (
    <Col minWidth={'70rem'} padding={'S200'} gridGap={'S300'}>
      <Col width={'60%'}>
        <PopupDateRangePicker
          aria-label="Audit History Date Range Picker"
          startValue={startDate}
          endValue={endDate}
          layout={DatePickerLayout.Row}
          clearButtonAltText={{ startDate: 'Clear Start Date', endDate: 'Clear End Date' }}
          calendarButtonAltText={{ startDate: 'Start Date Picker', endDate: 'End Date Picker' }}
          startDateInputProps={{ 'aria-required': true, 'aria-label': 'Start Date Input Box' }}
          endDateInputProps={{ 'aria-required': true, 'aria-label': 'End Date Input Box' }}
          renderStartDateLabel={customRenderer}
          renderEndDateLabel={customRenderer}
          onChange={(startDate, endDate) => updateDate(startDate!, endDate!)}
        />
      </Col>
      <Col display={'flex'}>
        {getAuditData.length ? (
          <Table columns={AUDIT_COLUMNS} data={getAuditData} />
        ) : (
          <MessageBanner type={MessageBannerType.Informational}>No Audit trail to display for this risk</MessageBanner>
        )}
      </Col>
    </Col>
  );
};
