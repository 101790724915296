import React from 'react';
import { formatEnumString, RISK_PRIORITY_COLOR } from 'src/components/utils';
import { Row } from '@amzn/stencil-react-components/layout';
import { InputFooter, Select } from '@amzn/stencil-react-components/form';
import { RiskPriority, RiskStatus } from 'src/models';
import { EditRiskState } from 'src/components/riskdetails/hooks';
import { Chip, ChipSize } from '@amzn/stencil-react-components/chip';
import { EMPTY_SEPARATOR } from 'src/components/riskdetails/constants';

interface RiskPriorityProps {
  id: string;
  editRiskState: EditRiskState;
  setPriority: (priority: RiskPriority) => void;
}

const RiskPriorityElement = ({
  id,
  editRiskState: {
    isEditing,
    edits: { priority, status },
    showErrors,
  },
  setPriority,
}: RiskPriorityProps) => {
  const isEditable = status && ![RiskStatus.RESOLVED, RiskStatus.INVALIDATED].includes(status);
  if (isEditable && isEditing) {
    const isError = showErrors && !formatEnumString(priority);
    return (
      <>
        <Select
          id={id}
          placeholder="Select a priority"
          aria-required={true}
          aria-labelledby={`${id}-label ${id}-toggle-button`}
          aria-describedby={`${id}-footer`}
          options={Object.values(RiskPriority).map(formatEnumString)}
          value={formatEnumString(priority)}
          error={isError}
          onChange={(value: RiskPriority) => {
            setPriority(value);
          }}
        />
        {isError && (
          <InputFooter id={`${id}-footer`} error>
            Please select a priority
          </InputFooter>
        )}
      </>
    );
  }

  return priority ? (
    <Row id={id} aria-labelledby={`${id}-label`} alignItems="center">
      <Chip size={ChipSize.Default} backgroundColor={RISK_PRIORITY_COLOR[priority]}>
        {formatEnumString(priority)}
      </Chip>
    </Row>
  ) : (
    <>{EMPTY_SEPARATOR}</>
  );
};

export default RiskPriorityElement;
