export const metricConfig = {
  RegisterPolicyPage: {
    componentName: 'RegisterPolicyPage',
    actions: {
      REGISTER_POLICY_SUBMIT: 'registerPolicy.submit',
      GENERATE_TAGS_BUTTON_CLICK: 'generateTagsButton.click',
    },
  },
  PolicyMarketplace: {
    componentName: 'PolicyMarketplace',
    actions: {
      REGISTER_POLICY_BUTTON_CLICK: 'registerPolicyButton.click',
      POLICY_MARKETPLACE_LOAD_LATENCY: 'policyMarketplaceLoad.latency',
    },
  },
  PolicyDetailsPage: {
    componentName: 'PolicyDetailsPage',
    actions: {
      POLICY_DETAILS_LOAD_LATENCY: 'policyDetailsLoad.latency',
    },
  },
  RisksTable: {
    componentName: 'RisksTable',
    actions: {
      ADD_RISK_BUTTON_CLICK: 'addRiskButton.click',
      DASHBOARD_RISKS_LOAD_LATENCY: 'dashboardRisksLoad.latency',
    },
  },
  CreateRisk: {
    componentName: 'CreateRisk',
    actions: {
      ADD_RISK_SUBMIT: 'addRisk.submit',
      CREATE_RISK_LOAD_LATENCY: 'createRiskLoad.latency',
    },
  },
  PolicyExecutionTable: {
    componentName: 'PolicyExecutionTable',
    actions: {
      START_ASSESSMENT_BUTTON_CLICK: 'startAssessmentButton.click',
    },
  },
  CreateExecutionWorkflow: {
    componentName: 'CreateExecutionWorkflow',
    actions: {
      CREATE_EXECUTION_SUBMIT: 'createExecution.submit',
      CREATE_EXECUTION_WORKFLOW_LOAD_LATENCY: 'createExecutionWorkflowLoad.latency',
    },
  },
  RiskDetailsPage: {
    componentName: 'RiskDetailsPage',
    actions: {
      EDIT_RISK_BUTTON_CLICK: 'editRiskButton.click',
      EDIT_RISK_SUBMIT: 'editRisk.submit',
      RISK_DETAILS_LOAD_LATENCY: 'riskDetailsLoad.latency',
    },
  },
  AuthenticationContext: {
    componentName: 'AuthenticationContext',
    actions: {
      AUTHENTICATION_LOAD_LATENCY: 'authenticationLoad.latency',
    },
  },
  ExecutionDetailsPage: {
    componentName: 'ExecutionDetailsPage',
    actions: {
      EXECUTION_DETAILS_PAGE_LOAD_LATENCY: 'executionDetailsPageLoad.latency',
    },
  },
  WorkspaceSelectorComponent: {
    componentName: 'WorkspaceSelectorComponent',
    actions: {
      WORKSPACE_SELECTOR_LOAD_LATENCY: 'workspaceSelectorLoad.latency',
    },
  },
};
