import { useQuery } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GetNamespacesResponse } from 'src/models';
import { GET_NAMESPACES_API } from 'src/paths';
import { useAuthentication } from 'src/components';

export const useGetNamespaces = () => {
  const { authenticatedUser } = useAuthentication();
  const userId = authenticatedUser?.userName || '';

  return useQuery<GetNamespacesResponse>(
    ['namespaces', userId],
    () =>
      getData<GetNamespacesResponse>({
        path: GET_NAMESPACES_API(),
        queryParams: { userId: userId },
      }),
    {
      enabled: !!userId,
      retry: false,
      staleTime: Infinity,
    },
  );
};
