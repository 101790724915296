import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GET_PERMISSIONS_API } from 'src/paths';
import { GetPermissionsRequest, GetPermissionsResponse } from 'src/models';

export const useGetPermissions = (request: Partial<GetPermissionsRequest>): UseQueryResult<GetPermissionsResponse> => {
  const path = GET_PERMISSIONS_API(request.namespaceId);
  return useQuery<unknown, unknown, GetPermissionsResponse>(
    [request.namespaceId],
    () =>
      getData<GetPermissionsResponse>({
        path,
      }),
    {
      enabled: !!request.namespaceId,
      retry: false,
      staleTime: Infinity,
    },
  );
};
