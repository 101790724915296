import * as KatalMetrics from '@amzn/katal-metrics';
import { useEffect, useRef } from 'react';
import { logger } from 'src/logger';
import { DIMENSION_NAME, METRIC_NAME } from 'src/constants/LoggerConstants';

export interface UseComponentLoadedLatencyMetricProps {
  componentName: string;
  latencyAction: string;
  isComponentLoaded: boolean;
}

export const useComponentLoadedLatencyMetric = ({
  componentName,
  latencyAction,
  isComponentLoaded,
}: UseComponentLoadedLatencyMetricProps): void => {
  const metricTimer = useRef(new KatalMetrics.Metric.TimerStopwatch('APA_LATENCY_TIMER').withMonitor());
  // We do not want to emit latency metric once we have done it for the first render of the component
  const isFirstRender = useRef(true);

  const dimensions = {
    [DIMENSION_NAME.COMPONENT_NAME]: componentName,
    [DIMENSION_NAME.ACTION]: latencyAction,
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    if (isComponentLoaded) {
      logger.emitLatencyMetric({
        metricName: METRIC_NAME.LATENCY,
        metricValue: metricTimer.current.value,
        dimensions,
      });
    }
  }, [isComponentLoaded]);
};
