import { useEffect, useState } from 'react';
import { useGetPoliciesMetadata } from 'src/hooks';
import { useAuthentication } from 'src/components';
import { useCreateRisk } from 'src/hooks/useCreateRisk';
import { PolicyMetadata, RiskPriority, RiskStatus } from 'src/models';
import { metricConfig, RISK_DETAILS } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';
import { getClickMetricDimensions } from 'src/utils';

export const useCreateRiskComponent = (selectedNamespaceId: string) => {
  const navigate = useNavigate();
  const { authenticatedUser } = useAuthentication();

  const { createRiskState, createRisk } = useCreateRisk();
  const { getPoliciesMetadataState, getPoliciesMetadata } = useGetPoliciesMetadata();

  const [riskName, setRiskName] = useState<string>();
  const [selectedPolicy, setSelectedPolicy] = useState<PolicyMetadata>();
  const [selectedReferencePolicies, setSelectedReferencePolicies] = useState<PolicyMetadata[]>([]);
  const [selectedPriority, setSelectedPriority] = useState<RiskPriority>();
  const [selectedETA, setSelectedETA] = useState<string>();
  const [riskDetails, setRiskDetails] = useState<string>();

  const [fieldErrors, setFieldErrors] = useState({
    title: false,
    details: false,
    policyId: false,
  });

  const validateForm = () => {
    const newFieldErrors = {
      title: !riskName,
      details: !riskDetails,
      policyId: !selectedPolicy,
    };
    setFieldErrors(newFieldErrors);

    return !Object.values(newFieldErrors).some((error) => error);
  };

  const handleCreateRiskOnSubmit = () => {
    if (!validateForm()) {
      return;
    }
    logger.emitCountMetric({
      metricName: METRIC_NAME.CLICK,
      dimensions: getClickMetricDimensions(
        metricConfig.CreateRisk.componentName,
        metricConfig.CreateRisk.actions.ADD_RISK_SUBMIT,
      ),
    });
    createRisk({
      title: riskName || '',
      details: riskDetails || '',
      status: RiskStatus.ACKNOWLEDGED,
      namespaceId: selectedNamespaceId || '',
      policyId: selectedPolicy?.policyId || '',
      referencePolicyIds: selectedReferencePolicies.map((refPolicy) => refPolicy.policyId) || [],
      priority: selectedPriority,
      fixByDate: selectedETA,
    });
  };

  useEffect(() => {
    if (selectedNamespaceId) {
      getPoliciesMetadata({
        userId: authenticatedUser?.userName || '',
        namespaceId: selectedNamespaceId || '',
      });
    }
  }, [selectedNamespaceId]);

  useEffect(() => {
    if (createRiskState?.data?.riskId) {
      navigate(`${RISK_DETAILS}/${createRiskState?.data?.riskId}`);
    }
  }, [createRiskState?.data]);

  return {
    setRiskName,
    selectedPolicy,
    setSelectedPolicy,
    setSelectedReferencePolicies,
    setSelectedPriority,
    setSelectedETA,
    setRiskDetails,
    fieldErrors,
    getPoliciesMetadataState,
    createRiskState,
    handleCreateRiskOnSubmit,
  };
};
