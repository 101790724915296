import { View } from '@amzn/stencil-react-components/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { DIMENSION_NAME } from 'src/constants/LoggerConstants';
import { logger } from 'src/logger';
import { useAuthentication } from 'src/components';
import { ErrorBoundaryMetricName } from 'src/constants/ErrorBoundaryConstants';

interface PolicyAdvisorErrorFallbackComponentProps {
  componentName: string;
  currentUser?: string;
  error: Error;
}

export const PolicyAdvisorErrorFallbackComponent: React.FC<PolicyAdvisorErrorFallbackComponentProps> = (props) => {
  useEffect(() => {
    if (props.componentName != null) {
      logger.logError(
        `User ${props?.currentUser} faced following error while loading Policy Advisor Component ${props.componentName}: ${props.error?.message}`,
        props.error,
      );
      const dimensions = {
        [DIMENSION_NAME.COMPONENT_NAME]: props?.componentName,
      };
      // will only log the metrics once when a component is rendered with error
      logger.emitCountMetric({
        metricName: ErrorBoundaryMetricName,
        dimensions: dimensions,
      });
    }
  }, [props.componentName, props.error.name]);
  return (
    <View margin={8}>
      <MessageBanner type={MessageBannerType.Error}>Something went wrong, please retry.</MessageBanner>
    </View>
  );
};

interface PolicyAdvisorErrorBoundaryProps {
  componentName: string;
  children: React.ReactNode;
}

export const PolicyAdvisorErrorBoundary: React.FC<PolicyAdvisorErrorBoundaryProps> = (props) => {
  const { authenticatedUser } = useAuthentication();
  return (
    <ErrorBoundary
      FallbackComponent={(fallbackProps) => {
        return (
          <PolicyAdvisorErrorFallbackComponent
            componentName={props.componentName}
            error={fallbackProps.error}
            currentUser={authenticatedUser?.userName}
          />
        );
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
};
