const environmentMap: Record<string, any> = {
  'http://localhost': {
    name: 'development',
    stage: 'alpha',
    userPoolId: 'us-west-2_JLK8wMlv1',
    identityPoolId: 'us-west-2:5b46e7fa-5b1a-4666-b4ca-8babafec95ef',
    userPoolClientId: '1neiamvu9nl6nmo2dpkaqe6bka',
    domain: 'policy-advisor-alpha.auth.us-west-2.amazoncognito.com',
    userPoolAuthScope: ['openid', 'aws.cognito.signin.user.admin', 'email', 'profile'],
    userPoolAuthResponseType: 'code',
    userPoolIdp: 'AmazonFederate-Midway',
  },
  'https://alpha.policy-advisor.pxt.amazon.dev': {
    name: 'alpha',
    stage: 'alpha',
    userPoolId: 'us-west-2_JLK8wMlv1',
    identityPoolId: 'us-west-2:5b46e7fa-5b1a-4666-b4ca-8babafec95ef',
    userPoolClientId: '1neiamvu9nl6nmo2dpkaqe6bka',
    domain: 'policy-advisor-alpha.auth.us-west-2.amazoncognito.com',
    userPoolAuthScope: ['openid', 'aws.cognito.signin.user.admin', 'email', 'profile'],
    userPoolAuthResponseType: 'code',
    userPoolIdp: 'AmazonFederate-Midway',
  },
  'https://beta.policy-advisor.pxt.amazon.dev': {
    name: 'beta',
    stage: 'beta',
    userPoolId: 'us-west-2_JqbLocZEX',
    identityPoolId: 'us-west-2:c916589a-26bf-42fe-9f28-81d5cbba64ba',
    userPoolClientId: '2b1v5ac3h6s08jjeffmnra30ab',
    domain: 'policy-advisor-beta.auth.us-west-2.amazoncognito.com',
    userPoolAuthScope: ['openid', 'aws.cognito.signin.user.admin', 'email', 'profile'],
    userPoolAuthResponseType: 'code',
    userPoolIdp: 'AmazonFederate-Midway',
  },
  'https://policy-advisor.pxt.amazon.dev': {
    name: 'prod',
    stage: 'prod',
    userPoolId: 'us-west-2_VqFCSvA1i',
    identityPoolId: 'us-west-2:18aa3225-cebe-4d07-9a17-14149cdde382',
    userPoolClientId: '7dq3350gkm86kh19ea1r2ib0je',
    domain: 'policy-advisor.auth.us-west-2.amazoncognito.com',
    userPoolAuthScope: ['openid', 'aws.cognito.signin.user.admin', 'email', 'profile'],
    userPoolAuthResponseType: 'code',
    userPoolIdp: 'AmazonFederate-Midway',
  },
};

export const getEnvironment = (browserLocation: string) => {
  return environmentMap[browserLocation] || environmentMap['http://localhost'];
};

export const getUserPoolIdp = (browserLocation: string): string => {
  return environmentMap[browserLocation]?.['userPoolIdp'] || environmentMap['http://localhost']['userPoolIdp'];
};

export const environmentConfiguration = getEnvironment(window.location.origin);
