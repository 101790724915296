import React, { useRef } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { FileUpload } from '@amzn/stencil-react-components/file-upload';
import { FormWrapper, Input, InputWrapper, Select, TextArea } from '@amzn/stencil-react-components/form';
import { Col, Container, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { DomainType, FeedbackUseCase, SourceType } from 'src/models';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { POLICY_MARKETPLACE } from 'src/constants';
import { getPolicyMetadataCountry } from 'src/components/policy-marketplace/utils/policyFilters';
import { Card } from '@amzn/stencil-react-components/card';
import { RouterLink } from 'src/components/shared/router-link';
import { POLICY_SOURCE_TYPE_DISPLAY_CONSTANT } from 'src/constants/DisplayConstants';
import { FocusedMessageBanner } from 'src/components/shared/focused-message-banner';
import GEN_AI_LOGO from 'src/assets/GEN_AI_LOGO.svg';
import { Feedback } from 'src/components/feedback';
import { COUNTRY_OPTIONS, FormFieldErrors, JOB_CLASS_OPTIONS, usePolicyMetadataInputHook } from './hooks';

interface PolicyMetadataFormProps {
  onSubmit: () => void;
  namespaceId: string;
}

interface SelectorConfig {
  id: string;
  labelText: string;
  errorKey: keyof FormFieldErrors;
  options: any;
  value: any;
  onChange: (value: any) => void;
  isMulti?: boolean;
}

const CREATE_POLICY_METADATA_ERROR_MESSAGE = 'Unable to Create Policy due to an unexpected error';

export const PolicyMetadataInputForm: React.FC<PolicyMetadataFormProps> = ({ onSubmit, namespaceId }) => {
  const {
    name,
    description,
    sourceType,
    domain,
    policyMetadataAttributes,
    formFieldErrors,
    handleInputChange,
    handleSelectChange,
    handleSubmit,
    handleFileUpload,
    handleLinkInput,
    isSubmitting,
    handleGenerateTags,
    isRegistrationSuccessful,
    shouldDisableGenerateTagsButton,
    shouldEnableGenerateTagsLoader,
    createPolicyMetadataState,
    shouldShowStateSelection,
    stateOptions,
    trackingSources,
  } = usePolicyMetadataInputHook(namespaceId);
  const firstInputRef = useRef<HTMLInputElement>(null);

  const SELECTOR_CONFIG: SelectorConfig[] = [
    {
      id: 'policy-metadata-domain',
      labelText: 'Domain',
      errorKey: 'domain',
      options: Object.values(DomainType),
      value: domain,
      onChange: handleSelectChange('domain'),
    },
    {
      id: 'policy-metadata-country',
      labelText: 'Country',
      errorKey: 'country',
      options: COUNTRY_OPTIONS,
      value: getPolicyMetadataCountry(policyMetadataAttributes?.country as string),
      onChange: handleSelectChange('country'),
    },
  ];

  const getSourceTypeInputField = (type: SourceType | undefined) => {
    switch (type) {
      case SourceType.INSIDE:
        return (
          <InputWrapper
            required={true}
            labelText="File Upload"
            error={formFieldErrors.file.isError}
            footer={formFieldErrors.file.message}
            id="policy-metadata-file-upload"
          >
            {(inputProps) => (
              <FileUpload
                maxFiles={1}
                maxSize={50000}
                accept=".doc,.docx,.pdf,.txt"
                onFileAttached={(files, rejectedFiles, failedFiles) => {
                  //TODO: Handle rejected and failed files
                  handleFileUpload(files);
                }}
                renderTooLargeFileErrorText={(maxSize) => `The max file size is 50 KB`}
                {...inputProps}
              />
            )}
          </InputWrapper>
        );
      case SourceType.GOVT:
        return (
          <InputWrapper
            required={true}
            labelText="Link"
            id="policy-metadata-link"
            error={formFieldErrors.trackingSources.isError}
            footer={formFieldErrors.trackingSources.message}
          >
            {(inputProps) => (
              <Input
                onChange={(event) => handleLinkInput(event.target.value)}
                placeholder="Enter Website link"
                type="url"
                {...inputProps}
              />
            )}
          </InputWrapper>
        );
      default:
        return <></>;
    }
  };

  return (
    <View width={'80%'}>
      <H1 margin={{ bottom: '1rem', top: '2rem' }}>Policy Registration</H1>
      <Text aria-hidden={true}>Fields with an asterisk (*) are required</Text>
      <Spacer height={'1rem'} />
      <Card width={'100%'} justifyContent={'center'} isElevated={true}>
        <Container>
          {isRegistrationSuccessful && !createPolicyMetadataState.error && (
            <Col width={'100%'}>
              <FocusedMessageBanner
                ariaLive="off"
                type={MessageBannerType.Success}
                onDismissed={() => firstInputRef.current?.focus()}
              >
                <Text aria-live="assertive">Policy has been registered successfully!</Text>
                <Spacer height={'S200'} />
                <RouterLink aria-live="assertive" to={POLICY_MARKETPLACE}>
                  Go back to the Marketplace
                </RouterLink>
              </FocusedMessageBanner>
            </Col>
          )}
          {createPolicyMetadataState?.error && (
            <FocusedMessageBanner type={MessageBannerType.Error} onDismissed={() => firstInputRef.current?.focus()}>
              <Text>{CREATE_POLICY_METADATA_ERROR_MESSAGE}</Text>
            </FocusedMessageBanner>
          )}
          {isSubmitting || createPolicyMetadataState?.loading ? (
            <Col alignItems={'center'}>
              <Spinner size={SpinnerSize.Large} />
            </Col>
          ) : (
            <Col width={'100%'} backgroundColor={'neutral0'} gridGap={'S400'} padding={'S500'}>
              <FormWrapper>
                {formFieldErrors.generatedTags.isError && (
                  <FocusedMessageBanner
                    type={MessageBannerType.Error}
                    onDismissed={() => firstInputRef.current?.focus()}
                  >
                    {formFieldErrors.generatedTags.message}
                  </FocusedMessageBanner>
                )}
                <InputWrapper
                  id="policy-metadata-name"
                  labelText="Policy Name"
                  required={true}
                  error={formFieldErrors.name.isError}
                  footer={formFieldErrors.name.message}
                >
                  {(inputProps) => (
                    <Input
                      placeholder="Enter name"
                      value={name}
                      onChange={(e) => handleInputChange('name', e.target.value)}
                      ref={firstInputRef}
                      {...inputProps}
                    />
                  )}
                </InputWrapper>
                <InputWrapper
                  id="policy-metadata-description"
                  labelText="Policy Description"
                  required={true}
                  error={formFieldErrors.description?.isError}
                  footer={formFieldErrors.description?.message}
                >
                  {(inputProps) => (
                    <TextArea
                      resize={'vertical'}
                      placeholder="Enter description"
                      value={description}
                      onChange={(e) => handleInputChange('description', e.target.value)}
                      {...inputProps}
                    />
                  )}
                </InputWrapper>
                <InputWrapper
                  id="policy-metadata-source-type"
                  labelText="Source Type"
                  required={true}
                  error={formFieldErrors.sourceType.isError}
                  footer={formFieldErrors.sourceType.message}
                >
                  {(inputProps) => (
                    <Select
                      options={[SourceType.GOVT]}
                      value={sourceType}
                      onChange={handleSelectChange('sourceType')}
                      placeholder="Select source type"
                      {...inputProps}
                      renderOption={(option) => POLICY_SOURCE_TYPE_DISPLAY_CONSTANT[option as SourceType]}
                    />
                  )}
                </InputWrapper>
                {getSourceTypeInputField(sourceType)}
                <Spacer height={'S100'} />
                <Row justifyContent={'space-between'} alignItems="start" flexWrap={'wrap'}>
                  <Col gridGap={'S200'}>
                    <View width={'50%'}>
                      <Button
                        onClick={handleGenerateTags}
                        variant={ButtonVariant.Secondary}
                        disabled={shouldDisableGenerateTagsButton()}
                        icon={
                          <View width="2rem" height="2rem" justifyContent="center" alignItems="center">
                            <img src={GEN_AI_LOGO} alt="GEN AI Logo" />
                          </View>
                        }
                        size={ButtonSize.Default}
                      >
                        {shouldEnableGenerateTagsLoader() ? <Spinner size={SpinnerSize.Small} /> : 'Generate Tags'}
                      </Button>
                    </View>
                    <Text margin={{ left: 'S200' }} fontStyle="italic" fontSize="T100" fontWeight="light">
                      *This feature is powered by Generative AI and may not always be accurate.
                    </Text>
                  </Col>
                  <Col>
                    <Feedback
                      feedbackUseCase={FeedbackUseCase.POLICY_TAGS}
                      referenceId={JSON.stringify(trackingSources)}
                    />
                  </Col>
                </Row>
                {SELECTOR_CONFIG.map((selector) => (
                  <InputWrapper
                    key={selector.id}
                    id={selector.id}
                    labelText={selector.labelText}
                    required={true}
                    error={formFieldErrors[selector.errorKey].isError}
                    footer={formFieldErrors[selector.errorKey].message}
                  >
                    {(inputProps) => (
                      <Select
                        options={selector.options}
                        placeholder={`Select ${selector.labelText.toLowerCase()}`}
                        value={selector.value}
                        onChange={selector.onChange}
                        isMulti={selector.isMulti}
                        {...inputProps}
                      />
                    )}
                  </InputWrapper>
                ))}
                <Spacer height={'S100'} />
                {policyMetadataAttributes?.country ? (
                  <>
                    <InputWrapper
                      id="policy-metadata-job-class"
                      labelText="Job Class"
                      required={true}
                      error={formFieldErrors.jobClass.isError}
                      footer={formFieldErrors.jobClass.message}
                    >
                      {(inputProps) => (
                        <Select
                          options={JOB_CLASS_OPTIONS}
                          value={policyMetadataAttributes?.jobClasses}
                          onChange={handleSelectChange('jobClass')}
                          placeholder="Select Job Class"
                          isMulti={true}
                          {...inputProps}
                        />
                      )}
                    </InputWrapper>
                    {shouldShowStateSelection() && (
                      <InputWrapper
                        id="policy-metadata-states"
                        labelText="States"
                        required={true}
                        error={formFieldErrors.states.isError}
                        footer={formFieldErrors.states.message}
                      >
                        {(inputProps) => (
                          <Select
                            options={stateOptions}
                            value={policyMetadataAttributes?.states}
                            onChange={handleSelectChange('states')}
                            placeholder="Select States"
                            isMulti={true}
                            {...inputProps}
                          />
                        )}
                      </InputWrapper>
                    )}
                  </>
                ) : (
                  <></>
                )}
                <Spacer height={'S400'} />
                <Row justifyContent={'right'} width={'100%'}>
                  <Button
                    onClick={() => handleSubmit(onSubmit)}
                    variant={ButtonVariant.Primary}
                    disabled={isSubmitting || shouldEnableGenerateTagsLoader()}
                  >
                    Submit
                  </Button>
                </Row>
              </FormWrapper>
            </Col>
          )}
        </Container>
      </Card>
    </View>
  );
};
