import React, { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  CREATE_EXECUTION,
  CREATE_RISK,
  DASHBOARD,
  ERROR_PAGE,
  EXECUTION_DETAILS,
  EXECUTION_ID_VERSION_SUFFIX,
  POLICY_DETAILS,
  POLICY_MARKETPLACE,
  POLICY_NAMESPACE_POLICY_ID_SUFFIX,
  REGISTER_POLICY,
  RISK_DETAILS,
  RISK_ID_SUFFIX,
} from 'src/constants';
import { AuthorizationController } from 'src/components/authorization';
import { Dashboard } from 'src/components/dashboard';
import { DetailsPage } from 'src/components/policy-details';
import { CreateExecutionWorkflow } from 'src/components/create-execution';
import { PolicyMarketplacePage } from 'src/components/policy-marketplace';
import RiskDetailsComponent from 'src/components/riskdetails/RiskDetailsComponent';
import { PolicyDetailsPage } from 'src/components/policy-details-page';
import RegisterPolicyPage from 'src/components/register-policy/RegisterPolicyPage';
import CreateRiskComponent from 'src/components/createRisk/CreateRiskComponent';
import { ErrorPage } from 'src/components/error-pages';
import {
  INTERNAL_SERVER_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
  PAGE_NOT_FOUND_ERROR_MESSAGE,
} from 'src/constants/ErrorConstants';
import { PolicyAdvisorErrorBoundary } from 'src/components/error-boundary/PolicyAdvisorErrorBoundary';
import { ErrorBoundaryComponent } from 'src/constants/ErrorBoundaryConstants';
import { Col } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { CommonContext } from 'src/components/navbar';

const routeConfig = [
  {
    path: DASHBOARD,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_DASHBOARD}>
        <Dashboard />
      </PolicyAdvisorErrorBoundary>
    ),
  },
  {
    path: `${EXECUTION_DETAILS}${EXECUTION_ID_VERSION_SUFFIX}`,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_EXECUTION_DETAILS_PAGE}>
        <DetailsPage />
      </PolicyAdvisorErrorBoundary>
    ),
  },
  {
    path: CREATE_EXECUTION,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_CREATE_EXECUTION_WORKFLOW}>
        <CreateExecutionWorkflow />
      </PolicyAdvisorErrorBoundary>
    ),
  },
  {
    path: POLICY_MARKETPLACE,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_POLICY_MARKET_PLACE}>
        <PolicyMarketplacePage />
      </PolicyAdvisorErrorBoundary>
    ),
  },
  {
    path: `${RISK_DETAILS}${RISK_ID_SUFFIX}`,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_RISK_DETAILS}>
        <RiskDetailsComponent />
      </PolicyAdvisorErrorBoundary>
    ),
  },
  {
    path: `${POLICY_DETAILS}${POLICY_NAMESPACE_POLICY_ID_SUFFIX}`,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_POLICY_DETAILS}>
        <PolicyDetailsPage />
      </PolicyAdvisorErrorBoundary>
    ),
  },
  {
    path: REGISTER_POLICY,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_REGISTER_POLICY}>
        <RegisterPolicyPage />
      </PolicyAdvisorErrorBoundary>
    ),
  },
  {
    path: CREATE_RISK,
    element: (
      <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_CREATE_RISK}>
        <CreateRiskComponent />
      </PolicyAdvisorErrorBoundary>
    ),
  },
];

export const AppRoutes = () => {
  const { permissionsContext, workspaceContext } = useContext(CommonContext);

  if (workspaceContext.isWorkspaceError) {
    return (
      <ErrorPage
        errorMessage={'Something went wrong while fetching workspaces. Please try again later.'}
        errorCode={INTERNAL_SERVER_ERROR_CODE}
      />
    );
  }

  if (permissionsContext.isPermissionsError) {
    return (
      <ErrorPage
        errorMessage={'Something went wrong while fetching user permissions. Please try again later.'}
        errorCode={INTERNAL_SERVER_ERROR_CODE}
      />
    );
  }

  if (permissionsContext.isPermissionsLoading) {
    return (
      <Col>
        <Spinner size={SpinnerSize.Large}></Spinner>
      </Col>
    );
  }

  return (
    <Routes>
      {routeConfig.map(({ path, element }) => (
        <Route key={path} path={path} element={<AuthorizationController element={element} />} />
      ))}
      {/* Adding public routes here as they don't require auth. */}
      <Route path={ERROR_PAGE} element={<ErrorPage />} />
      <Route
        path="*"
        element={<ErrorPage errorMessage={PAGE_NOT_FOUND_ERROR_MESSAGE} errorCode={NOT_FOUND_ERROR_CODE} />}
      />
    </Routes>
  );
};
