import { StencilThemeColors } from '@amzn/stencil-react-theme-default';
import { ReviewStatus } from 'src/models';
import { ExecutionStatus } from 'src/models/api-models/getExecutions';
import { ChipBackgroundColor } from '@amzn/stencil-react-components/chip';

export const getBorderColor = (status: string): keyof StencilThemeColors => {
  switch (status) {
    case ExecutionStatus.POLICY_COLLECTION_NOT_STARTED:
    case ExecutionStatus.COMPARISON_NOT_STARTED:
    case ExecutionStatus.DRAFT:
    case ExecutionStatus.CANCELLED:
    case ReviewStatus.CANCELLED:
      return 'neutral70';

    case ExecutionStatus.POLICY_COLLECTION_IN_PROGRESS:
    case ExecutionStatus.COMPARISON_IN_PROGRESS:
    case ExecutionStatus.IN_REVIEW:
    case ReviewStatus.PENDING:
      return 'yellow70';

    case ExecutionStatus.POLICY_COLLECTION_COMPLETED:
    case ExecutionStatus.COMPARISON_COMPLETED:
    case ExecutionStatus.APPROVED:
    case ReviewStatus.APPROVED:
      return 'green70';

    case ExecutionStatus.POLICY_COLLECTION_FAILED:
    case ExecutionStatus.COMPARISON_FAILED:
    case ExecutionStatus.REJECTED:
    case ReviewStatus.REJECTED:
    case ReviewStatus.INVALIDATED:
      return 'red70';

    case ExecutionStatus.REWORK:
      return 'primary70';

    case ReviewStatus.APPROVED_WITH_CONDITION:
      return 'green60';

    default:
      return 'primary70';
  }
};

export const mapExecutionStatusToDisplayText = (status: ExecutionStatus): string => {
  switch (status) {
    case ExecutionStatus.DRAFT:
    case ExecutionStatus.POLICY_COLLECTION_NOT_STARTED:
      return 'Not started';
    case ExecutionStatus.POLICY_COLLECTION_IN_PROGRESS:
    case ExecutionStatus.POLICY_COLLECTION_COMPLETED:
    case ExecutionStatus.COMPARISON_NOT_STARTED:
    case ExecutionStatus.COMPARISON_IN_PROGRESS:
    case ExecutionStatus.IN_REVIEW:
    case ExecutionStatus.REWORK:
      return 'In progress';
    case ExecutionStatus.COMPARISON_COMPLETED:
    case ExecutionStatus.APPROVED:
      return 'Completed';
    case ExecutionStatus.POLICY_COLLECTION_FAILED:
    case ExecutionStatus.COMPARISON_FAILED:
    case ExecutionStatus.REJECTED:
    case ExecutionStatus.CANCELLED:
      return 'Failed';
    default:
      return '-';
  }
};

export const compare = (a: any, b: any, activeSortId: any) => {
  // This requires the sort IDs to be the same as the property
  // names in the data object. If they aren't, some additional
  // logic would be required to extract the data for the column.
  const firstValue = a[activeSortId as keyof any];
  const secondValue = b[activeSortId as keyof any];

  return firstValue < secondValue ? -1 : 1;
};

export const getCurrentPageData = <T>(data: T[] = [], currentPage: number, pageSize: number): T[] => {
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  return data.slice(startIndex, endIndex);
};

export const capitalizeFirstLetter = (word?: string) => {
  if (!word || word.length == 0) return word;
  return word.charAt(0).toUpperCase() + word.slice(1).toLocaleLowerCase();
};

export const formatEnumString = (enumString?: string) => {
  if (!enumString) return enumString;
  return enumString
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const RISK_PRIORITY_COLOR: { [key: string]: ChipBackgroundColor } = {
  HIGH: ChipBackgroundColor.Red,
  MEDIUM: ChipBackgroundColor.Yellow,
  LOW: ChipBackgroundColor.Default,
};

export const RISK_STATUS_COLOR: { [key: string]: ChipBackgroundColor } = {
  ACKNOWLEDGED: ChipBackgroundColor.Green,
  UNACKNOWLEDGED: ChipBackgroundColor.Red,
  RESOLVED: ChipBackgroundColor.Blue,
  WORK_IN_PROGRESS: ChipBackgroundColor.Yellow,
  INVALIDATED: ChipBackgroundColor.Default,
};

export const getSetDifference = <T>(setA: Set<T>, setB: Set<T>): Set<T> => {
  const result = new Set<T>();

  setA.forEach((item) => {
    if (!setB.has(item)) {
      result.add(item);
    }
  });

  return result;
};

export const camelCaseToSentence = (input: string): string => {
  const sentence = input.replace(/([a-z])([A-Z])/g, '$1 $2');
  return sentence.charAt(0).toUpperCase() + sentence.slice(1);
};
