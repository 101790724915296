import { OnSortCallback, SortDirection, useSort } from '@amzn/stencil-react-components/table';
import { Dispatch, SetStateAction, useState } from 'react';
import { compare, getCurrentPageData } from 'src/components/utils';

interface TableSortAndPaginationProps<T> {
  data: T[];
  pageSize?: number;
  defaultActiveSortId?: string;
}

interface PaginationState {
  selectedPage: number;
  onPageSelect: Dispatch<SetStateAction<number>>;
  numberOfPages: number;
}

interface SortState {
  activeSortId?: string;
  sortDirection: SortDirection;
  onSort: OnSortCallback;
}

interface TableSortAndPagination<T> {
  visibleData: T[];
  paginationState: PaginationState;
  sortState: SortState;
}

export const useTableSortAndPagination = <T>({
  data,
  pageSize = 5,
  defaultActiveSortId,
}: TableSortAndPaginationProps<T>): TableSortAndPagination<T> => {
  const [currentPage, setCurrentPage] = useState(1);

  const { activeSortId, sortDirection, onSort } = useSort({
    defaultSortDirection: SortDirection.Descending,
    defaultActiveSortId,
  });

  const sortTable = (a: T, b: T) => {
    return compare(a, b, activeSortId);
  };

  const sortedData =
    sortDirection === SortDirection.Descending ? [...data].sort(sortTable).reverse() : [...data].sort(sortTable);

  return {
    visibleData: getCurrentPageData(sortedData, currentPage, pageSize),
    paginationState: {
      selectedPage: currentPage,
      onPageSelect: setCurrentPage,
      numberOfPages: Math.ceil(sortedData.length / pageSize),
    },
    sortState: {
      activeSortId,
      sortDirection,
      onSort,
    },
  };
};
