import { useEffect, useState } from 'react';
import { useGetPermissions } from 'src/hooks';
import { useGetNamespaces } from 'src/hooks/useGetNamespaces';
import { PermissionsContext, WorkspaceContext } from 'src/components/navbar/constants';

export const DEFAULT_WORKSPACE_CONTEXT: WorkspaceContext = {
  workspaces: [],
  selectedWorkspace: {
    namespaceId: '',
    namespaceVersion: 0,
    name: '',
    bindleId: '',
    createdAt: '',
    createdBy: '',
    resourceId: '',
    jobScheduledCron: '',
    aspects: [],
  },
  isWorkspaceLoading: true,
  isWorkspaceError: false,
};

export const DEFAULT_PERMISSIONS_CONTEXT: PermissionsContext = {
  permissions: {
    view: false,
    edit: false,
    administer: false,
  },
  isPermissionsLoading: true,
  isPermissionsError: false,
};

export const useCommonContext = () => {
  const [workspaceContext, setWorkspaceContext] = useState<WorkspaceContext>(DEFAULT_WORKSPACE_CONTEXT);

  const { data: namespacesData, isLoading: isNamespacesLoading, error: namespacesError } = useGetNamespaces();

  const {
    data: permissionsData,
    isLoading: isPermissionsLoading,
    error: permissionsError,
  } = useGetPermissions({ namespaceId: workspaceContext.selectedWorkspace.namespaceId });

  useEffect(() => {
    if (namespacesData?.namespaces?.length) {
      setWorkspaceContext((prevState) => ({
        ...prevState,
        workspaces: namespacesData.namespaces,
        selectedWorkspace: namespacesData.namespaces[0],
        isWorkspaceLoading: isNamespacesLoading,
        isWorkspaceError: !!namespacesError,
      }));
    }
  }, [namespacesData]);

  return {
    workspaceContext,
    setWorkspaceContext,
    permissionsContext: !permissionsData
      ? DEFAULT_PERMISSIONS_CONTEXT
      : {
          permissions: permissionsData.permissions,
          isPermissionsLoading: isNamespacesLoading || isPermissionsLoading,
          isPermissionsError: !!permissionsError,
        },
    isLoading: isNamespacesLoading || isPermissionsLoading,
    namespacesError,
    permissionsError,
  };
};
