import React, { useContext, useRef } from 'react';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { FormWrapper, InputFooter, InputWrapper, Select, TextArea } from '@amzn/stencil-react-components/form';
import { isBefore, PopupDatePicker } from '@amzn/stencil-react-components/date-picker';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { getDateBeforeNDays } from 'src/utils';
import { PolicyMetadata, RiskPriority } from 'src/models';
import { useCreateRiskComponent } from 'src/components/createRisk/hooks';
import { CommonContext } from 'src/components/navbar/CommonContext';
import { showWorkspaceMessageBanner, WorkspaceMessageBanner } from 'src/components/workspaces/WorkspacesUtils';
import { Card } from '@amzn/stencil-react-components/card';
import { useScrollIntoView } from 'src/hooks/useScrollIntoView';
import { useComponentLoadedLatencyMetric } from 'src/hooks';
import { metricConfig } from 'src/constants';
import FocusedMessageBanner from '../shared/focused-message-banner/FocusedMessageBanner';

const CREATE_RISK_API_ERROR_MESSAGE =
  'Create risk request failed due to some error. Please refresh or try after sometime';
const POLICIES_FETCH_ERROR_MESSAGE = 'Something went wrong while fetching policies.';

const CreateRiskComponent = () => {
  const { workspaceContext } = useContext(CommonContext);
  const containerRef = useScrollIntoView();
  const {
    setRiskName,
    selectedPolicy,
    setSelectedPolicy,
    setSelectedReferencePolicies,
    setSelectedPriority,
    setSelectedETA,
    setRiskDetails,
    fieldErrors,
    getPoliciesMetadataState,
    createRiskState,
    handleCreateRiskOnSubmit,
  } = useCreateRiskComponent(workspaceContext.selectedWorkspace.namespaceId);
  const firstInputRef = useRef<HTMLInputElement>(null);

  const isStartDateDisabled = (date: string) => !!date && isBefore(getDateBeforeNDays(1).toISOString())(date);
  const valueAccessor = (option: PolicyMetadata) => option.name;

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.CreateRisk.componentName,
    latencyAction: metricConfig.CreateRisk.actions.CREATE_RISK_LOAD_LATENCY,
    isComponentLoaded: !getPoliciesMetadataState.loading,
  });

  if (createRiskState.loading || getPoliciesMetadataState.loading) {
    return <Spinner size={SpinnerSize.Large} />;
  }

  if (showWorkspaceMessageBanner(workspaceContext)) {
    return WorkspaceMessageBanner(workspaceContext);
  }

  return (
    <Container ref={containerRef}>
      <Col alignItems={'center'} width={'100%'}>
        <Col width={'80%'} alignItems={'center'}>
          {createRiskState.error ? (
            <Col width={'100%'}>
              <FocusedMessageBanner type={MessageBannerType.Error} onDismissed={() => firstInputRef.current?.focus()}>
                {CREATE_RISK_API_ERROR_MESSAGE}
              </FocusedMessageBanner>
            </Col>
          ) : getPoliciesMetadataState.error ? (
            <Col width={'100%'}>
              <FocusedMessageBanner type={MessageBannerType.Error}>{POLICIES_FETCH_ERROR_MESSAGE}</FocusedMessageBanner>
            </Col>
          ) : (
            <Col width={'80%'} justifyContent={'center'}>
              <H1 margin={{ bottom: '1rem', top: '2rem' }}>Add Risk</H1>
              <Text aria-hidden={true}>Fields with an asterisk (*) are required</Text>
              <Spacer height={'1rem'} />
              <Card isElevated={true}>
                <Col width={'100%'} gridGap={'S400'} padding={'S500'}>
                  <FormWrapper>
                    <InputWrapper
                      labelText="Risk Name"
                      id="riskname-input"
                      required={true}
                      error={fieldErrors.title}
                      footer={(footerProps) =>
                        footerProps.error && <InputFooter {...footerProps}>Please enter risk name</InputFooter>
                      }
                    >
                      {(inputProps) => (
                        <Select
                          {...inputProps}
                          placeholder={'Enter name'}
                          onChange={(value) => {
                            setRiskName(value);
                          }}
                          options={workspaceContext.selectedWorkspace.aspects.map((aspect) => aspect.aspect)}
                        />
                      )}
                    </InputWrapper>
                    <InputWrapper
                      labelText="Risk Details"
                      id="riskdetails-input"
                      error={fieldErrors.details}
                      footer={(footerProps) =>
                        footerProps.error && <InputFooter {...footerProps}>Please enter risk details</InputFooter>
                      }
                      required={true}
                    >
                      {(inputProps) => (
                        <TextArea
                          {...inputProps}
                          onChange={(event) => {
                            setRiskDetails(event.target.value);
                          }}
                          resize={'vertical'}
                        />
                      )}
                    </InputWrapper>
                    <InputWrapper
                      labelText="Internal Policy"
                      id="policy-select"
                      error={fieldErrors.policyId}
                      footer={(footerProps) =>
                        footerProps.error && <InputFooter {...footerProps}>Please select a policy</InputFooter>
                      }
                      required={true}
                    >
                      {(inputProps) => (
                        <Select
                          {...inputProps}
                          noOptionsText="Select a workspace to get policies"
                          options={getPoliciesMetadataState?.data?.policiesMetadata || []}
                          renderOption={(option: PolicyMetadata) => option.name}
                          valueAccessor={valueAccessor}
                          onChange={(value) => {
                            setSelectedPolicy(value);
                          }}
                        />
                      )}
                    </InputWrapper>
                    <InputWrapper id={'risk-reference-policies'} labelText={'Reference Policies'}>
                      {(inputProps) => (
                        <Select
                          {...inputProps}
                          noOptionsText="Select a workspace to get policies"
                          options={getPoliciesMetadataState?.data?.policiesMetadata.filter(
                            (policyMetadata) => policyMetadata.name != selectedPolicy?.name,
                          )}
                          renderOption={(option: PolicyMetadata) => option.name}
                          isMulti={true}
                          valueAccessor={valueAccessor}
                          onChange={(value) => {
                            setSelectedReferencePolicies(value);
                          }}
                        />
                      )}
                    </InputWrapper>
                    <InputWrapper id={'risk-priority'} labelText={'Priority'}>
                      {(inputProps) => (
                        <Select
                          {...inputProps}
                          options={Object.values(RiskPriority)}
                          placeholder={'Choose a priority'}
                          onChange={(value) => {
                            setSelectedPriority(value as RiskPriority);
                          }}
                        />
                      )}
                    </InputWrapper>
                    <InputWrapper id={'risk-eta'} labelText={'ETA'}>
                      {(inputProps) => (
                        <PopupDatePicker
                          {...inputProps}
                          isDateDisabled={isStartDateDisabled}
                          onChange={(selectedDate) => {
                            setSelectedETA(selectedDate);
                          }}
                        />
                      )}
                    </InputWrapper>
                  </FormWrapper>
                  <Row justifyContent={'flex-end'}>
                    <Button variant={ButtonVariant.Primary} onClick={handleCreateRiskOnSubmit}>
                      Submit
                    </Button>
                  </Row>
                </Col>
              </Card>
            </Col>
          )}
        </Col>
      </Col>
    </Container>
  );
};

export default CreateRiskComponent;
