import { useState } from 'react';
import { useGetRisks } from 'src/hooks/useGetRisks';
import { RiskPriority, RiskStatus } from 'src/models';
import { RiskTableCards } from 'src/components/dashboard/models/RiskTableCards';
import { usePoliciesMetadata } from 'src/hooks';
import { populatePolicyDetails } from 'src/utils/riskUtils';
import ExtendedRisk from 'src/components/dashboard/models/ExtendedRisk';
import { useTableFilter } from 'src/components/shared/table-filters';
import { FilterOptionDetails } from 'src/components/shared/table-filters/TableFilters';

interface RiskClassificationCounts {
  highPriorityRisks: number;
  unacknowledgedRisks: number;
  overSLARisks: number;
}

interface RisksTableState {
  risks: ExtendedRisk[];
  isLoading: boolean;
  isError: boolean;
  counts: RiskClassificationCounts;
  activeCard?: RiskTableCards;
  setActiveCard: (card: RiskTableCards) => void;
  filterSelections: Record<string, string[]>;
  setFilterSelections: (filterSelections: Record<string, string[]>) => void;
  handleFilterChange: (filterKey: string, filterValue: string[]) => void;
  filterOptionsMap: Record<string, FilterOptionDetails>;
}

const RISK_TABLE_FILTER_KEYS = [
  { keyName: 'Country', keyPath: 'country' },
  { keyName: 'Created By', keyPath: 'createdBy' },
];

export const useRisksTable = (namespaceId: string): RisksTableState => {
  const [activeCard, setActiveCard] = useState<RiskTableCards>();
  const { data: risksData, isLoading: isGetRisksLoading, isError: isGetRisksError } = useGetRisks({ namespaceId });

  const {
    data: policiesMetadata,
    isLoading: isPoliciesMetadataLoading,
    isError: isPoliciesMetadataError,
  } = usePoliciesMetadata({ namespaceId });

  const {
    filteredData: filteredRisks,
    filterSelections,
    setFilterSelections,
    handleFilterChange,
    filterOptionsMap,
  } = useTableFilter({
    data: populatePolicyDetails(risksData?.risks, policiesMetadata?.policiesMetadata),
    filterKeys: RISK_TABLE_FILTER_KEYS,
  });

  const highPriorityRisks = filteredRisks.filter((risk) => risk.priority === RiskPriority.HIGH);
  const unacknowledgedRisks = filteredRisks.filter((risk) => risk.status === RiskStatus.UNACKNOWLEDGED);
  const overSLARisks = filteredRisks.filter((risk) => {
    if (!risk.fixByDate) return false;
    const currentDate = new Date();
    const slaDate = new Date(risk.fixByDate);
    slaDate.setDate(slaDate.getDate() + 1); // To include SLA date
    return currentDate > slaDate;
  });

  const handleCardSelection = (card: RiskTableCards) => {
    if (activeCard === card) {
      setActiveCard(undefined);
    } else {
      setActiveCard(card);
    }
  };

  const getFilteredRisks = () => {
    switch (activeCard) {
      case RiskTableCards.HIGH_PRIORIY_RISKS:
        return highPriorityRisks;
      case RiskTableCards.UNACKNOWLEDGED_RISKS:
        return unacknowledgedRisks;
      case RiskTableCards.OVER_SLA_RISKS:
        return overSLARisks;
      default:
        return filteredRisks;
    }
  };

  return {
    risks: getFilteredRisks(),
    isLoading: isGetRisksLoading || isPoliciesMetadataLoading,
    isError: isGetRisksError || isPoliciesMetadataError,
    counts: {
      highPriorityRisks: highPriorityRisks.length,
      unacknowledgedRisks: unacknowledgedRisks.length,
      overSLARisks: overSLARisks.length,
    },
    activeCard,
    setActiveCard: handleCardSelection,
    filterSelections,
    setFilterSelections,
    handleFilterChange,
    filterOptionsMap,
  };
};
