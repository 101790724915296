import React, { useEffect, useRef } from 'react';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';

interface CreateRiskBannerProps {
  type: MessageBannerType;
  onDismissed?: () => void;
  autoDismissAfter?: number;
  ariaLive?: 'polite' | 'assertive' | 'off';
  children: React.ReactNode;
}

const FocusedMessageBanner = ({
  type,
  onDismissed,
  children,
  autoDismissAfter,
  ariaLive = 'assertive',
}: CreateRiskBannerProps) => {
  const messageBannerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (messageBannerRef.current) {
      messageBannerRef.current.focus();
    }
  }, []);

  return (
    <MessageBanner
      aria-live={ariaLive}
      isDismissible={true}
      type={type}
      ref={messageBannerRef}
      onDismissed={onDismissed}
      autoDismissAfter={autoDismissAfter}
    >
      {children}
    </MessageBanner>
  );
};

export default FocusedMessageBanner;
