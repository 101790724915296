import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { FormWrapper } from '@amzn/stencil-react-components/form';
import { usePolicyMarketplace } from 'src/hooks/usePolicyMarketplace';
import { useNavigate } from 'react-router-dom';
import { FilterSelection } from '@amzn/stencil-react-components/filtering';
import { applyPolicyFilters } from 'src/components/policy-marketplace/utils/policyFilters';
import { CommonContext } from 'src/components/navbar/CommonContext';
import { showWorkspaceMessageBanner, WorkspaceMessageBanner } from 'src/components/workspaces/WorkspacesUtils';
import { useScrollIntoView } from 'src/hooks/useScrollIntoView';
import PolicyMarketplaceContent from './PolicyMarketplaceContent';

const PolicyMarketplacePage: React.FC = () => {
  const { workspaceContext } = useContext(CommonContext);
  const { getPoliciesMetadataState } = usePolicyMarketplace(workspaceContext.selectedWorkspace);
  const containerRef = useScrollIntoView();

  const navigate = useNavigate();
  const policies = getPoliciesMetadataState.data?.policiesMetadata || [];

  return (
    <Container ref={containerRef}>
      {showWorkspaceMessageBanner(workspaceContext) ? (
        WorkspaceMessageBanner(workspaceContext)
      ) : (
        <FormWrapper>
          <Col width={'100%'} alignItems={'center'}>
            <PolicyMarketplaceContent
              selectedNamespaceId={workspaceContext.selectedWorkspace.namespaceId}
              getPoliciesMetadataState={getPoliciesMetadataState}
              policies={policies}
              navigate={navigate}
            />
          </Col>
        </FormWrapper>
      )}
    </Container>
  );
};

export default PolicyMarketplacePage;
