import { Permissions } from 'src/models';
import {
  CREATE_EXECUTION,
  CREATE_RISK,
  DASHBOARD,
  EXECUTION_DETAILS,
  HOME_PAGE_ROUTE,
  POLICY_DETAILS,
  POLICY_MARKETPLACE,
  POLICY_REVIEW_WORKFLOW_PATH,
  REGISTER_POLICY,
  REVIEW_PAGE_WORKFLOW_BASE_PATH,
  REVIEW_SUBMISSION_WORKFLOW_PATH,
  RISK_DETAILS,
} from 'src/constants';

export const PathPermissionMap: Record<string, keyof Permissions> = {
  [HOME_PAGE_ROUTE]: 'view',
  [DASHBOARD]: 'view',
  [POLICY_MARKETPLACE]: 'view',
  [POLICY_DETAILS]: 'view',
  [RISK_DETAILS]: 'view',
  [EXECUTION_DETAILS]: 'view',
  [CREATE_RISK]: 'edit',
  [CREATE_EXECUTION]: 'edit',
  [REVIEW_SUBMISSION_WORKFLOW_PATH]: 'edit',
  [POLICY_REVIEW_WORKFLOW_PATH]: 'edit',
  [REVIEW_PAGE_WORKFLOW_BASE_PATH]: 'edit',
  [REGISTER_POLICY]: 'edit',
};
