import React, { forwardRef, Fragment, ReactNode, useContext, useEffect, useImperativeHandle, useRef } from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { TableElement, TbodyElement, TrElement } from '@amzn/stencil-react-components/table';
import { Label, Text } from '@amzn/stencil-react-components/text';
import { EMPTY_SEPARATOR, POLICY_DELIMITER, RiskItems } from 'src/components/riskdetails/constants';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { GetPoliciesMetadataResponse, GetRiskResponse, Risk, RiskStatus } from 'src/models';
import { metricConfig, EXECUTION_DETAILS, POLICY_DETAILS } from 'src/constants';
import { convertDateTimeToFormat, DateTimeFormats, getClickMetricDimensions } from 'src/utils';
import {
  FixByDateElement,
  RiskDetailsElement,
  RiskNameElement,
  RiskPriorityElement,
  RiskStatusElement,
} from 'src/components/riskdetails/elements';
import { StateEdits, useEditRiskState } from 'src/components/riskdetails/hooks';
import { UpdateRiskRequest } from 'src/models/api-models';
import { RouterLink } from 'src/components/shared/router-link';
import { CommonContext } from 'src/components';
import { useScrollIntoView } from 'src/hooks/useScrollIntoView';
import { StyledTdElement, StyledThElement } from 'src/components/shared/table-components';
import { FocusedMessageBanner } from 'src/components/shared/focused-message-banner';
import { MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';

const NO_RISK_UPDATES_MESSAGE = 'There are no new updates';
const MANDATORY_FIELDS_FOR_UPDATE: (keyof Risk)[] = ['title', 'details', 'priority', 'fixByDate'];
const MANDATORY_FIELDS_FOR_INVALIDATED_RISK: (keyof Risk)[] = ['title', 'details'];

interface RiskDetailsTabProps {
  riskId: string | undefined;
  policiesMetadataResponse: GetPoliciesMetadataResponse;
  getRiskResponse: GetRiskResponse;
  updateRisk: (updateRiskRequest: UpdateRiskRequest) => void;
}

export const RiskDetailsTab = forwardRef(
  ({ riskId, getRiskResponse, policiesMetadataResponse, updateRisk }: RiskDetailsTabProps, firstInputRef) => {
    const localRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(firstInputRef, () => localRef.current);

    const {
      editRiskState,
      setTitle,
      setDetails,
      setStatus,
      setPriority,
      setFixByDate,
      toggleEditing,
      updateStateEdits,
      getRiskUpdates,
      isRiskUpdated,
      updateShowError,
    } = useEditRiskState();
    const { permissionsContext, workspaceContext } = useContext(CommonContext);
    const columnRef = useScrollIntoView();

    useEffect(() => {
      updateStateEdits(getRiskResponse?.riskItem);
    }, [getRiskResponse?.riskItem.riskId]);

    const handleUpdateRisk = () => {
      if (!isRiskUpdated(getRiskResponse?.riskItem)) {
        updateShowError(true);
      } else if (
        getRiskUpdates(getRiskResponse?.riskItem).status === RiskStatus.INVALIDATED &&
        MANDATORY_FIELDS_FOR_INVALIDATED_RISK.some((key) => !editRiskState.edits[key as keyof StateEdits])
      ) {
        updateShowError(true);
      } else if (
        getRiskUpdates(getRiskResponse?.riskItem).status !== RiskStatus.INVALIDATED &&
        MANDATORY_FIELDS_FOR_UPDATE.some((key) => !editRiskState.edits[key as keyof StateEdits])
      ) {
        updateShowError(true);
      } else if (riskId) {
        logger.emitCountMetric({
          metricName: METRIC_NAME.CLICK,
          dimensions: getClickMetricDimensions(
            metricConfig.RiskDetailsPage.componentName,
            metricConfig.RiskDetailsPage.actions.EDIT_RISK_SUBMIT,
          ),
        });
        updateRisk({ riskId, updates: getRiskUpdates(getRiskResponse?.riskItem) });
      }
    };

    const getPolicyLink = (policyId: string, id?: string) => {
      for (const policyMetadata of policiesMetadataResponse?.policiesMetadata || []) {
        if (policyMetadata.policyId === policyId) {
          return (
            <RouterLink
              id={id}
              aria-labelledby={`${id}-label`}
              to={`${POLICY_DETAILS}/${workspaceContext.selectedWorkspace.namespaceId}/${policyMetadata.policyId}`}
            >
              {policyMetadata.name}
            </RouterLink>
          );
        }
      }
      return EMPTY_SEPARATOR;
    };

    const getLastUpdatedBy = () => {
      if (
        getRiskResponse &&
        getRiskResponse?.riskItem &&
        getRiskResponse.riskItem?.riskActions &&
        getRiskResponse.riskItem?.riskActions?.length > 0
      ) {
        const riskActions = getRiskResponse.riskItem.riskActions;
        return riskActions[riskActions.length - 1].performedBy;
      }
      return EMPTY_SEPARATOR;
    };

    const populateRiskDetailsConfig = (
      riskInfo: Risk,
    ): Record<RiskItems, { id: string; element: (id: string) => ReactNode; isEditable?: true }> => {
      return {
        [RiskItems.RISK_NAME]: {
          id: 'risk-name-input',
          element: (id: string) => (
            <RiskNameElement
              id={id}
              editRiskState={editRiskState}
              setTitle={setTitle}
              titleOptions={workspaceContext.selectedWorkspace.aspects.map((aspect) => aspect.aspect)}
              ref={localRef}
            />
          ),
          isEditable: true,
        },
        [RiskItems.RISK_DETAIL]: {
          id: 'risk-details-input',
          element: (id) => <RiskDetailsElement id={id} editRiskState={editRiskState} setDetails={setDetails} />,
          isEditable: true,
        },
        [RiskItems.INTERNAL_POLICY]: {
          id: 'internal-policy',
          element: (id) => (riskInfo?.policyId ? getPolicyLink(riskInfo.policyId, id) : EMPTY_SEPARATOR),
        },
        [RiskItems.REFERENCE_POLICIES]: {
          id: 'reference-policy',
          element: (id) => (
            <Row id={id} aria-labelledby={`${id}-label`}>
              {riskInfo?.referencePolicyIds?.length > 0
                ? riskInfo.referencePolicyIds.map((refPolicyId, index) => (
                    <Fragment key={refPolicyId}>
                      {getPolicyLink(refPolicyId)}
                      {index < riskInfo.referencePolicyIds.length - 1 && POLICY_DELIMITER}
                    </Fragment>
                  ))
                : EMPTY_SEPARATOR}
            </Row>
          ),
        },
        [RiskItems.ASSESSMENT]: {
          id: 'assessment',
          element: (id) =>
            riskInfo?.executionId ? (
              // TODO: Hard-coding the version value to 1 as execution version is not present in risk details.
              <RouterLink
                id={id}
                aria-labelledby={`${id}-label`}
                to={`${EXECUTION_DETAILS}/${riskInfo.executionId}/version/1`}
              >
                {riskInfo.executionId}
              </RouterLink>
            ) : (
              EMPTY_SEPARATOR
            ),
        },
        [RiskItems.CREATION_DATE]: {
          id: 'creation-date',
          element: (id) => (
            <Text id={id} aria-labelledby={`${id}-label`}>
              {convertDateTimeToFormat(riskInfo?.createdAt, DateTimeFormats.DATE_TIME_DD_MMM_YYYY_HHMM_A)}
            </Text>
          ),
        },
        [RiskItems.CREATED_BY]: {
          id: 'creation-by',
          element: (id) => (
            <Text id={id} aria-labelledby={`${id}-label`} textAlign="justify">
              {riskInfo?.createdBy}
            </Text>
          ),
        },
        [RiskItems.LAST_UPDATE_DATE]: {
          id: 'last-update-date',
          element: (id) => (
            <Text id={id} aria-labelledby={`${id}-label`}>
              {convertDateTimeToFormat(riskInfo.updatedAt, DateTimeFormats.DATE_TIME_DD_MMM_YYYY_HHMM_A)}
            </Text>
          ),
        },
        [RiskItems.LAST_UPDATED_BY]: {
          id: 'last-updated-by',
          element: (id) => (
            <Text id={id} aria-labelledby={`${id}-label`} textAlign="justify">
              {getLastUpdatedBy()}
            </Text>
          ),
        },
        [RiskItems.STATUS]: {
          id: 'status-input',
          element: (id) => <RiskStatusElement id={id} editRiskState={editRiskState} setStatus={setStatus} />,
          isEditable: true,
        },
        [RiskItems.PRIORITY]: {
          id: 'priority-input',
          element: (id) => <RiskPriorityElement id={id} editRiskState={editRiskState} setPriority={setPriority} />,
          isEditable: true,
        },
        [RiskItems.ETA]: {
          id: 'eta-input',
          element: (id) => <FixByDateElement id={id} editRiskState={editRiskState} setFixByDate={setFixByDate} />,
          isEditable: true,
        },
      };
    };

    return (
      // TODO: Remove the hardcoded value once we have the fix for tab panel width issue.
      <Col minWidth={'70rem'} ref={columnRef}>
        {editRiskState.showErrors && !isRiskUpdated(getRiskResponse?.riskItem) && (
          <FocusedMessageBanner type={MessageBannerType.Error} onDismissed={() => localRef.current?.focus()}>
            {NO_RISK_UPDATES_MESSAGE}
          </FocusedMessageBanner>
        )}
        {editRiskState.isEditing && (
          <Text margin={'S300'} aria-hidden={true}>
            Fields with an asterisk (*) are required
          </Text>
        )}
        <Row margin={{ bottom: '1rem' }}>
          <TableElement style={{ border: '0px' }}>
            <TbodyElement>
              {Object.entries(populateRiskDetailsConfig(getRiskResponse.riskItem)).map(
                ([key, { id, isEditable, element }]) => (
                  <TrElement key={key}>
                    <StyledThElement width={'20%'}>
                      <Label id={`${id}-label`} htmlFor={id} style={{ fontWeight: 'bold' }} textAlign={'justify'}>
                        {key}
                        {editRiskState.isEditing && isEditable && <span aria-hidden="true">*</span>}
                      </Label>
                    </StyledThElement>
                    <StyledTdElement>{element(id)}</StyledTdElement>
                  </TrElement>
                ),
              )}
            </TbodyElement>
          </TableElement>
        </Row>
        <Row justifyContent="flex-end" gridGap="S300">
          {editRiskState.isEditing ? (
            <>
              <Button onClick={() => toggleEditing(getRiskResponse?.riskItem)}>Cancel</Button>
              <Button variant={ButtonVariant.Primary} onClick={handleUpdateRisk}>
                Update Risk
              </Button>
            </>
          ) : (
            <Button
              disabled={!permissionsContext.permissions.edit}
              variant={ButtonVariant.Primary}
              onClick={() => {
                logger.emitCountMetric({
                  metricName: METRIC_NAME.CLICK,
                  dimensions: getClickMetricDimensions(
                    metricConfig.RiskDetailsPage.componentName,
                    metricConfig.RiskDetailsPage.actions.EDIT_RISK_BUTTON_CLICK,
                  ),
                });
                toggleEditing(getRiskResponse?.riskItem);
                localRef.current?.focus();
              }}
            >
              Edit Risk
            </Button>
          )}
        </Row>
      </Col>
    );
  },
);
