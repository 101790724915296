import React, { useRef } from 'react';
import { H1, Text } from '@amzn/stencil-react-components/text';
import { useGetRisk } from 'src/hooks/useGetRisk';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Col, Container, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { useParams } from 'react-router';
import { useComponentLoadedLatencyMetric, usePoliciesMetadata } from 'src/hooks';
import { MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { useUpdateRisk } from 'src/hooks/useUpdateRisk';
import { useEditRiskState } from 'src/components/riskdetails/hooks';
import { Card } from '@amzn/stencil-react-components/card';
import { TabBar, TabPanel, TabSwitcher, useTabs } from '@amzn/stencil-react-components/tabs';
import { RiskTabs } from 'src/components/riskdetails/constants';
import { RiskAuditTab, RiskDetailsTab } from 'src/components/riskdetails/tabs';
import { ErrorBoundaryComponent } from 'src/constants/ErrorBoundaryConstants';
import { PolicyAdvisorErrorBoundary } from 'src/components/error-boundary/PolicyAdvisorErrorBoundary';
import { FocusedMessageBanner } from 'src/components/shared/focused-message-banner';
import { useScrollIntoView } from 'src/hooks/useScrollIntoView';
import GEN_AI_LOGO from 'src/assets/GEN_AI_LOGO.svg';
import { SYSTEM_USER } from 'src/constants/CommonConstants';
import { metricConfig } from 'src/constants';
import { Feedback } from 'src/components/feedback';
import { FeedbackUseCase } from 'src/models';

const RISK_DETAILS_ERROR_MESSAGE = 'Unable to fetch risk details due to some error.';
const RISK_UPDATE_ERROR_MESSAGE = 'Unable to update the risk due to some error.';
const RISK_UPDATE_SUCCESS_MESSAGE = 'Risk updated successfully.';

const RiskDetailsComponent = () => {
  const firstInputRef = useRef<HTMLButtonElement>(null);
  const { riskId } = useParams<{ riskId: string }>();
  const { tabBarProps, tabSwitcherProps } = useTabs({ tabs: Object.values(RiskTabs) });

  const { editRiskState, toggleEditing } = useEditRiskState();
  const containerRef = useScrollIntoView();

  const {
    data: getRiskResponse,
    isLoading: isGetRiskLoading,
    isError: isGetRiskError,
    refetch: getRiskRefetch,
    isRefetching: isGetRiskRefetching,
    isRefetchError: isGetRiskRefetchingError,
  } = useGetRisk({ riskId });
  const {
    data: policiesMetadataResponse,
    isLoading: isGetPoliciesMetadataLoading,
    isError: isGetPoliciesMetadataError,
  } = usePoliciesMetadata({
    namespaceId: getRiskResponse?.riskItem.namespaceId || '',
  });
  const {
    updateRisk,
    updateRiskState: { isError: isUpdateRiskError, isSuccess: isUpdateRiskSuccess },
  } = useUpdateRisk({
    onSuccess: () => getRiskRefetch().then(() => editRiskState.isEditing && toggleEditing(getRiskResponse?.riskItem)),
  });

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.RiskDetailsPage.componentName,
    latencyAction: metricConfig.RiskDetailsPage.actions.RISK_DETAILS_LOAD_LATENCY,
    isComponentLoaded: !(isGetRiskLoading || isGetRiskRefetching || isGetPoliciesMetadataLoading),
  });

  return isGetRiskLoading || isGetRiskRefetching || isGetPoliciesMetadataLoading ? (
    <Spinner size={SpinnerSize.Large} />
  ) : isGetRiskError || isGetRiskRefetchingError || isGetPoliciesMetadataError ? (
    <FocusedMessageBanner type={MessageBannerType.Error}>{RISK_DETAILS_ERROR_MESSAGE}</FocusedMessageBanner>
  ) : (
    <Container ref={containerRef}>
      <Col alignItems={'center'} width={'100%'}>
        <Spacer height={'S400'} />
        <Col width={'80%'}>
          <Row justifyContent="space-between" alignItems="center" gridGap="S100">
            <Col>
              <Row alignItems="center" gridGap="S100">
                {getRiskResponse?.riskItem.createdBy.toLowerCase() === SYSTEM_USER && (
                  <View width="2rem" height="2rem" justifyContent="center" alignItems="center">
                    <img src={GEN_AI_LOGO} alt="GEN AI Logo" />
                  </View>
                )}
                <H1>Risk Details</H1>
              </Row>
            </Col>
            {getRiskResponse?.riskItem.createdBy.toLowerCase() === SYSTEM_USER && (
              <Col>
                <Feedback feedbackUseCase={FeedbackUseCase.RISK_DETAILS} referenceId={riskId} />
              </Col>
            )}
          </Row>
          {getRiskResponse?.riskItem.createdBy.toLowerCase() === SYSTEM_USER && (
            <Text margin={{ left: 'S200' }} fontStyle="italic" fontSize="T100" fontWeight="light">
              *This risk is generated by Generative AI and may not always be accurate.
            </Text>
          )}
          <Spacer height={'S300'} />
          <Card isElevated={true}>
            <Col width={'100%'}>
              <Row>
                <TabBar {...tabBarProps} />
              </Row>
              <Spacer height={'S200'} />
              <Col width={'100%'}>
                {isUpdateRiskError && (
                  <FocusedMessageBanner
                    type={MessageBannerType.Error}
                    onDismissed={() => firstInputRef.current?.focus()}
                  >
                    {RISK_UPDATE_ERROR_MESSAGE}
                  </FocusedMessageBanner>
                )}
                {isUpdateRiskSuccess && (
                  <FocusedMessageBanner
                    type={MessageBannerType.Success}
                    onDismissed={() => firstInputRef.current?.focus()}
                  >
                    {RISK_UPDATE_SUCCESS_MESSAGE}
                  </FocusedMessageBanner>
                )}
              </Col>
              <Row>
                <TabSwitcher {...tabSwitcherProps}>
                  <TabPanel value={RiskTabs.DETAILS}>
                    {/*TODO: Move this logic to a component hook to avoid props drilling*/}
                    <PolicyAdvisorErrorBoundary
                      componentName={ErrorBoundaryComponent.POLICY_ADVISOR_RISK_DETAILS_RISK_DETAILS_TAB}
                    >
                      <RiskDetailsTab
                        policiesMetadataResponse={policiesMetadataResponse}
                        getRiskResponse={getRiskResponse}
                        riskId={riskId}
                        updateRisk={updateRisk}
                        ref={firstInputRef}
                      />
                    </PolicyAdvisorErrorBoundary>
                  </TabPanel>
                  <TabPanel value={RiskTabs.AUDIT_HISTORY}>
                    <PolicyAdvisorErrorBoundary
                      componentName={ErrorBoundaryComponent.POLICY_ADVISOR_RISK_DETAILS_RISK_AUDIT_TAB}
                    >
                      <RiskAuditTab getRiskResponse={getRiskResponse} />
                    </PolicyAdvisorErrorBoundary>
                  </TabPanel>
                </TabSwitcher>
              </Row>
            </Col>
          </Card>
        </Col>
      </Col>
    </Container>
  );
};

export default RiskDetailsComponent;
