import React, { useContext } from 'react';
import { Row, View } from '@amzn/stencil-react-components/layout';
import { CommonContext } from 'src/components/navbar/CommonContext';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import { FilterChip, FilterList, FilterPopover } from '@amzn/stencil-react-components/filtering';
import { useComponentLoadedLatencyMetric } from 'src/hooks';
import { metricConfig } from 'src/constants';

const LOADING_WORKSPACES = 'Loading Workspaces';

const WorkspaceSelector = () => {
  const { workspaceContext, setWorkspaceContext } = useContext(CommonContext);

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.WorkspaceSelectorComponent.componentName,
    latencyAction: metricConfig.WorkspaceSelectorComponent.actions.WORKSPACE_SELECTOR_LOAD_LATENCY,
    isComponentLoaded: !workspaceContext.isWorkspaceLoading,
  });

  return (
    <View>
      {workspaceContext.isWorkspaceLoading ? (
        <Row gridGap={16}>
          <Spinner color={'#FFF'} size={SpinnerSize.Small} />
          <Text fontSize={'T300'} color={'#FFF'}>
            {LOADING_WORKSPACES}
          </Text>
        </Row>
      ) : (
        workspaceContext.workspaces &&
        workspaceContext.workspaces.length > 0 && (
          <FilterPopover triggerComponent={<FilterChip>Workspace</FilterChip>}>
            <FilterList
              isRequired
              onFilterChange={(value) => {
                setWorkspaceContext((prevState) => {
                  return {
                    ...prevState,
                    selectedWorkspace: workspaceContext.workspaces.find(
                      (workspace) => workspace.namespaceId === value,
                    )!,
                  };
                });
              }}
              options={workspaceContext.workspaces?.map((option) => ({
                label: option.name,
                value: option.namespaceId,
              }))}
              titleText="Select a workspace"
              value={workspaceContext.selectedWorkspace.namespaceId}
            />
          </FilterPopover>
        )
      )}
    </View>
  );
};

export default WorkspaceSelector;
