import React from 'react';
import { AuditView } from 'src/components/riskdetails/constants';
import { H2, Text } from '@amzn/stencil-react-components/text';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader, WithModal } from '@amzn/stencil-react-components/modal';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';

interface RiskAuditTableTextProps {
  content: string;
  field?: AuditView;
}

const BUTTON_STYLES = {
  seeMoreButton: {
    backgroundColor: 'transparent',
    color: '#007bff',
    border: 'none',
    cursor: 'pointer',
    marginLeft: '4px',
    padding: '0',
    fontSize: 'inherit',
    textDecoration: 'underline',
  },
};

const CONTENT_SIZE_LIMIT = 60;

const RiskAuditTableTextElement = ({ content, field }: RiskAuditTableTextProps) => {
  if (content.length < CONTENT_SIZE_LIMIT) return content;

  const renderModal = ({ close }: { close: () => void }) => (
    <ModalContainer>
      <ModalHeader onCloseButtonClick={close} closeButtonAltText="Close modal">
        <H2>{field}</H2>
      </ModalHeader>
      <ModalBody>
        <Text>{content}</Text>
      </ModalBody>
      <ModalFooter>
        <Button key="dismiss" onClick={close} variant={ButtonVariant.Primary}>
          Dismiss
        </Button>
      </ModalFooter>
    </ModalContainer>
  );

  return (
    <Text>
      {content.slice(0, CONTENT_SIZE_LIMIT)}
      <WithModal renderModal={renderModal}>
        {({ open }) => (
          <button onClick={open} style={BUTTON_STYLES.seeMoreButton} aria-label={'More details'}>
            ...see more
          </button>
        )}
      </WithModal>
    </Text>
  );
};

export default RiskAuditTableTextElement;
