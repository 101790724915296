import React from 'react';
import { Row, Spacer } from '@amzn/stencil-react-components/layout';
import { PageHeader, PageHeaderList } from '@amzn/stencil-react-components/page';
import { H2 } from '@amzn/stencil-react-components/text';
import { Avatar, AvatarSize } from '@amzn/stencil-react-components/avatar';
import { HOME_PAGE_ROUTE } from 'src/constants';
import SideNavigation from 'src/components/navbar/SideNavigation';
import WorkspaceSelector from 'src/components/workspaces/WorkspaceSelector';
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from 'src/components';
import { Button, ButtonColorScheme, ButtonVariant } from '@amzn/stencil-react-components/button';
import AMAZON_LOGO from '../../../public/assests/images/AMAZON_LOGO.svg';

interface NavigationBarComponentProps {
  viewOnly: boolean;
}

export const NavigationBarComponent = (props: NavigationBarComponentProps) => {
  const navigate = useNavigate();
  const { authenticatedUser } = useAuthentication();
  return (
    <PageHeader isFullWidth={true} isFixed={true}>
      {!props.viewOnly && <SideNavigation key="menu" />}
      <Row justifyContent="space-between" width={'100%'} flexWrap={'wrap'}>
        <Button
          color="neutral0"
          variant={ButtonVariant.Tertiary}
          colorScheme={ButtonColorScheme.DarkBackground}
          aria-label="Policy Advisor Home"
          onClick={() => {
            navigate(HOME_PAGE_ROUTE);
          }}
        >
          <img src={AMAZON_LOGO} alt="Amazon Logo" style={{ height: '2rem' }} />
          <H2 fontWeight="regular" color="white">
            Policy Advisor
          </H2>
        </Button>
        <Row>
          {!props.viewOnly && <PageHeaderList items={[<WorkspaceSelector key={'workspace-selector'} />]} />}
          <Spacer width={'2rem'} />
          <Avatar username={authenticatedUser?.userName || ''} showUsername={false} size={AvatarSize.Small} />
        </Row>
      </Row>
    </PageHeader>
  );
};
