import { styledWithTheme } from '@amzn/stencil-react-components/context';
import { Link } from 'react-router-dom';

const RouterLink = styledWithTheme(Link)(({ theme }) => ({
  color: theme.selectors.color('primary70'),
  fontFamily: theme.selectors.fontFamily('primary') ?? undefined,
  fontWeight: theme.selectors.fontWeight('bold') ?? undefined,
}));

export default RouterLink;
