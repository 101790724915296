import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GET_RISK_API } from 'src/paths';
import { GetRiskRequest, GetRiskResponse } from 'src/models';

export const useGetRisk = ({ riskId = '' }: Partial<GetRiskRequest>): UseQueryResult<GetRiskResponse> => {
  const path = GET_RISK_API(riskId);
  return useQuery<unknown, unknown, GetRiskResponse>(
    ['get-risk', riskId],
    () =>
      getData<GetRiskResponse>({
        path,
      }),
    {
      enabled: !!riskId,
    },
  );
};
