export const getValueFromPath = <T>(object: T, path: string) => {
  const keys = path.split('.');
  let result: object = { ...object } as object;

  for (const key of keys) {
    if (result !== null && typeof result === 'object' && key in result) {
      result = result[key as keyof typeof result];
    } else {
      return undefined;
    }
  }
  return result;
};
