import { DateTime } from 'luxon';

/**
 * Generates EPOCH timestamp from date string.
 * @param date
 */
export const getEpochFromDateString = (date: string): number => new Date(date).getTime() / 1000;

/**
 * Returns today/current date as string.
 */
export const getTodayDate = (): string => {
  const date = new Date();
  return date.toISOString();
};

export const NUMBER_OF_MILLISECONDS_IN_A_SECOND = 1000;

export enum DateTimeFormats {
  DATE_TIME_DD_MMM_YYYY_HHMM_A = 'dd MMM, yyyy hh:mm a',
  DATE_TIME_DD_MMM_YYYY = 'dd MMM, yyyy',
  DATE_TIME_YY_MM_DD = 'yyyy-MM-dd',
}

export const convertDateTimeToFormat = (dateTime: string = '', format: DateTimeFormats) => {
  if (DateTime.fromISO(dateTime).isValid) {
    return DateTime.fromISO(dateTime).toFormat(format);
  }
  return '-';
};

export function getDateBeforeNDays(n: number): Date {
  const currentDate = new Date();
  const prevDate = new Date(currentDate);
  prevDate.setDate(currentDate.getDate() - n);
  return prevDate;
}
