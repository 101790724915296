import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GetExecutionsRequest, GetExecutionsResponse } from 'src/models/api-models/getExecutions';
import { GET_EXECUTIONS_API } from 'src/paths';
export const useGetExecutions = (request: GetExecutionsRequest): UseQueryResult<GetExecutionsResponse> => {
  const path = request.executionStatus
    ? GET_EXECUTIONS_API(request.namespaceId, request.executionStatus)
    : GET_EXECUTIONS_API(request.namespaceId);

  return useQuery<unknown, unknown, GetExecutionsResponse>(
    [request.namespaceId, request.executionStatus],
    () =>
      getData<GetExecutionsResponse>({
        path,
      }),
    {
      enabled: true, // Always enabled since executionStatus is optional
      staleTime: 1000 * 60,
    },
  );
};
