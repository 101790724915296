import { CommonContext } from 'src/components';
import React, { ReactNode, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { PathPermissionMap } from 'src/components/authorization/constants';
import { Permissions } from 'src/models';
import { AUTHORIZATION_ERROR_CODE, AUTHORIZATION_ERROR_MESSAGE } from 'src/constants/ErrorConstants';
import { ErrorPage } from 'src/components/error-pages';

const checkPermission = (currentPath: string, permissions: Permissions): boolean => {
  const normalizedPath = currentPath.toLowerCase().replace(/\/$/, '');

  for (const [path, requiredPermission] of Object.entries(PathPermissionMap)) {
    if (normalizedPath.startsWith(path.toLowerCase())) {
      return permissions[requiredPermission];
    }
  }
  return permissions.view;
};

export const AuthorizationController = (props: { element: ReactNode }) => {
  const { permissions } = useContext(CommonContext).permissionsContext;
  const location = useLocation();

  if (checkPermission(location.pathname, permissions)) {
    return <>{props.element}</>;
  }

  return <ErrorPage errorMessage={AUTHORIZATION_ERROR_MESSAGE} errorCode={AUTHORIZATION_ERROR_CODE} />;
};
