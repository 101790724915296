import { Checkbox, FormWrapper, InputWrapper, TextArea } from '@amzn/stencil-react-components/form';
import { Col, Container, GridContainer, GridItem, Row, Spacer } from '@amzn/stencil-react-components/layout';
import React from 'react';
import { Card } from '@amzn/stencil-react-components/card';
import { H3, Label } from '@amzn/stencil-react-components/text';
import { ComparisonGroupType } from 'src/models';
import { useCreateExecutionWorkflowContext } from '../context';
import { PolicyTile } from '../shared';

export const CreateExecutionPreviewStep = () => {
  const { selectedPolicies, compareWithMyTimeCheckbox } = useCreateExecutionWorkflowContext();

  // const Parameters = () => {
  //   return (
  //     <FormWrapper>
  //       {selectedParameters?.map((parameter) => (
  //         <ParameterTile key={parameter.id} viewMode={true} parameter={parameter} />
  //       ))}
  //     </FormWrapper>
  //   );
  // };

  return (
    <Container>
      <FormWrapper>
        <GridContainer>
          <GridItem lg={6} md={4} sm={2}>
            <Card width={'100%'}>
              <Col gridGap={'S400'} width={'100%'} tabIndex={0}>
                <H3>Group {ComparisonGroupType.A}</H3>
                {selectedPolicies[ComparisonGroupType.A].map((policyMetadata) => (
                  <PolicyTile
                    viewMode={true}
                    group={ComparisonGroupType.A}
                    key={policyMetadata.policyId}
                    policyMetadata={policyMetadata}
                  />
                ))}
              </Col>
            </Card>
          </GridItem>
          <GridItem lg={6} md={4} sm={2}>
            <Card width={'100%'}>
              <Col gridGap={'S400'} width={'100%'} tabIndex={0}>
                <H3>Group {ComparisonGroupType.B}</H3>
                {selectedPolicies[ComparisonGroupType.B].map((policyMetadata) => (
                  <PolicyTile
                    viewMode={true}
                    group={ComparisonGroupType.B}
                    key={policyMetadata.policyId}
                    policyMetadata={policyMetadata}
                  />
                ))}
              </Col>
            </Card>
          </GridItem>
        </GridContainer>
        {compareWithMyTimeCheckbox ? (
          <Row>
            <Checkbox
              disabled={true}
              name="my-time-checkbox-preview"
              id="my-time-checkbox-preview"
              checked={compareWithMyTimeCheckbox}
            />
            <Spacer width={'S400'} />
            <Label htmlFor="my-time-checkbox-preview">
              Comparing Group A policies with its corresponding My Time policy.
            </Label>
          </Row>
        ) : (
          <></>
        )}
        {/*<Card width={'100%'}>*/}
        {/*  <Col width={'100%'}>*/}
        {/*    <InputWrapper*/}
        {/*      id="additional-parameters-select"*/}
        {/*      labelText="Parameters"*/}
        {/*      renderLabel={() => <H3>Parameters</H3>}*/}
        {/*    >*/}
        {/*      {(inputProps) => <Parameters {...inputProps} />}*/}
        {/*    </InputWrapper>*/}
        {/*  </Col>*/}
        {/*</Card>*/}
        {/*<Card width={'100%'}>*/}
        {/*  <Col width={'100%'}>*/}
        {/*    <InputWrapper*/}
        {/*      id="additional-instruction-input"*/}
        {/*      labelText="Additional Instructions"*/}
        {/*      renderLabel={() => <H3>Additional Instructions</H3>}*/}
        {/*    >*/}
        {/*      {(inputProps) => (*/}
        {/*        <TextArea value={additionalInstructions} disabled={true} {...inputProps} resize={'vertical'} />*/}
        {/*      )}*/}
        {/*    </InputWrapper>*/}
        {/*  </Col>*/}
        {/*</Card>*/}
      </FormWrapper>
    </Container>
  );
};
