import { Policy, UserInput } from './common';

export enum ExecutionStatus {
  POLICY_COLLECTION_NOT_STARTED = 'POLICY_COLLECTION_NOT_STARTED',
  POLICY_COLLECTION_IN_PROGRESS = 'POLICY_COLLECTION_IN_PROGRESS',
  POLICY_COLLECTION_COMPLETED = 'POLICY_COLLECTION_COMPLETED',
  POLICY_COLLECTION_FAILED = 'POLICY_COLLECTION_FAILED',
  COMPARISON_NOT_STARTED = 'COMPARISON_NOT_STARTED',
  COMPARISON_IN_PROGRESS = 'COMPARISON_IN_PROGRESS',
  COMPARISON_COMPLETED = 'COMPARISON_COMPLETED',
  COMPARISON_FAILED = 'COMPARISON_FAILED',
  DRAFT = 'DRAFT',
  IN_REVIEW = 'IN_REVIEW',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  REWORK = 'REWORK',
}

export interface GetExecutionsRequest {
  namespaceId: string;
  executionStatus?: ExecutionStatus;
}

export interface PolicyCollectionItem {
  id: string;
  s3Path: string;
  additionalData?: AdditionalDataMap;
}

export interface AdditionalDataMap {
  key: string;
  value: string;
}

export interface PolicyComparisonData {
  result: string;
}

export interface ExecutionDetails {
  executionId: string;
  version: number;
  requesterAlias: string;
  executionStatus: ExecutionStatus;
  namespaceId: string;
  namespaceVersion: number;
  policies?: Policy[];
  userInput?: UserInput;
  policyCollectionData?: PolicyCollectionItem[];
  policyComparisonData?: PolicyComparisonData;
  reviewId?: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface GetExecutionsResponse {
  executions: ExecutionDetails[];
}
