import React from 'react';
import { Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconChevronRight } from '@amzn/stencil-react-components/icons';
import { useNavigate } from 'react-router-dom';
import { formatEnumString } from 'src/components/utils';
import ExtendedRisk from 'src/components/dashboard/models/ExtendedRisk';
import { POLICY_DETAILS, RISK_DETAILS } from 'src/constants';
import { RouterLink } from 'src/components/shared/router-link';
import GEN_AI_LOGO from 'src/assets/GEN_AI_LOGO.svg';
import { Row, View } from '@amzn/stencil-react-components/layout';
import { SYSTEM_USER } from 'src/constants/CommonConstants';

export const RiskName = ({ data: { title, riskId, createdBy } }: { data: ExtendedRisk }) => {
  return (
    <Row gridGap="S100">
      {createdBy.toLowerCase() == SYSTEM_USER && (
        <View height="1rem" width="1rem" justifyContent="center" alignItems="center">
          <img src={GEN_AI_LOGO} alt="GEN AI Logo" />
        </View>
      )}
      <RouterLink to={`${RISK_DETAILS}/${riskId}`}>{title}</RouterLink>
    </Row>
  );
};

export const PolicyName = ({ data: { policyName, policyId, namespaceId } }: { data: ExtendedRisk }) => {
  return <RouterLink to={`${POLICY_DETAILS}/${namespaceId}/${policyId}`}>{policyName}</RouterLink>;
};

export const RiskStatus = ({ data: { status } }: { data: ExtendedRisk }) => {
  return <Text>{formatEnumString(status)}</Text>;
};

export const RiskPriority = ({ data: { priority } }: { data: ExtendedRisk }) => {
  return <Text>{formatEnumString(priority)}</Text>;
};

export const RiskETA = ({ data: { fixByDate } }: { data: ExtendedRisk }) => {
  return <Text>{fixByDate}</Text>;
};

export const Country = ({ data: { country } }: { data: ExtendedRisk }) => {
  return <Text>{country}</Text>;
};

export const ViewDetails = ({ data: { riskId } }: { data: ExtendedRisk }) => {
  const navigate = useNavigate();
  return (
    <Button
      icon={<IconChevronRight />}
      onClick={() => navigate(`${RISK_DETAILS}/${riskId}`)}
      variant={ButtonVariant.Tertiary}
    />
  );
};
