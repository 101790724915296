import { useState } from 'react';
import { getSetDifference } from 'src/components/utils';
import { FilteringOptions } from '@amzn/stencil-react-components/filtering';
import { FilterOptionDetails } from 'src/components/shared/table-filters/TableFilters';
import { getValueFromPath } from 'src/components/shared/table-filters/utils/tableFilterUtils';

interface FilterKey {
  keyName: string;
  keyPath: string;
}

interface TableFilterState<T> {
  data: T[];
  filterKeys: FilterKey[];
}

const useTableFilter = <T>({ data, filterKeys }: TableFilterState<T>) => {
  const [filterSelections, setFilterSelections] = useState<Record<string, string[]>>({});

  const handleFilterChange = (filterKey: string, filterValue: string[]) => {
    setFilterSelections((selections) => ({
      ...selections,
      [filterKey]: filterValue,
    }));
  };

  const getFilterData = () => {
    return data.filter((dataEntry) => {
      return Object.entries(filterSelections).every(([filterName, selectedValues]) => {
        if (!selectedValues || !selectedValues.length) return true;

        const dataEntryValues = getValueFromPath(dataEntry, filterName) as string | string[] | undefined;
        if (Array.isArray(dataEntryValues)) {
          const dataEntryValueSet = new Set(dataEntryValues.length > 0 ? dataEntryValues : ['-']);
          const selectedValuesSet = new Set(selectedValues);
          // retain if at least on of the selected value is present in the data
          return getSetDifference(selectedValuesSet, dataEntryValueSet).size < selectedValuesSet.size;
        } else {
          return selectedValues.includes(dataEntryValues || '-');
        }
      });
    });
  };

  const getFilterOptionsMap = (): Record<string, FilterOptionDetails> => {
    const filterOptionsMap: Record<string, FilterOptionDetails> = {};

    filterKeys.forEach(({ keyName, keyPath }) => {
      const distinctValues = data.reduce((set, currentDataEntry) => {
        const values = getValueFromPath(currentDataEntry, keyPath) as string | string[] | undefined;
        if (Array.isArray(values) && values.length > 0) {
          values.forEach((value) => set.add(value));
        } else if (!Array.isArray(values) && values) {
          set.add(values);
        } else {
          set.add('-');
        }
        return set;
      }, new Set<string>());

      filterOptionsMap[keyPath] = {
        filterName: keyName,
        filterOptions: Array.from(distinctValues).map((value) => ({ label: value, value: value } as FilteringOptions)),
      };
    });

    return filterOptionsMap;
  };

  return {
    filteredData: getFilterData(),
    filterSelections,
    setFilterSelections,
    handleFilterChange,
    filterOptionsMap: getFilterOptionsMap(),
  };
};

export default useTableFilter;
