export enum ErrorBoundaryComponent {
  POLICY_ADVISOR_DASHBOARD = 'PolicyAdvisorDashboard',
  POLICY_ADVISOR_RISKS_TABLE = 'PolicyAdvisorRisksTable',
  POLICY_ADVISOR_ASSESSMENTS_TABLE = 'PolicyAdvisorAssessmentsTable',
  POLICY_ADVISOR_REVIEW_SUBMISSION_WORKFLOW = 'PolicyAdvisorReviewSubmissionWorkflow',
  POLICY_ADVISOR_POLICY_REVIEW_WORKFLOW = 'PolicyAdvisorPolicyReviewWorkflow',
  POLICY_ADVISOR_EXECUTION_DETAILS_PAGE = 'PolicyAdvisorExecutionDetailsPage',
  POLICY_ADVISOR_CREATE_EXECUTION_WORKFLOW = 'PolicyAdvisorCreateExecutionWorkflow',
  POLICY_ADVISOR_CREATE_EXECUTION_WORKFLOW_SELECT_POLICIES = 'PolicyAdvisorCreateExecutionWorkflowSelectPolicies',
  POLICY_ADVISOR_CREATE_EXECUTION_WORKFLOW_PREVIEW_ASSESSMENT = 'PolicyAdvisorCreateExecutionWorkflowPreviewAssessment',
  POLICY_ADVISOR_POLICY_MARKET_PLACE = 'PolicyAdvisorPolicyMarketPlace',
  POLICY_ADVISOR_RISK_DETAILS = 'PolicyAdvisorRiskDetails',
  POLICY_ADVISOR_RISK_DETAILS_RISK_DETAILS_TAB = 'PolicyAdvisorRiskDetailsRiskDetailsTab',
  POLICY_ADVISOR_RISK_DETAILS_RISK_AUDIT_TAB = 'PolicyAdvisorRiskDetailsRiskAuditTab',
  POLICY_ADVISOR_POLICY_DETAILS = 'PolicyAdvisorPolicyDetails',
  POLICY_ADVISOR_REGISTER_POLICY = 'PolicyAdvisorRegisterPolicy',
  POLICY_ADVISOR_CREATE_RISK = 'PolicyAdvisorCreateRisk',
}

export const ErrorBoundaryMetricName = 'Policy Advisor Error Boundary';
