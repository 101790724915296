import { formatEnumString, RISK_STATUS_COLOR } from 'src/components/utils';
import { Row } from '@amzn/stencil-react-components/layout';
import { InputFooter, Select } from '@amzn/stencil-react-components/form';
import React from 'react';
import { RiskStatus } from 'src/models';
import { EditRiskState } from 'src/components/riskdetails/hooks';
import { Chip, ChipSize } from '@amzn/stencil-react-components/chip';
import { EMPTY_SEPARATOR } from 'src/components/riskdetails/constants';

interface RiskStatusProps {
  id: string;
  editRiskState: EditRiskState;
  setStatus: (status: RiskStatus) => void;
}

const RiskStatusElement = ({
  id,
  editRiskState: {
    isEditing,
    edits: { status },
    showErrors,
  },
  setStatus,
}: RiskStatusProps) => {
  const options = Object.values(RiskStatus).filter(
    (option) => status === RiskStatus.UNACKNOWLEDGED || option !== RiskStatus.ACKNOWLEDGED,
  );

  const isError = showErrors && !formatEnumString(status);
  return isEditing ? (
    <>
      <Select
        id={id}
        placeholder="Select a status"
        aria-required={true}
        aria-labelledby={`${id}-label ${id}-toggle-button`}
        aria-describedby={`${id}-footer`}
        options={options}
        value={formatEnumString(status)}
        onChange={(value: RiskStatus) => {
          setStatus(value);
        }}
        renderOption={formatEnumString}
        error={isError}
      />
      {isError && (
        <InputFooter id={`${id}-footer`} error>
          Please select a status
        </InputFooter>
      )}
    </>
  ) : status ? (
    <Row id={id} aria-labelledby={`${id}-label`} alignItems="center">
      <Chip size={ChipSize.Default} backgroundColor={RISK_STATUS_COLOR[status]}>
        {formatEnumString(status)}
      </Chip>
    </Row>
  ) : (
    <>{EMPTY_SEPARATOR}</>
  );
};

export default RiskStatusElement;
