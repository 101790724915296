import React from 'react';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H1, H4, Text } from '@amzn/stencil-react-components/text';
import { useComponentLoadedLatencyMetric, usePoliciesMetadata } from 'src/hooks';
import { useParams, useNavigate } from 'react-router-dom';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { TableElement, TbodyElement, TrElement } from '@amzn/stencil-react-components/table';
import { Chip, ChipSize } from '@amzn/stencil-react-components/chip';
import { Card } from '@amzn/stencil-react-components/card';
import { DomainType } from 'src/models';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { metricConfig, POLICY_MARKETPLACE } from 'src/constants';
import { POLICY_SOURCE_TYPE_DISPLAY_CONSTANT } from 'src/constants/DisplayConstants';
import { StyledTdElement, StyledThElement } from 'src/components/shared/table-components';
import PolicyLinks from './PolicyLinks';

const POLICY_DETAILS_ERROR_MESSAGE = 'Unable to load policy details due to some error.';
const POLICY_NOT_FOUND_MESSAGE = 'Policy with this ID does not exist in the application.';
const COLON_SEPARATOR = ': ';
const EMPTY_SEPARATOR = '-';

const PolicyDetailsPage: React.FC = () => {
  const { namespaceId, policyId } = useParams<{ namespaceId: string; policyId: string }>();
  const { data: policiesMetadata, isLoading, isError } = usePoliciesMetadata({ namespaceId: namespaceId || '' });
  const navigate = useNavigate();

  const policyMetadata = policiesMetadata?.policiesMetadata.find(
    (policyMetadata) => policyMetadata.policyId === policyId,
  );

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.PolicyDetailsPage.componentName,
    latencyAction: metricConfig.PolicyDetailsPage.actions.POLICY_DETAILS_LOAD_LATENCY,
    isComponentLoaded: !isLoading,
  });

  if (policyMetadata === undefined) {
    return (
      <Container width={'80%'}>
        <MessageBanner type={MessageBannerType.Error}>{POLICY_NOT_FOUND_MESSAGE}</MessageBanner>
      </Container>
    );
  }

  const policyDetailsConfig = {
    Name: policyMetadata?.name,
    Domains: policyMetadata?.domains,
    Country: policyMetadata?.policyMetadataAttributes?.country || EMPTY_SEPARATOR,
    Type: POLICY_SOURCE_TYPE_DISPLAY_CONSTANT[policyMetadata?.sourceType],
    Description: policyMetadata?.description,
  };

  return (
    <Container width={'80%'}>
      {isLoading ? (
        <Row width={'100%'} justifyContent={'center'} margin={{ top: 'S800' }}>
          <Spinner size={SpinnerSize.Large} />
        </Row>
      ) : isError || !policyMetadata ? (
        <MessageBanner type={MessageBannerType.Error}>{POLICY_DETAILS_ERROR_MESSAGE}</MessageBanner>
      ) : (
        <Col width="80%" margin="auto">
          <H1 margin={{ top: '2rem', bottom: '1.5rem' }}>Policy Details</H1>
          <Card isElevated={true}>
            <Col width={'100%'} padding={'S500'}>
              <Row width={'100%'}>
                <TableElement style={{ border: '0px' }}>
                  <TbodyElement>
                    {Object.entries(policyDetailsConfig).map(([key, value]) => (
                      <TrElement key={key}>
                        <StyledThElement width={'20%'}>
                          <H4 textAlign={'justify'}>
                            {key}
                            {COLON_SEPARATOR}
                          </H4>
                        </StyledThElement>
                        <StyledTdElement>
                          {key === 'Domains' ? (
                            <Row flexWrap="wrap" gridGap="S100">
                              {(value as DomainType[])?.map((domain: string) => (
                                <Chip key={domain} size={ChipSize.Small}>
                                  {domain}
                                </Chip>
                              ))}
                            </Row>
                          ) : (
                            <Text textAlign="justify">{value}</Text>
                          )}
                        </StyledTdElement>
                      </TrElement>
                    ))}
                    <TrElement>
                      <StyledThElement width={'20%'}>
                        <H4 textAlign={'justify'}>Links{COLON_SEPARATOR}</H4>
                      </StyledThElement>
                      <StyledTdElement>
                        <PolicyLinks policyMetadata={policyMetadata} />
                      </StyledTdElement>
                    </TrElement>
                  </TbodyElement>
                </TableElement>
              </Row>
              <Spacer padding={'S200'} />
              <Row justifyContent="flex-end">
                <Button variant={ButtonVariant.Secondary} onClick={() => navigate(POLICY_MARKETPLACE)}>
                  Go Back
                </Button>
              </Row>
            </Col>
          </Card>
        </Col>
      )}
    </Container>
  );
};

export default PolicyDetailsPage;
