import { InputFooter, TextArea } from '@amzn/stencil-react-components/form';
import { Text } from '@amzn/stencil-react-components/text';
import React from 'react';
import { EditRiskState } from 'src/components/riskdetails/hooks';

interface RiskNameProps {
  id: string;
  editRiskState: EditRiskState;
  setDetails: (details: string) => void;
}

const RiskDetailsElement = ({
  id,
  editRiskState: {
    isEditing,
    edits: { details },
    showErrors,
  },
  setDetails,
}: RiskNameProps) => {
  const isError = showErrors && !details;
  return isEditing ? (
    <>
      <TextArea
        id={id}
        aria-required={true}
        aria-labelledby={`${id}-label`}
        aria-describedby={`${id}-footer`}
        resize={'vertical'}
        value={details}
        onChange={(event) => {
          setDetails(event.target.value);
        }}
        width="100%"
        height="10rem"
        error={isError}
      />
      {isError && (
        <InputFooter id={`${id}-footer`} error>
          Please enter risk details
        </InputFooter>
      )}
    </>
  ) : (
    <Text id={id} aria-labelledby={`${id}-label`} textAlign="justify">
      {details}
    </Text>
  );
};

export default RiskDetailsElement;
