import { useEffect, useState } from 'react';
import { useAuthentication } from 'src/components';
import { UploadFileContentMetadata, useUploadFilesOnPreSignedURL } from 'src/hooks/useUploadFilesOnPresignedURL';
import { SERVICE } from 'src/paths';
import { useCreatePolicyMetadata } from 'src/hooks/useCreatePolicyMetadata';
import {
  Country,
  CreatePolicyMetadataRequest,
  DomainType,
  JobClass,
  PolicyMetadataAttributes,
  SourceType,
  TrackingSources,
} from 'src/models';
import { useGenerateTags } from 'src/hooks/useGenerateTags';
import { getPolicyMetadataCountry } from 'src/components/policy-marketplace/utils/policyFilters';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';
import { getClickMetricDimensions } from 'src/utils';
import { metricConfig } from 'src/constants';

interface ErrorAttributes {
  isError: boolean;
  isWarning: boolean;
  message: string | undefined;
}
type FormField =
  | 'name'
  | 'description'
  | 'sourceType'
  | 'domain'
  | 'country'
  | 'trackingSources'
  | 'file'
  | 'generatedTags'
  | 'jobClass'
  | 'states';

type SelectChange = 'sourceType' | 'domain' | 'country' | 'jobClass' | 'states';

export type FormFieldErrors = Record<FormField, ErrorAttributes>;

export const COUNTRY_OPTIONS = Object.values(Country); // TODO: Add more countries in future.
export const JOB_CLASS_OPTIONS = Object.values(JobClass);

const COUNTRY_TO_STATE_MAP: Record<string, string[]> = {
  ['India']: ['Maharashtra', 'Telangana'],
};

const DEFAULT_ERROR_ATTRIBUTE: ErrorAttributes = {
  isError: false,
  isWarning: false,
  message: undefined,
};

export const usePolicyMetadataInputHook = (namespaceId: string) => {
  const { authenticatedUser } = useAuthentication();
  const { createPolicyMetadataState, createPolicyMetadata } = useCreatePolicyMetadata();

  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [sourceType, setSourceType] = useState<SourceType>();
  const [domain, setDomain] = useState<DomainType>();
  const [files, setFiles] = useState<File[]>();
  const [link, setLink] = useState<string>('');
  const [policyMetadataAttributes, setPolicyMetadataAttributes] = useState<PolicyMetadataAttributes>({});
  const [formFieldErrors, setFormFieldErrors] = useState<FormFieldErrors>({
    name: DEFAULT_ERROR_ATTRIBUTE,
    description: DEFAULT_ERROR_ATTRIBUTE,
    sourceType: DEFAULT_ERROR_ATTRIBUTE,
    domain: DEFAULT_ERROR_ATTRIBUTE,
    country: DEFAULT_ERROR_ATTRIBUTE,
    trackingSources: DEFAULT_ERROR_ATTRIBUTE,
    file: DEFAULT_ERROR_ATTRIBUTE,
    generatedTags: DEFAULT_ERROR_ATTRIBUTE,
    jobClass: DEFAULT_ERROR_ATTRIBUTE,
    states: DEFAULT_ERROR_ATTRIBUTE,
  });

  const [trackingSources, setTrackingSources] = useState<TrackingSources[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { uploadFilesOnPreSignedURL } = useUploadFilesOnPreSignedURL();
  const { generateTagsState, generateTags, resetGenerateTags } = useGenerateTags();
  const [isRegistrationSuccessful, setIsRegistrationSuccessful] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [stateOptions, setStateOptions] = useState<string[]>([]);

  const resetFormSelectorState = () => {
    setDomain(undefined);
    setPolicyMetadataAttributes({});
    setLink('');
    resetGenerateTags();
  };

  const resetPolicyNameDescription = () => {
    setName('');
    setDescription('');
    setSourceType(undefined);
  };

  //TODO: Add support for Other Source Types ( eg. Link Input With Scraping )
  const handleGenerateTags = async () => {
    let fileUrl;
    if (!handleValidateSourceContent()) {
      return;
    }
    try {
      logger.emitCountMetric({
        metricName: METRIC_NAME.CLICK,
        dimensions: getClickMetricDimensions(
          metricConfig.RegisterPolicyPage.componentName,
          metricConfig.RegisterPolicyPage.actions.GENERATE_TAGS_BUTTON_CLICK,
        ),
      });
      if (sourceType === SourceType.GOVT) {
        fileUrl = link;
      } else if (sourceType === SourceType.INSIDE) {
        // This flow is currently not being used but can be used in the future as we support this functionality
        setIsUploadingFile(true);
        const uploadResult = await uploadFilesToS3();
        if (!uploadResult || uploadResult.length === 0) {
          setError('Failed to upload file. Please try again.');
          return;
        }
        fileUrl = uploadResult[0];
        updateTrackingSourceWithFile(fileUrl);
      }
      if (fileUrl) {
        generateTags({
          referenceId: fileUrl,
          sourceType: sourceType as SourceType,
          userId: authenticatedUser?.userName || '',
          workspaceId: namespaceId,
        });
      }
      updateFieldError('generatedTags', DEFAULT_ERROR_ATTRIBUTE);
    } catch (error) {
      updateFieldError('generatedTags', {
        isError: true,
        isWarning: false,
        message: 'Failed to generate tags. Please try again.',
      });
    } finally {
      setIsUploadingFile(false);
    }
  };

  const handleCountrySelection = (country: Country) => {
    if (!COUNTRY_OPTIONS.includes(country)) {
      updateFieldError('country', {
        isError: true,
        isWarning: false,
        message: 'Country is not supported.',
      });
    } else {
      handleCountryChange(country);
    }
  };

  const handleDomainSelection = (domains: string[]) => {
    if (domains.length === 0) {
      updateFieldError('domain', {
        isError: true,
        isWarning: false,
        message: 'No domain provided.',
      });
      return;
    }
    // TODO: Update domain implementation when generate Tags and create policy metadata API support the same number of domains
    const mappedDomain = domains[0] as keyof typeof DomainType;
    if (Object.values(DomainType).includes(DomainType[mappedDomain])) {
      handleDomainChange(DomainType[mappedDomain]);
    } else {
      updateFieldError('domain', {
        isError: true,
        isWarning: false,
        message: 'Domain is not supported.',
      });
    }
  };

  const handleValidateSourceContent = () => {
    if (!validateSourceType()) {
      return false;
    }

    if (sourceType === SourceType.INSIDE) {
      return validateFile();
    } else if (sourceType === SourceType.GOVT) {
      return validateLink();
    }
    // TODO: Add Logic to Validate Source Content for File as well as Link based Inputs
  };

  const handleInputChange = (field: 'name' | 'description', value: string) => {
    switch (field) {
      case 'name':
        setName(value);
        updateFieldError('name', DEFAULT_ERROR_ATTRIBUTE);
        break;
      case 'description':
        setDescription(value);
        updateFieldError('description', DEFAULT_ERROR_ATTRIBUTE);
    }
  };

  const handleSelectChange = (field: SelectChange) => (value: any) => {
    switch (field) {
      case 'sourceType':
        setSourceType(value);
        if (value === SourceType.INSIDE) {
          setLink('');
        } else if (value === SourceType.GOVT) {
          setFiles(undefined);
        }
        updateFieldError('sourceType', DEFAULT_ERROR_ATTRIBUTE);
        break;
      case 'domain':
        handleDomainChange(value);
        break;
      case 'country':
        handleCountryChange(value);
        break;
      case 'jobClass':
        handleJobClassChange(value);
        break;
      case 'states':
        handleStatesChange(value);
        break;
    }
  };

  const handleDomainChange = (value: DomainType) => {
    setDomain(value);
    updateFieldError('domain', DEFAULT_ERROR_ATTRIBUTE);
  };

  const handleCountryChange = (value: string) => {
    setPolicyMetadataAttributes((prevAttributes) => ({
      ...prevAttributes,
      country: value,
    }));

    const states = COUNTRY_TO_STATE_MAP[value];
    if (states) {
      setStateOptions(states);
    }
    setPolicyMetadataAttributes((prevAttributes) => ({
      ...prevAttributes,
      states: [],
      jobClasses: [],
    }));
    updateFieldError('country', DEFAULT_ERROR_ATTRIBUTE);
    updateFieldError('jobClass', DEFAULT_ERROR_ATTRIBUTE);
    updateFieldError('states', DEFAULT_ERROR_ATTRIBUTE);
  };

  const handleStatesChange = (values: string[]) => {
    setPolicyMetadataAttributes((prevAttributes) => ({
      ...prevAttributes,
      states: values,
    }));
    updateFieldError('states', DEFAULT_ERROR_ATTRIBUTE);
  };

  const handleJobClassChange = (values: string[]) => {
    setPolicyMetadataAttributes((prevAttributes) => ({
      ...prevAttributes,
      jobClasses: values,
    }));
    updateFieldError('jobClass', DEFAULT_ERROR_ATTRIBUTE);
  };

  const updateFieldError = (field: FormField, errorAttributes: ErrorAttributes) => {
    setFormFieldErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorAttributes,
    }));
  };

  const resetAllErrors = () => {
    setFormFieldErrors({
      name: DEFAULT_ERROR_ATTRIBUTE,
      description: DEFAULT_ERROR_ATTRIBUTE,
      sourceType: DEFAULT_ERROR_ATTRIBUTE,
      domain: DEFAULT_ERROR_ATTRIBUTE,
      country: DEFAULT_ERROR_ATTRIBUTE,
      trackingSources: DEFAULT_ERROR_ATTRIBUTE,
      file: DEFAULT_ERROR_ATTRIBUTE,
      generatedTags: DEFAULT_ERROR_ATTRIBUTE,
      jobClass: DEFAULT_ERROR_ATTRIBUTE,
      states: DEFAULT_ERROR_ATTRIBUTE,
    });
  };

  const validateName = () => {
    if (!name) {
      updateFieldError('name', {
        isError: true,
        isWarning: false,
        message: 'Policy name is required. Please enter a name.',
      });
      return false;
    }
    return true;
  };

  const validateDescription = () => {
    if (!description) {
      updateFieldError('description', {
        isError: true,
        isWarning: false,
        message: 'Policy description is required. Please enter a description.',
      });
      return false;
    }
    return true;
  };

  const validateSourceType = () => {
    if (!sourceType) {
      updateFieldError('sourceType', {
        isError: true,
        isWarning: false,
        message: 'Please select a source type for the policy.',
      });
      return false;
    }
    return true;
  };

  const validateDomain = () => {
    if (!domain) {
      updateFieldError('domain', {
        isError: true,
        isWarning: false,
        message: 'Please select a domain for the policy.',
      });
      return false;
    }
    return true;
  };

  const validatePolicyMetadataAttributes = () => {
    let isPolicyMetadataAttributesValid = true;
    if (!policyMetadataAttributes.country) {
      isPolicyMetadataAttributesValid = false;
      updateFieldError('country', {
        isError: true,
        isWarning: false,
        message: 'Please select a country for the policy.',
      });
    }

    if (policyMetadataAttributes?.country) {
      if (
        shouldShowStateSelection() &&
        (!policyMetadataAttributes.states || policyMetadataAttributes?.states?.length === 0)
      ) {
        isPolicyMetadataAttributesValid = false;
        updateFieldError('states', {
          isError: true,
          isWarning: false,
          message: 'Please select a state for the policy.',
        });
      }
      if (!policyMetadataAttributes.jobClasses || policyMetadataAttributes?.jobClasses?.length === 0) {
        isPolicyMetadataAttributesValid = false;
        updateFieldError('jobClass', {
          isError: true,
          isWarning: false,
          message: 'Please select a job class for the policy.',
        });
      }
    }
    return isPolicyMetadataAttributesValid;
  };

  const validateTrackingSource = () => {
    if (sourceType == SourceType.GOVT && trackingSources.length == 0) {
      updateFieldError('trackingSources', {
        isError: true,
        isWarning: false,
        message: ' Please provide the policy link.',
      });
      return false;
    }
    updateFieldError('trackingSources', DEFAULT_ERROR_ATTRIBUTE);
    return true;
  };

  const validateCountry = (country: string | undefined) => {
    if (!country || !COUNTRY_OPTIONS.includes(getPolicyMetadataCountry(country))) {
      updateFieldError('country', {
        isError: true,
        isWarning: false,
        message: 'Please select a valid country for the policy',
      });
      return false;
    }
    return true;
  };

  const validateFile = () => {
    if (!files || files.length === 0) {
      updateFieldError('file', {
        isError: true,
        isWarning: false,
        message: 'Please upload a file.',
      });
      return false;
    }
    return true;
  };

  const validateLink = () => {
    if (!link) {
      updateFieldError('trackingSources', {
        isError: true,
        isWarning: false,
        message: ' Please provide the policy link.',
      });
      return false;
    }
    updateFieldError('trackingSources', DEFAULT_ERROR_ATTRIBUTE);
    return true;
  };

  const validatePolicyMetadataInputForm = () => {
    resetAllErrors();
    return [
      validateName(),
      validateDescription(),
      validateDomain(),
      validatePolicyMetadataAttributes(),
      validateSourceType(),
      validateTrackingSource(),
      // Currently supporting only GOVT Source Type hence all other source types fail validation as they are not valid
      sourceType === SourceType.GOVT ? validateLink() : false,
      validateCountry(policyMetadataAttributes.country),
    ].every(Boolean);
  };

  const uploadFilesToS3 = async () => {
    try {
      if (!files || files.length === 0) {
        throw new Error('No file selected');
      }
      const fileToUpload = files[0];
      const { name } = fileToUpload;
      const content = await getFileContentAsString(fileToUpload);
      return await uploadFilesOnPreSignedURL([
        {
          fileContent: content,
          fileName: name,
          service: SERVICE.PMS,
          namespaceId: namespaceId,
        } as UploadFileContentMetadata,
      ]);
    } catch (error) {
      console.error('Error uploading file to s3:', error);
      throw error;
    }
  };

  const handleSubmit = async (onSubmit: () => void) => {
    if (!validatePolicyMetadataInputForm()) {
      return;
    }
    setIsSubmitting(true);
    setError(null);

    try {
      const payload: CreatePolicyMetadataRequest = {
        name: name!,
        description: description,
        sourceType: sourceType!,
        domains: [domain!],
        policyMetadataAttributes: policyMetadataAttributes,
        trackingSources: trackingSources,
        namespaceId: namespaceId,
        requesterAlias: authenticatedUser?.userName || '', // TODO: Remove and move to header.
      };
      setIsRegistrationSuccessful(false);
      createPolicyMetadata(payload);
      resetFormSelectorState();
      resetPolicyNameDescription();
      onSubmit();
    } catch (err) {
      setError('Failed to create policy metadata. Please try again.');
      setIsRegistrationSuccessful(false);
    } finally {
      setIsSubmitting(false);
    }
  };

  const getFileContentAsString = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event?.target?.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const handleLinkInput = (link: string) => {
    if (link.trim()) {
      setTrackingSources([
        {
          referenceId: link.trim(),
          sourceType: sourceType!,
        },
      ]);
      updateFieldError('trackingSources', DEFAULT_ERROR_ATTRIBUTE);
    }
    setLink(link.trim());
  };

  const handleFileUpload = (files: File[]) => {
    //TODO: Handle for multiple file, as currently workflow allows one upload file
    if (files && files.length > 0) {
      setFiles(files);
      resetFormSelectorState();
    }
  };

  const shouldDisableGenerateTagsButton = () => {
    return generateTagsState.loading || isUploadingFile;
  };

  const shouldEnableGenerateTagsLoader = () => {
    return generateTagsState.loading || isUploadingFile;
  };

  const shouldShowStateSelection = () => {
    return policyMetadataAttributes.country !== undefined && COUNTRY_TO_STATE_MAP[policyMetadataAttributes.country];
  };

  const updateTrackingSourceWithFile = (referenceId: string) => {
    setTrackingSources((prevSources) => {
      const newSources = {
        referenceId: referenceId,
        sourceType: sourceType,
      } as TrackingSources;
      return [...prevSources, newSources];
    });
  };

  useEffect(() => {
    if (generateTagsState.data?.tags) {
      const { country, domains, jobClasses } = generateTagsState.data.tags;
      handleCountrySelection(getPolicyMetadataCountry(country));
      handleDomainSelection(domains);
      handleJobClassChange(jobClasses);
    } else if (generateTagsState.error) {
      updateFieldError('generatedTags', {
        isError: true,
        isWarning: false,
        message: 'Failed to generate tags. Please try again.',
      });
    }
  }, [generateTagsState.data, generateTagsState.error]);

  useEffect(() => {
    if (createPolicyMetadataState?.data && !createPolicyMetadataState.error) {
      setIsRegistrationSuccessful(true);
    }
  }, [createPolicyMetadataState?.data]);

  return {
    name,
    description,
    sourceType,
    domain,
    policyMetadataAttributes,
    trackingSources,
    formFieldErrors,
    handleInputChange,
    handleSelectChange,
    handleCountrySelection,
    handleSubmit,
    handleFileUpload,
    handleLinkInput,
    isSubmitting,
    error,
    handleGenerateTags,
    isRegistrationSuccessful,
    setIsRegistrationSuccessful,
    shouldDisableGenerateTagsButton,
    shouldEnableGenerateTagsLoader,
    createPolicyMetadataState,
    shouldShowStateSelection,
    stateOptions,
  };
};
