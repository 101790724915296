import { PolicyContentData, usePolicyDetails, UsePolicyDetailsResult } from 'src/hooks';
import { ComparisonGroupType } from 'src/models';
import { Nullable } from 'src/types';

export interface UseDetailsPageProps {
  executionId: string;
  version: number;
}

export interface UseDetailsPageResult extends UsePolicyDetailsResult {
  groupBasedPoliciesMap: Nullable<Record<ComparisonGroupType, PolicyContentData[]>>;
}

export const useDetailsPage = ({ executionId, version }: UseDetailsPageProps): UseDetailsPageResult => {
  // TODO : Remove empty string once the executionId is made required.
  const policyDetailsResult = usePolicyDetails(executionId, version);
  // TODO : Add other relevant hooks such Policy MetaData to get the domain, country, etc around policy

  /**
   * Segregate the policy content data on the basis of ComparisonGroupType.
   *
   * @param data - PolicyContentData
   */
  const segregateByGroup = (data: PolicyContentData[]): Record<ComparisonGroupType, PolicyContentData[]> => {
    return data.reduce((groupBasedSegregation, policyContent) => {
      if (!groupBasedSegregation[policyContent.group]) {
        groupBasedSegregation[policyContent.group] = [];
      }
      groupBasedSegregation[policyContent.group].push(policyContent);

      return groupBasedSegregation;
    }, {} as Record<ComparisonGroupType, PolicyContentData[]>);
  };

  return {
    ...policyDetailsResult,
    groupBasedPoliciesMap:
      policyDetailsResult.responseState.policyContentData &&
      segregateByGroup(policyDetailsResult.responseState.policyContentData),
  };
};
