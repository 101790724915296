import React, { useContext, useEffect, useState } from 'react';
import { Col, Container } from '@amzn/stencil-react-components/layout';
import { CommonContext } from 'src/components/navbar/CommonContext';
import DashboardTabs from 'src/components/dashboard/models/DashboardTabs';
import { showWorkspaceMessageBanner, WorkspaceMessageBanner } from 'src/components/workspaces/WorkspacesUtils';
import { useScrollIntoView } from 'src/hooks/useScrollIntoView';
import DashboardTables from './DashboardTables';

const DashboardV2 = () => {
  const { workspaceContext } = useContext(CommonContext);
  const [availableTabs, setAvailableTabs] = useState<DashboardTabs[]>([]);
  const containerRef = useScrollIntoView();

  useEffect(() => {
    if (workspaceContext.selectedWorkspace.namespaceId !== '') {
      const availableTabs = [];
      if (workspaceContext.selectedWorkspace?.isRisksEnabled) {
        availableTabs.push(DashboardTabs.RISKS);
      }
      if (workspaceContext.selectedWorkspace?.isAssessmentsEnabled) {
        availableTabs.push(DashboardTabs.ASSESSMENTS);
      }
      if (workspaceContext.selectedWorkspace?.isReviewsEnabled) {
        availableTabs.push(DashboardTabs.REVIEWS);
      }
      setAvailableTabs(availableTabs);
    }
  }, [workspaceContext.selectedWorkspace]);

  return (
    <Container ref={containerRef}>
      {showWorkspaceMessageBanner(workspaceContext) ? (
        WorkspaceMessageBanner(workspaceContext)
      ) : (
        <Col>
          {workspaceContext.selectedWorkspace && availableTabs.length > 0 && (
            <DashboardTables
              namespaceId={workspaceContext.selectedWorkspace.namespaceId}
              availableTabs={availableTabs}
            />
          )}
        </Col>
      )}
    </Container>
  );
};

export default DashboardV2;
