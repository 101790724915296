import React, { ReactNode } from 'react';
import { CommonContextType } from 'src/components/navbar/constants';
import { useCommonContext } from 'src/components/navbar/hooks/useCommonContext';

export const CommonContext = React.createContext<CommonContextType>({} as CommonContextType);

export const CommonContextProvider = (props: { children?: ReactNode }) => {
  const { workspaceContext, setWorkspaceContext, permissionsContext } = useCommonContext();

  return (
    <CommonContext.Provider
      value={{
        workspaceContext,
        permissionsContext,
        setWorkspaceContext,
      }}
    >
      {props.children}
    </CommonContext.Provider>
  );
};
