import React from 'react';
import { Row } from '@amzn/stencil-react-components/layout';
import { isBefore, PopupDatePicker } from '@amzn/stencil-react-components/date-picker';
import { EditRiskState } from 'src/components/riskdetails/hooks';
import { convertDateTimeToFormat, DateTimeFormats, getDateBeforeNDays } from 'src/utils';
import { RiskStatus } from 'src/models';
import { InputFooter } from '@amzn/stencil-react-components/form';

interface RiskStatusProps {
  id: string;
  editRiskState: EditRiskState;
  setFixByDate: (status: string) => void;
}

const FixByDateElement = ({
  id,
  editRiskState: {
    isEditing,
    edits: { fixByDate, status },
    showErrors,
  },
  setFixByDate,
}: RiskStatusProps) => {
  const isEditable = !!status && ![RiskStatus.RESOLVED, RiskStatus.INVALIDATED].includes(status);
  const isDateDisabled = (date: string = '') => isBefore(getDateBeforeNDays(1).toISOString())(date);
  const isError = showErrors && (!fixByDate || isDateDisabled(fixByDate));
  return isEditable && isEditing ? (
    <>
      <PopupDatePicker
        id={id}
        aria-required={true}
        aria-labelledby={`${id}-label ${id}-toggle-button`}
        aria-describedby={`${id}-footer`}
        value={fixByDate}
        isDateDisabled={isDateDisabled}
        error={isError}
        onChange={(selectedDate) => {
          setFixByDate(selectedDate);
        }}
      />
      {isError && (
        <InputFooter id={`${id}-footer`} error>
          Please select an ETA
        </InputFooter>
      )}
    </>
  ) : (
    <Row id={id} aria-labelledby={`${id}-label`} alignItems="center">
      {convertDateTimeToFormat(fixByDate, DateTimeFormats.DATE_TIME_DD_MMM_YYYY)}
    </Row>
  );
};

export default FixByDateElement;
