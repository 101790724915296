import { HttpError, postData } from 'src/clients';
import { useMutation } from '@tanstack/react-query';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { CREATE_RISK_API } from 'src/paths';
import { CreateRiskRequest, CreateRiskResponse } from 'src/models';

export const useCreateRisk = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
  const [retryParams, setRetryParams] = useState<CreateRiskRequest>();
  const path = CREATE_RISK_API();
  const createRisk = (createRiskRequest: CreateRiskRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path,
        method: 'POST',
        body: createRiskRequest,
      },
      {
        onError: (err) => {
          setRetryParams({ ...createRiskRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    createRisk(retryParams);
  };

  return {
    createRiskState: {
      data: responseData as CreateRiskResponse,
      loading,
      error: retryParams ? error : null,
      retry,
    },
    createRisk,
  };
};
