import React, { useContext } from 'react';
import { H1 } from '@amzn/stencil-react-components/text';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { PolicyMetadata } from 'src/models';
import { NavigateFunction } from 'react-router-dom';
import { PolicyMetadataState } from 'src/hooks/usePolicyMarketplace';
import { metricConfig, REGISTER_POLICY } from 'src/constants';
import { Card } from '@amzn/stencil-react-components/card';
import { CommonContext } from 'src/components';
import { useTableFilter } from 'src/components/shared/table-filters';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';
import { getClickMetricDimensions } from 'src/utils';
import { useComponentLoadedLatencyMetric } from 'src/hooks';
import TableFilters from '../shared/table-filters/TableFilters';
import PolicyMarketplaceTable from './PolicyMarketplaceTable';

interface PolicyMarketplaceContentProps {
  selectedNamespaceId: string;
  getPoliciesMetadataState: PolicyMetadataState;
  policies: PolicyMetadata[];
  navigate: NavigateFunction;
}

const POLICY_MARKETPLACE_TABLE_FILTER_KEYS = [
  { keyName: 'Domains', keyPath: 'domains' },
  { keyName: 'Country', keyPath: 'policyMetadataAttributes.country' },
  { keyName: 'Source', keyPath: 'sourceType' },
];

const POLICY_LIST_ERROR_MESSAGE =
  'Unable to load policies in a namespace due to some error. Please try reloading the page.';
const POLICY_LIST_EMPTY_MESSAGE = 'No Policies found for the selected namespace.';

const PolicyMarketplaceContent: React.FC<PolicyMarketplaceContentProps> = ({
  selectedNamespaceId,
  getPoliciesMetadataState,
  policies,
  navigate,
}) => {
  const { permissions } = useContext(CommonContext).permissionsContext;

  const {
    filteredData: filteredPolicies,
    filterSelections,
    setFilterSelections,
    handleFilterChange,
    filterOptionsMap,
  } = useTableFilter({
    data: policies,
    filterKeys: POLICY_MARKETPLACE_TABLE_FILTER_KEYS,
  });

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.PolicyMarketplace.componentName,
    latencyAction: metricConfig.PolicyMarketplace.actions.POLICY_MARKETPLACE_LOAD_LATENCY,
    isComponentLoaded: !getPoliciesMetadataState.loading,
  });

  if (!selectedNamespaceId) {
    return null;
  }

  if (getPoliciesMetadataState.error) {
    return <MessageBanner type={MessageBannerType.Error}>{POLICY_LIST_ERROR_MESSAGE}</MessageBanner>;
  }

  if (getPoliciesMetadataState.loading) {
    return (
      <Row width={'100%'} justifyContent={'center'}>
        <Spinner size={SpinnerSize.Small} />
      </Row>
    );
  }

  if (!getPoliciesMetadataState.data || getPoliciesMetadataState.data.policiesMetadata.length === 0) {
    return <MessageBanner type={MessageBannerType.Warning}>{POLICY_LIST_EMPTY_MESSAGE}</MessageBanner>;
  }

  const handleRegisterPolicyClick = () => {
    logger.emitCountMetric({
      metricName: METRIC_NAME.CLICK,
      dimensions: getClickMetricDimensions(
        metricConfig.PolicyMarketplace.componentName,
        metricConfig.PolicyMarketplace.actions.REGISTER_POLICY_BUTTON_CLICK,
      ),
    });
    navigate(`${REGISTER_POLICY}`);
  };

  return (
    <Col width={'80%'}>
      <H1 margin={{ bottom: '2 rem', top: '2rem' }}> Policy Marketplace</H1>
      <Card width={'100%'} padding={'1.5rem'} isElevated={true}>
        <View width={'100%'}>
          <Col>
            <Row alignItems={'end'} justifyContent={'space-between'} margin={{ top: 'S300', bottom: 'S300' }}>
              <TableFilters
                filterOptionsMap={filterOptionsMap}
                filterSelections={filterSelections}
                setFilterSelections={setFilterSelections}
                handleFilterChange={handleFilterChange}
              />
              <Button
                variant={ButtonVariant.Secondary}
                onClick={handleRegisterPolicyClick}
                disabled={!permissions.edit}
              >
                Register Policy
              </Button>
            </Row>
          </Col>
          <Col width={'100%'}>
            <Spacer height={'2rem'} />
            <PolicyMarketplaceTable data={filteredPolicies} navigate={navigate} />
          </Col>
        </View>
      </Card>
    </Col>
  );
};

export default PolicyMarketplaceContent;
