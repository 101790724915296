import React, { useContext } from 'react';
import { Col, Container, Row } from '@amzn/stencil-react-components/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { FormWrapper } from '@amzn/stencil-react-components/form';
import { PolicyMetadataInputForm } from 'src/components/shared/policy-metadata-form';
import { CommonContext } from 'src/components/navbar/CommonContext';
import { useScrollIntoView } from 'src/hooks/useScrollIntoView';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';
import { getClickMetricDimensions } from 'src/utils';
import { metricConfig } from 'src/constants';

const RegisterPolicyPage: React.FC = () => {
  const { workspaceContext } = useContext(CommonContext);
  const containerRef = useScrollIntoView();

  // TODO: Extend in future
  const onSubmit = () => {
    logger.emitCountMetric({
      metricName: METRIC_NAME.CLICK,
      dimensions: getClickMetricDimensions(
        metricConfig.RegisterPolicyPage.componentName,
        metricConfig.RegisterPolicyPage.actions.REGISTER_POLICY_SUBMIT,
      ),
    });
  };

  return (
    <Container ref={containerRef}>
      {workspaceContext.isWorkspaceError ? (
        <MessageBanner type={MessageBannerType.Error}>{'Unable to load workspaces. Please try again.'}</MessageBanner>
      ) : workspaceContext.isWorkspaceLoading ? (
        <Row width={'100%'} justifyContent={'center'}>
          <Spinner size={SpinnerSize.Large} />
        </Row>
      ) : (
        <FormWrapper alignItems={'center'}>
          <Col width={'80%'} alignItems={'center'}>
            {workspaceContext.selectedWorkspace.namespaceId && (
              <PolicyMetadataInputForm
                onSubmit={onSubmit}
                namespaceId={workspaceContext.selectedWorkspace.namespaceId}
              />
            )}
          </Col>
        </FormWrapper>
      )}
    </Container>
  );
};

export default RegisterPolicyPage;
