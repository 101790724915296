import { CellComponentType, CellType, TableColumn } from '@amzn/stencil-react-components/table';
import {
  Country,
  PolicyName,
  RiskETA,
  RiskName,
  RiskPriority,
  RiskStatus,
  ViewDetails,
} from 'src/components/dashboard/shared/RisksTableComponents';
import ExtendedRisk from 'src/components/dashboard/models/ExtendedRisk';
import { DateText, getPhoneToolLink } from 'src/components/dashboard/shared';

export const riskTableColumns: TableColumn<string, CellComponentType>[] = [
  { header: 'Risk Name', cellComponent: RiskName },
  { header: 'Policy', cellComponent: PolicyName },
  { header: 'Status', sortId: 'status', cellComponent: RiskStatus },
  { header: 'Priority', sortId: 'priority', cellComponent: RiskPriority },
  { header: 'Country', sortId: 'country', cellComponent: Country },
  {
    header: 'Created By',
    sortId: 'createdBy',
    cellComponent: ({ data: { createdBy } }: { data: ExtendedRisk }) => getPhoneToolLink(createdBy),
  },
  { header: 'Creation Date', sortId: 'createdAt', cellComponent: DateText },
  { header: 'ETA', sortId: 'fixByDate', cellComponent: RiskETA },
];
