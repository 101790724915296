import { Cookies } from 'react-cookie';
import { jwtDecode } from 'jwt-decode';
import { CookieSetOptions } from 'universal-cookie';

const cookies = new Cookies();

const IDP_ID_TOKEN_KEY = 'idp-id-token';
const FEDERATE_ACCESS_TOKEN = 'federate-access-token';
const AWS_IDENTITY = 'aws-identity';
export const FEDERATE_ACCESS_TOKEN_ATTRIBUTE_KEY = 'custom:FED_ACCESS_TOKEN';

const getFederateIdpCookieStorageOptions = (expires: Date): CookieSetOptions => {
  return {
    domain: window.location.hostname,
    expires,
    path: '/',
    sameSite: 'strict',
    secure: true,
  };
};

/**
 * Decode id token and return expiry time
 * @param token - id token to be decoded
 * @returns expiry time
 * */
const decodeJwtToken = (token: string): { exp: number } => {
  const { exp }: { exp: number } = jwtDecode(token);
  return {
    exp,
  };
};

/**
 * A function to get the authorization token from the storage
 * @returns authorization token.
 */
export const getAuthorizationTokenFromStorage = () => cookies.get(IDP_ID_TOKEN_KEY);

/**
 * A function to store the authorization token in the storage
 * @param idToken - authorization token to be stored.
 */
export const storeAuthorizationTokenInStorage = (idToken: string | undefined) => {
  if (!idToken) {
    return;
  }
  const { exp } = decodeJwtToken(idToken);

  // Converting the seconds to milliseconds.
  const expires = new Date(exp * 1000);

  cookies.set(IDP_ID_TOKEN_KEY, idToken, getFederateIdpCookieStorageOptions(expires));
};

/**
 * A function to get the IAMCredentials from the storage
 * @returns IAMCredentials.
 */
export const getIAMCredentialsFromStorage = () => cookies.get(AWS_IDENTITY); // TODO: Add refresh flow before returning token

/**
 * A function to get the FederateAccessToken from the storage
 * @returns FederateAccessToken.
 */
export const getFederateAccessTokenFromStorage = () => cookies.get(FEDERATE_ACCESS_TOKEN); // TODO: Add refresh flow before returning token

/**
 * A function to store the aws credentials token in the storage
 * @param credentials - credentials to be stored.
 */
export const storeIAMCredentialsInStorage = (credentials: any | undefined) => {
  if (!credentials || !credentials.expiration || !credentials.sessionToken) {
    return;
  }

  cookies.set(AWS_IDENTITY, JSON.stringify(credentials), getFederateIdpCookieStorageOptions(credentials.expiration));
};

/**
 * A function to store the aws accessToken in the storage
 * @param accessToken - accessToken to be stored.
 */
export const storeFederateAccessTokenInStorage = (accessToken?: string) => {
  if (!accessToken) {
    return;
  }
  const { exp } = decodeJwtToken(accessToken);
  cookies.set(FEDERATE_ACCESS_TOKEN, accessToken, getFederateIdpCookieStorageOptions(new Date(exp * 1000)));
};
