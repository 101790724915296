import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CommonContextProvider, NavigationBarComponent } from 'src/components';
import { AppRoutes } from 'src/components/AppRoutes';
import { PostAuthenticationController } from './authentication';

export const App = () => {
  return (
    <>
      <Router basename="/">
        <PostAuthenticationController>
          <CommonContextProvider>
            <NavigationBarComponent viewOnly={false} />
            <AppRoutes />
          </CommonContextProvider>
        </PostAuthenticationController>
      </Router>
    </>
  );
};
