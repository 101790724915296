import { BinaryRatingData, BinaryRatingValue } from '@amzn/stencil-react-components/feedback';
import { useState } from 'react';
import { FeedbackUseCase } from 'src/models';
import { useSubmitFeedback } from 'src/hooks/useSubmitFeedback';

export const DEFAULT_POSITIVE_FEEDBACK_OPTIONS = ['Clear and accurate response', 'Could be more clear or detailed'];
export const DEFAULT_NEGATIVE_FEEDBACK_OPTIONS = [
  'Missing relevant information/data',
  'Contains inaccurate information/data',
  'Potentially harmful or biased content',
];

// TODO: Update the positive and negative options to be use-case specific
const USECASE_TO_NEGATIVE_OPTIONS: Record<FeedbackUseCase, Array<string>> = {
  [FeedbackUseCase.RISK_DETAILS]: DEFAULT_NEGATIVE_FEEDBACK_OPTIONS,
  [FeedbackUseCase.ASSESSMENT_DETAILS]: DEFAULT_NEGATIVE_FEEDBACK_OPTIONS,
  [FeedbackUseCase.POLICY_TAGS]: DEFAULT_NEGATIVE_FEEDBACK_OPTIONS,
};

const USECASE_TO_POSITIVE_OPTIONS: Record<FeedbackUseCase, Array<string>> = {
  [FeedbackUseCase.RISK_DETAILS]: DEFAULT_POSITIVE_FEEDBACK_OPTIONS,
  [FeedbackUseCase.ASSESSMENT_DETAILS]: DEFAULT_POSITIVE_FEEDBACK_OPTIONS,
  [FeedbackUseCase.POLICY_TAGS]: DEFAULT_POSITIVE_FEEDBACK_OPTIONS,
};

export interface UseFeedbackProps {
  feedbackUseCase: FeedbackUseCase;
  referenceId?: string;
}

export const useFeedback = ({ feedbackUseCase, referenceId }: UseFeedbackProps) => {
  const [binaryRating, setBinaryRating] = useState<BinaryRatingValue | undefined>(undefined);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [shouldShowFeedbackCard, setShouldShowFeedbackCard] = useState(false);

  const positiveFeedbackOptions = USECASE_TO_POSITIVE_OPTIONS[feedbackUseCase];
  const negativeFeedbackOptions = USECASE_TO_NEGATIVE_OPTIONS[feedbackUseCase];

  const { submitFeedback } = useSubmitFeedback();

  const handleRatingChange = (_: any, value: BinaryRatingValue) => {
    setBinaryRating(value);
    setHasErrors(false);
    setShouldShowFeedbackCard(true);
  };

  const mappedOptions = (ratingData: BinaryRatingData): string[] => {
    if (ratingData.selectedOptions !== undefined) {
      const selectedIndices = Array.isArray(ratingData.selectedOptions)
        ? ratingData.selectedOptions
        : [ratingData.selectedOptions];

      return selectedIndices
        .map((index) => {
          if (ratingData.selectedRating === BinaryRatingValue.Yes) {
            return positiveFeedbackOptions[index];
          } else {
            return negativeFeedbackOptions[index];
          }
        })
        .filter((option) => option !== undefined);
    }
    return [];
  };

  const buildFeedbackDataJson = (ratingData: BinaryRatingData): string => {
    if (ratingData.selectedOptions !== undefined) {
      const finalFeedbackData = {
        selectedRating: ratingData.selectedRating,
        selectedOptions: mappedOptions(ratingData),
        referenceId: referenceId,
        optionalData: ratingData.optionalData,
      };

      return JSON.stringify(finalFeedbackData, null, 2);
    }
    return JSON.stringify(ratingData, null, 2);
  };

  const handleSubmit = (ratingData: BinaryRatingData) => {
    if (feedbackSubmitted) {
      setShouldShowFeedbackCard(false);
      return;
    }
    if (typeof ratingData.selectedOptions === 'undefined' || JSON.stringify(ratingData.selectedOptions).length === 0) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
      setShouldShowFeedbackCard(false);
      setFeedbackSubmitted(true);
      submitFeedback({
        useCase: feedbackUseCase,
        feedback: buildFeedbackDataJson(ratingData),
      });
    }
  };

  const handleHideCard = () => {
    setBinaryRating(undefined);
    setHasErrors(false);
    setShouldShowFeedbackCard(false);
  };

  return {
    binaryRating,
    hasErrors,
    shouldShowFeedbackCard,
    positiveFeedbackOptions,
    negativeFeedbackOptions,
    handleRatingChange,
    handleSubmit,
    handleHideCard,
  };
};
