import { useEffect, useRef } from 'react';
import { HEADER_HEIGHT } from 'src/constants/CommonConstants';

export const useScrollIntoView = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const currentElement = elementRef.current;
    if (!currentElement) {
      return;
    }
    const handleFocus = (event: FocusEvent) => {
      const target = event.target as HTMLElement;
      const targetRect = target.getBoundingClientRect();
      // We are scrolling by an offset of header height which is a static value as defined in constants
      if (targetRect.top <= HEADER_HEIGHT) {
        window.scrollBy({
          top: -HEADER_HEIGHT,
        });
      }
    };
    currentElement.addEventListener('focus', handleFocus, true);

    return () => {
      currentElement.removeEventListener('focus', handleFocus, true);
    };
  }, [elementRef.current]);

  return elementRef;
};
