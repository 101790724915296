import React from 'react';
import { Card } from '@amzn/stencil-react-components/card';
import { FormWrapper } from '@amzn/stencil-react-components/form';
import { Col, Container, GridContainer, GridItem, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H3, Text } from '@amzn/stencil-react-components/text';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { ComparisonGroupType } from 'src/models';
import { ScreenReaderOnly } from '@amzn/stencil-react-components/screen-reader-only';
import { useComponentLoadedLatencyMetric } from 'src/hooks';
import { metricConfig } from 'src/constants';
import { useCreateExecutionWorkflowContext } from '../context';
import { PolicyTile } from '../shared';

const POLICY_LIST_ERROR_MESSAGE =
  'Unable to load polices in a namespace due to some error. Please try reloading the page.';
const POLICY_LIST_EMPTY_MESSAGE = 'No Policies found for the selected namespace.';
const INFORMATIONAL_MESSAGE =
  'Please select distinct policies for Group A and Group B. The same policy cannot be selected in both groups.';

export const SelectPoliciesStep: React.FC = () => {
  const { selectedNamespaceId, getPoliciesMetadataState, selectPoliciesValidationError, compareWithMyTimeCheckbox } =
    useCreateExecutionWorkflowContext();

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.CreateExecutionWorkflow.componentName,
    latencyAction: metricConfig.CreateExecutionWorkflow.actions.CREATE_EXECUTION_WORKFLOW_LOAD_LATENCY,
    isComponentLoaded: !getPoliciesMetadataState.loading,
  });

  const renderPoliciesContent = () => {
    if (!selectedNamespaceId) {
      return null;
    }

    if (getPoliciesMetadataState.loading) {
      return (
        <Row width={'100%'} justifyContent={'center'}>
          <Spinner size={SpinnerSize.Small} />
        </Row>
      );
    }

    if (getPoliciesMetadataState.error) {
      return <MessageBanner type={MessageBannerType.Error}>{POLICY_LIST_ERROR_MESSAGE}</MessageBanner>;
    }

    if (!getPoliciesMetadataState.data || getPoliciesMetadataState.data.policiesMetadata.length === 0) {
      return <MessageBanner type={MessageBannerType.Warning}>{POLICY_LIST_EMPTY_MESSAGE}</MessageBanner>;
    }

    return (
      <>
        {!selectPoliciesValidationError && (
          <MessageBanner aria-live={'polite'} type={MessageBannerType.Informational}>
            <Text id={'informational-instruction-message'}>{INFORMATIONAL_MESSAGE}</Text>
          </MessageBanner>
        )}
        <GridContainer>
          <GridItem lg={6} md={4} sm={2}>
            <Card width={'100%'}>
              <fieldset style={{ border: 'none', padding: 0, margin: 0 }}>
                <legend>
                  <H3 id="group-a-heading">
                    Group {ComparisonGroupType.A} (select at least one)
                    <ScreenReaderOnly>{INFORMATIONAL_MESSAGE}</ScreenReaderOnly>
                  </H3>
                </legend>
                <Col gridGap={'S400'} width={'100%'}>
                  <Col height={'35rem'} overflow={'scroll'}>
                    {getPoliciesMetadataState.data.policiesMetadata.map((policyMetadata) => (
                      <PolicyTile
                        group={ComparisonGroupType.A}
                        key={policyMetadata.policyId}
                        policyMetadata={policyMetadata}
                      />
                    ))}
                  </Col>
                </Col>
              </fieldset>
            </Card>
          </GridItem>
          <GridItem lg={6} md={4} sm={2}>
            <Card width={'100%'}>
              <fieldset style={{ border: 'none', padding: 0, margin: 0 }}>
                <legend>
                  <H3 id="group-b-heading">Group {ComparisonGroupType.B} (select at least one)</H3>
                  <ScreenReaderOnly>{INFORMATIONAL_MESSAGE}</ScreenReaderOnly>
                </legend>
                <Col gridGap={'S400'} width={'100%'}>
                  <Col height={'35rem'} overflow={'scroll'}>
                    {getPoliciesMetadataState.data.policiesMetadata.map((policyMetadata) => (
                      <PolicyTile
                        viewMode={compareWithMyTimeCheckbox}
                        group={ComparisonGroupType.B}
                        key={policyMetadata.policyId}
                        policyMetadata={policyMetadata}
                      />
                    ))}
                  </Col>
                </Col>
              </fieldset>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  return (
    <Container>
      <FormWrapper>
        {selectPoliciesValidationError && (
          <MessageBanner aria-live={'polite'} type={MessageBannerType.Error}>
            <Text id={'validation-error-message'}>{selectPoliciesValidationError}</Text>
          </MessageBanner>
        )}
        {renderPoliciesContent()}
      </FormWrapper>
    </Container>
  );
};
