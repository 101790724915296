import { Row } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import React from 'react';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { WorkspaceContext } from 'src/components/navbar/constants';

const NAMESPACE_ERROR_MESSAGE = 'Unable to load workspaces. Please try again.';
const NO_NAME_SPACES_MESSAGE = 'No workspaces available.';

export const showWorkspaceMessageBanner = (workspaceContext: WorkspaceContext) => {
  return (
    workspaceContext.isWorkspaceLoading ||
    workspaceContext.isWorkspaceError ||
    workspaceContext.selectedWorkspace.namespaceId === ''
  );
};

export const WorkspaceMessageBanner = (workspaceContext: WorkspaceContext) => {
  if (workspaceContext.isWorkspaceLoading) {
    return (
      <Row width={'100%'} justifyContent={'center'}>
        <Spinner size={SpinnerSize.Large} />
      </Row>
    );
  } else if (workspaceContext.isWorkspaceError) {
    return <MessageBanner type={MessageBannerType.Error}>{NAMESPACE_ERROR_MESSAGE}</MessageBanner>;
  } else {
    return <MessageBanner type={MessageBannerType.Warning}>{NO_NAME_SPACES_MESSAGE}</MessageBanner>;
  }
};
