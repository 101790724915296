import { useReducer } from 'react';
import { Risk, RiskPriority, RiskStatus } from 'src/models';

export interface StateEdits {
  title?: string;
  details?: string;
  status?: RiskStatus;
  priority?: RiskPriority;
  fixByDate?: string;
}

export interface EditRiskState {
  isEditing: boolean;
  edits: StateEdits;
  showErrors: boolean;
}

enum EditRiskAction {
  TOGGLE_EDITING,
  UPDATE_TITLE,
  UPDATE_DETAILS,
  UPDATE_STATUS,
  UPDATE_PRIORITY,
  UPDATE_FIX_BY_DATE,
  UPDATE_EDITS,
  UPDATE_SHOW_ERROR,
}

const reducer = (state: EditRiskState, action: { type: EditRiskAction; payload?: any }) => {
  switch (action.type) {
    case EditRiskAction.TOGGLE_EDITING:
      return {
        ...state,
        isEditing: !state.isEditing,
        edits: {
          title: action.payload.title,
          details: action.payload.details,
          status: action.payload.status,
          priority: action.payload.priority,
          fixByDate: action.payload.fixByDate,
        },
        showErrors: state.isEditing ? false : state.showErrors,
      };
    case EditRiskAction.UPDATE_TITLE:
      return { ...state, edits: { ...state.edits, title: action.payload } };
    case EditRiskAction.UPDATE_DETAILS:
      return { ...state, edits: { ...state.edits, details: action.payload } };
    case EditRiskAction.UPDATE_STATUS:
      return { ...state, edits: { ...state.edits, status: action.payload.toUpperCase() } };
    case EditRiskAction.UPDATE_PRIORITY:
      return { ...state, edits: { ...state.edits, priority: action.payload.toUpperCase() } };
    case EditRiskAction.UPDATE_FIX_BY_DATE:
      return { ...state, edits: { ...state.edits, fixByDate: action.payload } };
    case EditRiskAction.UPDATE_EDITS:
      return {
        ...state,
        edits: {
          ...state.edits,
          title: action.payload.title,
          details: action.payload.details,
          status: action.payload.status,
          priority: action.payload.priority,
          fixByDate: action.payload.fixByDate,
        } as StateEdits,
      };
    case EditRiskAction.UPDATE_SHOW_ERROR:
      return {
        ...state,
        showErrors: action.payload,
      };
    default:
      throw new Error();
  }
};

export const useEditRiskState = () => {
  const [state, dispatch] = useReducer(reducer, { isEditing: false, edits: {}, showErrors: false });

  const getRiskUpdates = (risk?: Risk): StateEdits => {
    if (!risk) return state.edits;
    return Object.keys(state.edits).reduce((acc, key) => {
      if (state.edits[key as keyof StateEdits] === risk[key as keyof Risk]) return acc;
      return { ...acc, [key]: state.edits[key as keyof StateEdits] };
    }, {});
  };

  const isRiskUpdated = (risk?: Risk) => {
    return Object.keys(getRiskUpdates(risk)).length !== 0;
  };

  return {
    editRiskState: state,
    toggleEditing: (payload: StateEdits = {}) => dispatch({ type: EditRiskAction.TOGGLE_EDITING, payload }),
    setTitle: (title: string) => dispatch({ type: EditRiskAction.UPDATE_TITLE, payload: title }),
    setDetails: (details: string) => dispatch({ type: EditRiskAction.UPDATE_DETAILS, payload: details }),
    setStatus: (status: RiskStatus) => dispatch({ type: EditRiskAction.UPDATE_STATUS, payload: status }),
    setPriority: (priority: RiskPriority) => dispatch({ type: EditRiskAction.UPDATE_PRIORITY, payload: priority }),
    setFixByDate: (fixByDate: string) => dispatch({ type: EditRiskAction.UPDATE_FIX_BY_DATE, payload: fixByDate }),
    updateStateEdits: (payload: StateEdits) => dispatch({ type: EditRiskAction.UPDATE_EDITS, payload }),
    updateShowError: (payload: boolean) => dispatch({ type: EditRiskAction.UPDATE_SHOW_ERROR, payload }),
    getRiskUpdates,
    isRiskUpdated,
  };
};
