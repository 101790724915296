import { useQuery } from '@tanstack/react-query';
import { postData } from 'src/clients';
import { useAuthentication } from 'src/components';
import { GetPoliciesMetadataRequest, GetPoliciesMetadataResponse } from 'src/models';
import { GET_POLICIES_METADATA_API } from 'src/paths';

export const usePoliciesMetadata = (request: Omit<GetPoliciesMetadataRequest, 'userId'>) => {
  const { authenticatedUser } = useAuthentication();
  return useQuery<GetPoliciesMetadataResponse>(
    ['get-policies-metadata', authenticatedUser?.userName, request.namespaceId],
    () =>
      postData()({
        path: GET_POLICIES_METADATA_API(),
        body: {
          ...request,
          userId: authenticatedUser?.userName,
        },
      }) as Promise<GetPoliciesMetadataResponse>,
    {
      enabled: !!authenticatedUser?.userName && !!request.namespaceId,
    },
  );
};
