import React, { useEffect, useState } from 'react';
import { ProgressTrackerStepProps, ProgressTrackerStepStatus } from '@amzn/stencil-react-components/progress-tracker';

export interface Step extends ProgressTrackerStepProps {
  labelText: string;
  status?: ProgressTrackerStepStatus;
  renderStepComponent?: () => React.ReactNode;
  checkIfNextClickDisabled?: () => boolean;
  stepOnNextClick?: () => void;
  stepOnBackClick?: () => void;
  showActions?: boolean;
}

export interface StepProgress {
  currentStep: number;
  completedSteps: boolean[];
  onNext: () => void;
  onBack: () => void;
  setCurrentStep: (step: number) => void;
  isNextDisabled: boolean;
  setIsNextDisabled: (isNextDisabled: boolean) => void;
  currentStepShowActions: boolean;
  setCompletedSteps: (completedSteps: boolean[]) => void;
}

const PENDING_STEP_STATUSES = [ProgressTrackerStepStatus.Current, ProgressTrackerStepStatus.Available, undefined];

export const useStepProgress = ({ steps }: { steps: Step[] }): StepProgress => {
  const firstIncompleteStep = () => {
    const stepIndex = steps.findIndex((step) => PENDING_STEP_STATUSES.includes(step?.status));
    return stepIndex !== -1 ? stepIndex : steps.length;
  };

  const [currentStep, setCurrentStep] = useState(firstIncompleteStep);
  const [isNextDisabled, setIsNextDisabled] = useState<boolean>(false); // Enables next by default
  const [completedSteps, setCompletedSteps] = useState<boolean[]>([
    ...steps.map((step) => step?.status === ProgressTrackerStepStatus.Complete),
  ]);

  useEffect(() => {
    setIsNextDisabled(steps[currentStep]?.checkIfNextClickDisabled?.() || false);
  });

  /**
   * Controls on click of next button.
   */
  const onNext = () => {
    if (steps[currentStep]?.stepOnNextClick) {
      steps[currentStep].stepOnNextClick?.();
    }
    setCompletedSteps((prevCompleted) => {
      const newCompleted = [...prevCompleted];
      if (steps[currentStep].status !== ProgressTrackerStepStatus.Disabled) {
        newCompleted[currentStep] = true;
      }
      return newCompleted;
    });
    setCurrentStep((prevStep) => prevStep + 1);
  };

  /**
   * Controls on click of back button.
   */
  const onBack = () => {
    if (steps[currentStep]?.stepOnBackClick) {
      steps[currentStep].stepOnBackClick?.();
    }
    setCurrentStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  const { showActions: currentStepShowActions = true } = steps[currentStep] || {};

  return {
    currentStep,
    currentStepShowActions: currentStepShowActions,
    completedSteps,
    onNext,
    onBack,
    setCurrentStep,
    isNextDisabled,
    setIsNextDisabled,
    setCompletedSteps,
  };
};
