export const HOME_PAGE_ROUTE = '/dashboard';
export const REVIEW_PAGE = '/review-page/:executionId/version/:version';
export const DASHBOARD = '/dashboard';
export const REVIEW_SUBMISSION_WORKFLOW_PATH = '/policy-review-submission';
export const POLICY_DETAILS = '/policy-details';
export const POLICY_NAMESPACE_POLICY_ID_SUFFIX = '/:namespaceId/:policyId';
export const EXECUTION_DETAILS = '/execution-details';
export const POLICY_MARKETPLACE = '/policy-marketplace';
export const POLICY_REVIEW_WORKFLOW_PATH = '/policy-review';
export const CREATE_EXECUTION = '/create-execution';
export const REVIEW_PAGE_WORKFLOW_BASE_PATH = '/review-page';
export const EXECUTION_ID_VERSION_SUFFIX = '/:executionId/version/:version';
export const REGISTER_POLICY = '/register-policy';
export const RISK_DETAILS = '/risk-details';
export const RISK_ID_SUFFIX = '/:riskId';
export const CREATE_RISK = '/create-risk';
export const ERROR_PAGE = '/error-page';
