import { usePoliciesMetadata } from 'src/hooks';
import { Namespace, PolicyMetadata } from 'src/models';

export interface PolicyMetadataState {
  loading: boolean;
  error: string | null;
  data?: { policiesMetadata: PolicyMetadata[] };
}

export const usePolicyMarketplace = (selectedWorkspace: Namespace) => {
  const {
    data: policiesMetadata,
    isLoading: isPoliciesMetadataLoading,
    error: policiesMetadataError,
  } = usePoliciesMetadata({ namespaceId: selectedWorkspace.namespaceId });

  const policyMetadataState: PolicyMetadataState = {
    loading: isPoliciesMetadataLoading,
    error: policiesMetadataError ? 'An error occurred while fetching policies' : null,
    data: policiesMetadata,
  };

  return {
    getPoliciesMetadataState: policyMetadataState,
  };
};
