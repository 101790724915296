import { SubmitFeedbackRequest, SubmitFeedbackResponse } from 'src/models';
import { useMutation } from '@tanstack/react-query';
import { HttpError, postData } from 'src/clients';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { SUBMIT_FEEDBACK_API } from 'src/paths';

export const useSubmitFeedback = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
    isSuccess,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
  const [retryParams, setRetryParams] = useState<SubmitFeedbackRequest>();
  const submitFeedback = (submitFeedbackRequest: SubmitFeedbackRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path: SUBMIT_FEEDBACK_API,
        method: 'POST',
        body: submitFeedbackRequest,
      },
      {
        onError: () => {
          setRetryParams({ ...submitFeedbackRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    submitFeedback(retryParams);
  };

  return {
    submitFeedbackState: {
      data: responseData as SubmitFeedbackResponse,
      loading,
      error: retryParams ? error : null,
      retry,
      isSuccess,
    },
    submitFeedback,
  };
};
