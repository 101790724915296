import { ExecutionType } from 'src/models';
import { Policy, UserInput } from './common';

export interface PolicyComparisonData {
  result?: string;
  risks?: ModelGeneratedRisk[];
}

export interface ModelGeneratedRisk {
  riskId?: string;
  title?: string;
  action?: ModelGeneratedAction;
  details?: string;
  priority?: string;
}

export enum ModelGeneratedAction {
  RESOLVE = 'RESOLVE',
  CREATE = 'CREATE',
}

export const actionDisplayMap: Record<ModelGeneratedAction, string> = {
  [ModelGeneratedAction.RESOLVE]: 'RESOLVED',
  [ModelGeneratedAction.CREATE]: 'CREATED',
};

export interface PolicyExecutionResponse {
  executionId: string;
  executionType: ExecutionType;
  version: number;
  requesterAlias: string;
  executionStatus: string;
  namespaceId: string;
  namespaceVersion: number;
  reviewId: string;
  createdAt: Date;
  updatedAt: Date;
  policies: Policy[];
  userInput: UserInput;
  policyComparisonData: PolicyComparisonData;
}
