import React from 'react';
import { Table, TableColumn, CellComponentType } from '@amzn/stencil-react-components/table';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { Col, Row, Spacer, View } from '@amzn/stencil-react-components/layout';
import { H2, Text } from '@amzn/stencil-react-components/text';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { useTableSortAndPagination } from 'src/hooks/useTablePagination/useTableSortAndPagination';
import { actionDisplayMap, FeedbackUseCase, ModelGeneratedRisk } from 'src/models';
import { RISK_DETAILS } from 'src/constants';
import { NavigateFunction } from 'react-router-dom';
import { Card } from '@amzn/stencil-react-components/card';
import { createCardRenderer } from 'src/utils/tableUtils';
import { RouterLink } from 'src/components/shared/router-link';
import GEN_AI_LOGO from 'src/assets/GEN_AI_LOGO.svg';
import { Feedback } from 'src/components/feedback';

const PAGE_SIZE = 5;
const NO_RISKS_WARNING_MESSAGE = 'No risks found for this execution.';

interface ExecutionRisksTableProps {
  navigate: NavigateFunction;
  riskComparisonData: ModelGeneratedRisk[];
  executionId?: string;
}

const ExecutionRisksTable: React.FC<ExecutionRisksTableProps> = ({ riskComparisonData, executionId }) => {
  const RiskName = ({ data: { title, riskId } }: { data: ModelGeneratedRisk }) => (
    <RouterLink to={`${RISK_DETAILS}/${riskId}`}>{title}</RouterLink>
  );

  const RiskStatus = ({ data: { action } }: { data: ModelGeneratedRisk }) => (
    <Text>{action ? actionDisplayMap[action] : '-'}</Text>
  );

  const RiskDetail = ({ data: { details } }: { data: ModelGeneratedRisk }) => <Text>{details}</Text>;

  const columns: TableColumn<string, CellComponentType>[] = [
    { header: 'Risk Name', cellComponent: RiskName, width: '20%', textAlign: 'left' },
    { header: 'Risk Detail', cellComponent: RiskDetail, width: '70%', textAlign: 'justify' },
    { header: 'Action', sortId: 'action', cellComponent: RiskStatus, width: '10%', textAlign: 'left' },
  ];

  const {
    visibleData: visibleRisks,
    sortState,
    paginationState,
  } = useTableSortAndPagination({
    data: riskComparisonData,
    pageSize: PAGE_SIZE,
  });

  if (visibleRisks.length === 0) {
    return <MessageBanner type={MessageBannerType.Warning}>{NO_RISKS_WARNING_MESSAGE}</MessageBanner>;
  }

  return (
    <View>
      <Col width={'100%'}>
        <Col>
          <Row alignItems="center" gridGap="S100">
            <View width="1.5rem" height="1.5rem" justifyContent="center" alignItems="center">
              <img src={GEN_AI_LOGO} alt="GEN AI Logo" />
            </View>
            <H2>Execution Results</H2>
            <Feedback feedbackUseCase={FeedbackUseCase.ASSESSMENT_DETAILS} referenceId={executionId} />
          </Row>
          <Text margin={{ left: 'S100' }} fontStyle="italic" fontSize="T100" fontWeight="light">
            *This feature is powered by Generative AI and may not always be accurate.
          </Text>
        </Col>
        <Spacer height={'S300'} />
        <Card width={'100%'} isElevated={true}>
          <Col padding={'1rem'}>
            <Table
              aria-labelledby="execution-risks-table"
              maxHeight={'90vh'}
              columns={columns}
              data={visibleRisks}
              hasStickyHeader={true}
              renderCardForRow={createCardRenderer(columns)}
              {...sortState}
            />
            <Spacer height={'S300'} />
            <Col>
              <Pagination aria-labelledby="execution-risks-table-pagination" {...paginationState} />
            </Col>
          </Col>
        </Card>
      </Col>
    </View>
  );
};

export default ExecutionRisksTable;
