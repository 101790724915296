import React, { useState } from 'react';
import { IconMenu, IconSize } from '@amzn/stencil-react-components/icons';
import { Flyout, FlyoutPosition } from '@amzn/stencil-react-components/flyout';
import { SideNav, SideNavItem, SideNavSection } from '@amzn/stencil-react-components/side-nav';
import { ButtonColorScheme, ButtonVariant } from '@amzn/stencil-react-components/button';
import { PageHeaderButton } from '@amzn/stencil-react-components/page';
import { DASHBOARD, POLICY_MARKETPLACE } from 'src/constants';
import { useNavigate } from 'react-router-dom';

const SideNavigation = () => {
  const navigate = useNavigate();
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleNav = () => {
    setMenuOpen(!isMenuOpen);
  };

  const navigateFromSideNav = (navigationLink: string) => {
    toggleNav();
    navigate(navigationLink);
  };

  return (
    <>
      <PageHeaderButton
        color="neutral0"
        variant={ButtonVariant.Tertiary}
        colorScheme={ButtonColorScheme.DarkBackground}
        aria-label="Navigation Menu"
        onClick={toggleNav}
        icon={<IconMenu size={IconSize.Large} color="neutral0" />}
      />
      <Flyout isOpen={isMenuOpen} close={toggleNav} position={FlyoutPosition.Leading}>
        <SideNav>
          <SideNavSection label="Policy Advisor">
            <SideNavItem
              tabIndex={0}
              role="button"
              onClick={() => {
                navigateFromSideNav(POLICY_MARKETPLACE);
              }}
            >
              Policy Marketplace
            </SideNavItem>
            <SideNavItem
              tabIndex={1}
              role="button"
              onClick={() => {
                navigateFromSideNav(DASHBOARD);
              }}
            >
              Dashboard
            </SideNavItem>
          </SideNavSection>
        </SideNav>
      </Flyout>
    </>
  );
};

export default SideNavigation;
