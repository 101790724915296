import { useMutation } from '@tanstack/react-query';
import { HttpError, postData } from 'src/clients';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { UPDATE_RISKS_API } from 'src/paths';
import { UpdateRiskRequest, UpdateRiskResponse } from 'src/models/api-models/updateRisk';

interface UseUpdateRiskProps {
  onSuccess?: () => void;
}

export const useUpdateRisk = ({ onSuccess }: UseUpdateRiskProps) => {
  const {
    isLoading,
    error,
    isError,
    isSuccess,
    data: responseData,
    mutate,
  } = useMutation<unknown, HttpError, PostApiParams<UpdateRiskRequest>>(postData());
  const [retryParams, setRetryParams] = useState<UpdateRiskRequest | undefined>(undefined);

  const updateRisk = (updateRiskRequest: UpdateRiskRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path: UPDATE_RISKS_API,
        method: 'POST',
        body: updateRiskRequest,
      },
      {
        onError: (_error) => {
          setRetryParams({ ...updateRiskRequest });
        },
        onSuccess,
      },
    );
  };

  const retry = () => {
    if (!retryParams || isLoading) return;
    updateRisk(retryParams);
  };

  return {
    updateRiskState: {
      data: responseData as UpdateRiskResponse,
      isLoading,
      error: retryParams ? error : null,
      isError,
      isSuccess,
      retry,
    },
    updateRisk: updateRisk,
  };
};
