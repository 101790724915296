import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { postData } from 'src/clients';
import { GetRisksRequest, GetRisksResponse } from 'src/models/api-models/getRisks';
import { GET_RISKS_API } from 'src/paths';

export const useGetRisks = (request: GetRisksRequest): UseQueryResult<GetRisksResponse> => {
  return useQuery<GetRisksResponse>(
    ['get-risks', request.namespaceId, request.executionId, request.policyId, request.status],
    () =>
      postData()({
        path: GET_RISKS_API,
        body: { ...request },
      }) as Promise<GetRisksResponse>,
    {
      enabled: !!request.namespaceId || !!request.executionId || !!request.policyId || !!request.status,
    },
  );
};
