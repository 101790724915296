import React from 'react';
import { Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Link } from '@amzn/stencil-react-components/link';
import { IconExternalLink } from '@amzn/stencil-react-components/icons';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Status, StatusIndicator } from '@amzn/stencil-react-components/status-indicator';
import { PolicyMetadata } from 'src/models';
import { usePolicyLinks } from 'src/components/policy-details-page/hooks/usePolicyLinks';

const LINKS_ERROR_MESSAGE = 'Unable to fetch some of the links.';
const EMPTY_SEPARATOR = '-';

interface PolicyLinksProps {
  policyMetadata: PolicyMetadata;
}

const PolicyLinks: React.FC<PolicyLinksProps> = ({ policyMetadata }) => {
  const { externalLinks, s3Links, isError, isLoading } = usePolicyLinks(policyMetadata);

  if (isLoading) {
    return <Spinner size={SpinnerSize.Small} />;
  }

  const hasLinks = externalLinks.length > 0 || s3Links.length > 0;

  return (
    <>
      {hasLinks ? (
        <Row flexWrap="wrap">
          {externalLinks.map((link, index) => (
            <Link
              key={link}
              href={link}
              target="_blank"
              icon={<IconExternalLink title="Opens in a new window" />}
              margin={{ right: 'S300', bottom: 'S100' }}
            >
              Policy Link {index + 1}
            </Link>
          ))}
          {s3Links.map((link, index) => (
            <Link
              download="Policy Link"
              key={link}
              href={link}
              target="_blank"
              icon={<IconExternalLink title="Opens in a new window" />}
              margin={{ right: 'S300', bottom: 'S100' }}
            >
              Policy Link {externalLinks.length + index + 1}
            </Link>
          ))}
        </Row>
      ) : (
        <Text fontSize={'T300'}>{EMPTY_SEPARATOR}</Text>
      )}
      {isError && <StatusIndicator messageText={LINKS_ERROR_MESSAGE} status={Status.Warning} />}
    </>
  );
};

export default PolicyLinks;
