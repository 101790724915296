import { TabBar, TabPanel, TabSwitcher, useTabs } from '@amzn/stencil-react-components/tabs';
import React from 'react';
import { Row } from '@amzn/stencil-react-components/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import DashboardTabs from 'src/components/dashboard/models/DashboardTabs';
import RisksTable from 'src/components/dashboard/RisksTable';
import PolicyExecutionTable from 'src/components/dashboard/PolicyExecutionTable';
import { PolicyAdvisorErrorBoundary } from 'src/components/error-boundary/PolicyAdvisorErrorBoundary';
import { ErrorBoundaryComponent } from 'src/constants/ErrorBoundaryConstants';
import { H1 } from '@amzn/stencil-react-components/text';

const NO_FEATURE_ERROR_MESSAGE = 'No feature is enabled on this workspace.';

interface DashboardTablesProps {
  namespaceId: string;
  availableTabs: DashboardTabs[];
}

const DashboardTables = ({ namespaceId, availableTabs }: DashboardTablesProps) => {
  const { tabBarProps, tabSwitcherProps } = useTabs({ tabs: availableTabs });

  if (availableTabs.length === 0) {
    return <MessageBanner type={MessageBannerType.Error}>{NO_FEATURE_ERROR_MESSAGE}</MessageBanner>;
  }

  const selectedTab = availableTabs.includes(tabBarProps.selectedTab as DashboardTabs)
    ? tabBarProps.selectedTab
    : availableTabs[0];

  return (
    <>
      <H1 margin={{ top: 'S500' }}>Dashboard</H1>
      <Row margin={{ bottom: 'S300' }}>
        <TabBar {...tabBarProps} selectedTab={selectedTab} />
      </Row>
      <TabSwitcher {...tabSwitcherProps} selectedTab={selectedTab}>
        <TabPanel value={DashboardTabs.RISKS}>
          <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_RISKS_TABLE}>
            <RisksTable namespaceId={namespaceId} />
          </PolicyAdvisorErrorBoundary>
        </TabPanel>
        <TabPanel value={DashboardTabs.ASSESSMENTS}>
          <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_ASSESSMENTS_TABLE}>
            <PolicyExecutionTable namespaceId={namespaceId} />
          </PolicyAdvisorErrorBoundary>
        </TabPanel>
      </TabSwitcher>
    </>
  );
};

export default DashboardTables;
