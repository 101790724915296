import React, { useContext } from 'react';
import { Table } from '@amzn/stencil-react-components/table';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { useNavigate } from 'react-router-dom';
import Spacer from '@amzn/stencil-react-components/dist/submodules/layout/Spacer';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { useRisksTable } from 'src/components/dashboard/hooks/useRisksTable/useRisksTable';
import { metricConfig, CREATE_RISK } from 'src/constants';
import { RiskTableCards } from 'src/components/dashboard/models/RiskTableCards';
import DashboardCard from 'src/components/dashboard/DashboardCards';
import { useTableSortAndPagination } from 'src/hooks/useTablePagination/useTableSortAndPagination';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { riskTableColumns } from 'src/components/dashboard/constants/RiskTableConstants';
import { TableFilters } from 'src/components/shared/table-filters';
import { CommonContext } from 'src/components';
import { createCardRenderer } from 'src/utils/tableUtils';
import { H2, Text } from '@amzn/stencil-react-components/text';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';
import { getClickMetricDimensions } from 'src/utils';
import { useComponentLoadedLatencyMetric } from 'src/hooks/useComponentLoadedLatencyMetric/useComponentLoadedLatencyMetric';

const PAGE_SIZE = 10;
const NO_RISKS_WARNING_MESSAGE = 'No risks found for the selected filters in this workspace.';
const GET_RISKS_ERROR_MESSAGE =
  'Unable to load risks in the namespace due to some error. Please try reloading the page.';
const NEEDS_ATTENTION = 'Needs attention';
const HIGH_PRIORITY_RISK_DESCRIPTION = 'Critical risks requiring immediate action.';
const UNACKNOWLEDGED_RISK_DESCRIPTION = 'Risks that are not acknowledged with an ETA & Priority.';
const OVER_SLA_RISK_DESCRIPTION = 'Risks that are over the defined ETA.';

export interface RisksTableProp {
  namespaceId: string;
}

const RisksTable = ({ namespaceId }: RisksTableProp) => {
  const navigate = useNavigate();
  const { permissions } = useContext(CommonContext).permissionsContext;
  const {
    risks,
    isLoading,
    isError,
    counts,
    activeCard,
    setActiveCard,
    filterSelections,
    setFilterSelections,
    handleFilterChange,
    filterOptionsMap,
  } = useRisksTable(namespaceId);
  const {
    visibleData: visibleRisks,
    sortState,
    paginationState,
  } = useTableSortAndPagination({
    data: risks,
    pageSize: PAGE_SIZE,
    defaultActiveSortId: 'createdAt',
  });

  const cardData: { type: RiskTableCards; description?: string; chip?: string; count: number }[] = [
    {
      type: RiskTableCards.HIGH_PRIORIY_RISKS,
      description: HIGH_PRIORITY_RISK_DESCRIPTION,
      count: counts.highPriorityRisks,
    },
    {
      type: RiskTableCards.UNACKNOWLEDGED_RISKS,
      description: UNACKNOWLEDGED_RISK_DESCRIPTION,
      chip: NEEDS_ATTENTION,
      count: counts.unacknowledgedRisks,
    },
    {
      type: RiskTableCards.OVER_SLA_RISKS,
      description: OVER_SLA_RISK_DESCRIPTION,
      chip: NEEDS_ATTENTION,
      count: counts.overSLARisks,
    },
  ];

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.RisksTable.componentName,
    latencyAction: metricConfig.RisksTable.actions.DASHBOARD_RISKS_LOAD_LATENCY,
    isComponentLoaded: !isLoading,
  });

  const handleAddRiskOnClick = () => {
    logger.emitCountMetric({
      metricName: METRIC_NAME.CLICK,
      dimensions: getClickMetricDimensions(
        metricConfig.RisksTable.componentName,
        metricConfig.RisksTable.actions.ADD_RISK_BUTTON_CLICK,
      ),
    });
    navigate(CREATE_RISK);
  };

  if (isError) {
    return <MessageBanner type={MessageBannerType.Error}>{GET_RISKS_ERROR_MESSAGE}</MessageBanner>;
  }

  if (isLoading) {
    return (
      <Row width={'100%'} justifyContent={'center'}>
        <Spinner size={SpinnerSize.Small} />
      </Row>
    );
  }

  return (
    <Col padding={'0.5rem'} gridGap={'S300'}>
      <H2 id={'risks-summary-label'}>Risks Summary</H2>
      <Row gridGap={20} role={'group'} aria-labelledby={'risks-summary-label'}>
        {cardData.map((card) => (
          <DashboardCard
            key={card.type}
            title={card.type}
            description={card.description}
            chip={card.chip}
            count={card.count}
            isActive={activeCard === card.type}
            onClick={() => setActiveCard(card.type)}
          />
        ))}
      </Row>
      <Row justifyContent="right" margin={{ top: 'S300', bottom: 'S300' }} alignItems={'end'}>
        <TableFilters
          filterOptionsMap={filterOptionsMap}
          filterSelections={filterSelections}
          setFilterSelections={setFilterSelections}
          handleFilterChange={handleFilterChange}
        />
        <Button disabled={!permissions.edit} variant={ButtonVariant.Primary} onClick={handleAddRiskOnClick}>
          Add Risk
        </Button>
      </Row>
      {visibleRisks.length === 0 ? (
        <MessageBanner type={MessageBannerType.Warning}>{NO_RISKS_WARNING_MESSAGE}</MessageBanner>
      ) : (
        <Col display="flex">
          <Table
            aria-labelledby="policy-risks-table"
            maxHeight={'90vh'}
            columns={riskTableColumns}
            data={visibleRisks}
            hasStickyHeader={true}
            renderCardForRow={createCardRenderer(riskTableColumns)}
            {...sortState}
          />
          <Spacer height={'S300'} />
          <Row justifyContent="space-between">
            <Pagination aria-labelledby="policy-risks-table-pagination" {...paginationState} />
            <Text fontStyle="italic" fontSize="T100" fontWeight="light">
              *System generated risks are powered by Generative AI and may not always be accurate.
            </Text>
          </Row>
        </Col>
      )}
    </Col>
  );
};

export default RisksTable;
