import React from 'react';
import {
  BinaryRating,
  BinaryRatingFeedbackLayout,
  BinaryRatingValue,
  BinaryRatingVariant,
} from '@amzn/stencil-react-components/feedback';
import { useFeedback } from 'src/components/feedback/hooks/useFeedback';
import { Col } from '@amzn/stencil-react-components/layout';
import {
  FEEDBACK_MODAL_DISCLAIMER_TEXT,
  FEEDBACK_MODAL_TITLE,
  FEEDBACK_TITLE_TEXT,
  OPTIONAL_TEXT_FOOTER,
  OPTIONAL_TEXT_LABEL,
  OPTIONAL_TEXT_TITLE,
} from 'src/constants/FeedbackConstants';
import { FeedbackUseCase } from 'src/models';

export interface FeedbackProps {
  feedbackUseCase: FeedbackUseCase;
  referenceId?: string;
}

const Feedback = ({ feedbackUseCase, referenceId }: FeedbackProps) => {
  const {
    hasErrors,
    binaryRating,
    shouldShowFeedbackCard,
    positiveFeedbackOptions,
    negativeFeedbackOptions,
    handleRatingChange,
    handleHideCard,
    handleSubmit,
  } = useFeedback({ feedbackUseCase, referenceId });

  return (
    <Col width={'100%'} gridGap={'S300'} alignItems={'end'}>
      <BinaryRating
        variant={BinaryRatingVariant.Inline}
        feedbackLayout={BinaryRatingFeedbackLayout.Modal}
        feedbackModalProps={{
          title: FEEDBACK_MODAL_TITLE,
          disclaimerText: FEEDBACK_MODAL_DISCLAIMER_TEXT,
          onClose: handleHideCard,
        }}
        feedbackCardProps={{
          hasErrors,
          hasOptionalTextArea: true,
          optionalContentLabel: OPTIONAL_TEXT_LABEL,
          optionalContentFooter: OPTIONAL_TEXT_FOOTER,
          title: OPTIONAL_TEXT_TITLE,
          options: binaryRating === BinaryRatingValue.Yes ? positiveFeedbackOptions : negativeFeedbackOptions,
        }}
        onCancelClick={handleHideCard}
        onLaterClick={handleHideCard}
        onRatingChange={handleRatingChange}
        onSubmit={handleSubmit}
        shouldShowFeedbackCard={shouldShowFeedbackCard}
        titleText={FEEDBACK_TITLE_TEXT}
      />
    </Col>
  );
};

export default Feedback;
