import { PolicyMetadata, SourceType, TrackingSources } from 'src/models';
import { FileOperationType, GetPreSignedUrlResponse, useGetPreSignedUrl } from 'src/hooks';
import { SERVICE } from 'src/paths';
import { environmentConfiguration } from 'src/components/authentication/AmplifyConfig';
import { INSIDE_AMAZON_DOMAIN_NAME, INSIDE_AMAZON_HTML_POLICY_SUFFIX } from 'src/constants/CommonConstants';

interface PolicyLinksResponse {
  externalLinks: string[];
  s3Links: string[];
  isLoading: boolean;
  isError: boolean;
}

const S3_POLICY_BUCKET_NAME = `policy-data-store-${environmentConfiguration.stage}-us-west-2`;

const FILE_SOURCE_TYPES = [SourceType.FILE];
const LINK_SOURCE_TYPES = [SourceType.GOVT, SourceType.LINK, SourceType.INSIDE];

const getLinkFromTrackingSource = (trackingSource: TrackingSources): string => {
  if (trackingSource.sourceType === SourceType.INSIDE) {
    return `${INSIDE_AMAZON_DOMAIN_NAME}${trackingSource.referenceId}${INSIDE_AMAZON_HTML_POLICY_SUFFIX}`;
  }
  return trackingSource.referenceId;
};

export const usePolicyLinks = ({ trackingSources, policyId, versionNumber }: PolicyMetadata): PolicyLinksResponse => {
  const s3FilePaths: string[] = [];
  const otherLinks: string[] = [];
  let isError = false;
  trackingSources.forEach((trackingSource) => {
    if (FILE_SOURCE_TYPES.includes(trackingSource.sourceType)) {
      s3FilePaths.push(
        `s3://${S3_POLICY_BUCKET_NAME}/${policyId}/${versionNumber}/${trackingSource.referenceId.replace(/[./]/g, '')}`,
      );
    } else if (LINK_SOURCE_TYPES.includes(trackingSource.sourceType)) {
      otherLinks.push(getLinkFromTrackingSource(trackingSource));
    } else {
      isError = true;
    }
  });

  const preSignedUrlQueries = useGetPreSignedUrl(
    s3FilePaths.map((s3FilePath) => ({
      fileOperationType: FileOperationType.DOWNLOAD,
      service: SERVICE.PES,
      s3FilePath,
    })),
  );

  const preSignedUrls = preSignedUrlQueries
    .filter((preSignedUrlQuery) => preSignedUrlQuery.isSuccess)
    .map((preSignedUrlQuery) => (preSignedUrlQuery.data as GetPreSignedUrlResponse).preSignedUrl);

  const hasErrorsInPreSignedUrls = preSignedUrlQueries.some((preSignedUrlQuery) => preSignedUrlQuery.isError);
  const hasLoadingInPreSignedUrls = preSignedUrlQueries.some((preSignedUrlQuery) => preSignedUrlQuery.isLoading);

  return {
    externalLinks: otherLinks,
    s3Links: preSignedUrls,
    isLoading: hasLoadingInPreSignedUrls,
    isError: isError || hasErrorsInPreSignedUrls,
  };
};
