import { stringify } from 'query-string';
import { Nullable } from 'src/types';
import { FileOperationType } from 'src/hooks/useGetPreSignedUrl/useGetPreSignedUrl';

interface QueryParameter {
  [key: string]: Nullable<string | number>;
}

export const parseQueryParameters = (params: QueryParameter) => {
  const parsedParams = stringify(params, { skipNull: true });
  return parsedParams ? `?${parsedParams}` : '';
};

export const PES_API_PREFIX = '/apa-pes';
export const PMS_API_PREFIX = '/apa-pms';

export enum SERVICE {
  PES,
  PMS,
}

/**
 * API paths for the backend APIs from various microservices under AmazonPolicyAdvisor.
 *
 */

export const CREATE_REVIEW_API = () => `${PES_API_PREFIX}/create-review`;

export const GET_REVIEWS_API = (
  actorRole: string,
  approverReviewAssignmentStatus?: Array<string>,
  reviewStatus?: Array<string>,
) => {
  const queryParams = parseQueryParameters({
    actorRole,
    approverReviewAssignmentStatus: approverReviewAssignmentStatus?.join(','),
    reviewStatus: reviewStatus?.join(','),
  });
  return `${PES_API_PREFIX}/get-reviews${queryParams}`;
};

export const GET_REVIEW_API = (executionId: string, version: number): string => {
  const queryParams = parseQueryParameters({ executionId, executionVersion: `${version}` });
  return `${PES_API_PREFIX}/get-review${queryParams}`;
};

export const GET_RISK_API = (riskId: string): string => {
  const queryParams = parseQueryParameters({ riskId });
  return `${PES_API_PREFIX}/get-risk${queryParams}`;
};

export const GET_PERMISSIONS_API = (namespaceId?: string): string => {
  const queryParams = parseQueryParameters({ namespaceId });
  return `${PMS_API_PREFIX}/get-permissions${queryParams}`;
};

export const GET_EXECUTIONS_API = (namespaceId: string, executionStatus?: string): string => {
  const queryParams = parseQueryParameters({ executionStatus, namespaceId });
  return `${PES_API_PREFIX}/get-executions${queryParams}`;
};

export const GET_EXECUTION_API = (executionId?: string, version?: number): string => {
  const queryParams = parseQueryParameters({ executionId, version });
  return `${PES_API_PREFIX}/get-execution${queryParams}`;
};

export const PERFORM_APPROVER_ACTION_API = () => `${PES_API_PREFIX}/perform-approver-action`;

export interface GET_PRESIGNED_URL_REQUEST_PARAMS {
  fileOperationType: FileOperationType;
  service: SERVICE;
  s3FilePath?: string;
  fileName?: string;
  namespaceId?: string;
}

export const GET_PRE_SIGNED_URL = (getPresignedURLRequestParams: GET_PRESIGNED_URL_REQUEST_PARAMS) => {
  const queryParams = parseQueryParameters({ ...getPresignedURLRequestParams });
  return `${
    getPresignedURLRequestParams.service == SERVICE.PES ? PES_API_PREFIX : PMS_API_PREFIX
  }/get-presigned-url${queryParams}`;
};

export const EXECUTE_COMPARISON_API = `${PES_API_PREFIX}/execute-comparison`;

export const GET_POLICIES_METADATA_API = () => `${PMS_API_PREFIX}/get-policies-metadata`;

export const CREATE_EXECUTION_API = () => `${PES_API_PREFIX}/create-execution`;

export const CREATE_RISK_API = () => `${PES_API_PREFIX}/create-risk`;

export const CREATE_POLICY_METADATA_API = () => `${PMS_API_PREFIX}/create-policy-metadata`;

export const GET_NAMESPACES_API = () => `${PMS_API_PREFIX}/get-namespaces`;

export const GET_RISKS_API = `${PES_API_PREFIX}/get-risks`;

export const UPDATE_RISKS_API = `${PES_API_PREFIX}/update-risk`;

export const GENERATE_TAGS_API = () => `${PMS_API_PREFIX}/generate-tags`;

export const SUBMIT_FEEDBACK_API = `${PES_API_PREFIX}/submit-feedback`;
