export enum METRIC_UNIT {
  COUNT = 'Count',
  MILLISECONDS = 'Milliseconds',
}

export enum METRIC_NAME {
  APP_ERROR_UNKNOWN = 'app.error.unknown',
  CLICK = 'click',
  LATENCY = 'latency',
}

export enum DIMENSION_NAME {
  COMPONENT_NAME = 'Component Name',
  ACTION = 'Action',
}

export type DIMENSION_VALUES = {
  [key in DIMENSION_NAME]?: any;
};

export type DIMENSION_TYPE = DIMENSION_VALUES | Record<string, string>;

export type EMFMetric = {
  _aws: {
    Timestamp: number;
    CloudWatchMetrics: {
      Namespace: string;
      Dimensions: string[][];
      Metrics: { Name: string; Unit?: string }[];
    }[];
  };
};

export type MetaDataMetric = {
  metricName: string;
  metricValue: number;
  metricUnit: METRIC_UNIT;
  dimensions?: DIMENSION_TYPE;
  namespace?: string;
};
