import { SourceType } from 'src/models';

export const POLICY_SOURCE_TYPE_DISPLAY_CONSTANT: Record<SourceType, string> = {
  [SourceType.GOVT]: 'Governmental',
  [SourceType.INSIDE]: 'Inside Amazon',
  [SourceType.POLICY_2_0]: 'Policy 2.0',
  [SourceType.FILE]: 'Document',
  [SourceType.LINK]: 'External Link',
  [SourceType.MY_TIME]: 'MyTime',
};
