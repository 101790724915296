import React from 'react';
import { Container } from '@amzn/stencil-react-components/layout';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { useLocation } from 'react-router-dom';

const DEFAULT_ERROR_MESSAGE = 'An unknown error occurred. Please try reloading the page.';
const DEFAULT_ERROR_CODE = '500';

interface ErrorPageProps {
  errorMessage?: string;
  errorCode?: string;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({
  errorMessage = DEFAULT_ERROR_MESSAGE,
  errorCode = DEFAULT_ERROR_CODE,
}) => {
  const location = useLocation();
  const locationState = location.state as ErrorPageProps | undefined;

  const displayErrorMessage = locationState?.errorMessage || errorMessage;
  const displayErrorCode = locationState?.errorCode || errorCode;

  return (
    <>
      <Container>
        <MessageBanner type={MessageBannerType.Error}>
          {`Error ${displayErrorCode}: ${displayErrorMessage}`}
        </MessageBanner>
      </Container>
    </>
  );
};
