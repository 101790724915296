import React from 'react';
import {
  FilterBar,
  FilterController,
  FilteringOptions,
  FilterList,
  FilterPopoverAction,
} from '@amzn/stencil-react-components/filtering';
import { H3 } from '@amzn/stencil-react-components/text';

export interface FilterOptionDetails {
  filterName: string;
  filterOptions: FilteringOptions[];
}

export interface TableFiltersProps {
  filterOptionsMap: Record<string, FilterOptionDetails>;
  filterSelections: Record<string, string[]>;
  setFilterSelections: (filterSelections: Record<string, string[]>) => void;
  handleFilterChange: (filterKey: string, filterValue: string[]) => void;
}

const TableFilters = ({
  filterOptionsMap,
  filterSelections,
  handleFilterChange,
  setFilterSelections,
}: TableFiltersProps) => {
  const onFilterChange = (filterKey: string) => (filterValue: string[]) => {
    handleFilterChange(filterKey, filterValue);
  };

  return (
    <fieldset role="group" style={{ border: '0', padding: '0', width: '100%' }}>
      <legend style={{ marginBottom: '0.5rem' }}>
        <H3>Filters</H3>
      </legend>
      <FilterBar
        flyoutTitleText={''}
        onApply={(filterSelections) => {
          setFilterSelections(
            Object.entries(filterSelections).reduce((selectionsAccumulated, [key, value]) => {
              selectionsAccumulated[key] = value as string[];
              return selectionsAccumulated;
            }, {} as Record<string, string[]>),
          );
        }}
        shouldShowAppliedFiltersRow={false}
      >
        {Object.entries(filterOptionsMap)
          .filter(([, { filterOptions }]) => filterOptions && filterOptions.length > 0)
          .map(([filterPath, { filterName, filterOptions }]) => (
            <FilterController key={filterName} name={filterName} titleText={`Select ${filterName}`}>
              <FilterList
                isMulti={true}
                onFilterChange={onFilterChange(filterPath) as FilterPopoverAction}
                options={filterOptions}
                searchFieldAriaLabel={`Choose ${filterName}`}
                searchFieldPlaceholder={`Type to search ${filterName}`}
                titleText={`Select a ${filterName}`}
                value={filterSelections[filterPath]}
                selectAllOptionLabel={`Select all ${filterName}`}
                resetButtonText={`Clear ${filterName}`}
              />
            </FilterController>
          ))}
      </FilterBar>
    </fieldset>
  );
};

export default TableFilters;
