import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import { AuditTableDataType, DEFAULT_DATE_RANGE } from 'src/components/riskdetails/constants';
import { DateTimeFormats } from 'src/utils';
import { GetRiskResponse, RiskActionType } from 'src/models';
import { RISK_FIELD_AUDIT_VIEW_MAP } from 'src/utils/riskUtils';

export const useAuditTab = (getRiskResponse: GetRiskResponse) => {
  const [endDate, setEndDate] = useState<string>(DateTime.now().toFormat(DateTimeFormats.DATE_TIME_YY_MM_DD));

  const getStartDateBasedOnRiskCreation = (createdDate: string): string => {
    const now = DateTime.now();
    const riskCreationDate = DateTime.fromISO(createdDate);

    const daysDifference = now.diff(riskCreationDate, 'days').days;
    if (daysDifference > DEFAULT_DATE_RANGE) {
      return now.minus({ days: DEFAULT_DATE_RANGE }).toFormat(DateTimeFormats.DATE_TIME_YY_MM_DD);
    }
    return riskCreationDate.toFormat(DateTimeFormats.DATE_TIME_YY_MM_DD);
  };

  const [startDate, setStartDate] = useState<string>(
    getStartDateBasedOnRiskCreation(getRiskResponse?.riskItem?.createdAt),
  );

  const updateDate = (newStartDate: string, newEndDate: string) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const isWithinDateRange = (date: string) => {
    const actionDate = DateTime.fromISO(date);
    const startDateTime = DateTime.fromISO(startDate).startOf('day');
    const endDateTime = DateTime.fromISO(endDate).endOf('day');
    return actionDate >= startDateTime && actionDate <= endDateTime;
  };

  // TODO: Modify this function to Support priority and ETA updates.
  const getAuditData = useMemo((): AuditTableDataType[] => {
    const { riskItem } = getRiskResponse;

    if (!riskItem?.riskActions) {
      return [];
    }

    return riskItem.riskActions
      .filter((action) => isWithinDateRange(action.date))
      .reverse()
      .reduce((accumulatedRows, currentRow) => {
        if (currentRow.type === RiskActionType.CREATED) {
          return accumulatedRows.concat({
            type: currentRow.type,
            updatedBy: currentRow.performedBy,
            updatedAt: currentRow.date,
          });
        }
        return accumulatedRows.concat(
          currentRow.fieldAudits.map((fieldAudit) => ({
            field: RISK_FIELD_AUDIT_VIEW_MAP[fieldAudit.field],
            type: currentRow.type,
            oldValue: fieldAudit.oldValue,
            newValue: fieldAudit.newValue,
            updatedBy: currentRow.performedBy,
            updatedAt: currentRow.date,
          })),
        );
      }, [] as AuditTableDataType[]); // TODO: Add a filtering logic on the table.
  }, [getRiskResponse, startDate, endDate]);

  return {
    startDate,
    endDate,
    getAuditData,
    updateDate,
  };
};
