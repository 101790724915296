import React, { useContext } from 'react';
import { CellComponentType, CellType, Table, TableColumn } from '@amzn/stencil-react-components/table';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { metricConfig, CREATE_EXECUTION } from 'src/constants';
import {
  ColoredText,
  DateText,
  ExecutionIdText,
  ReferencePolicies,
  RequesterAlias,
  SourcePolicy,
  ViewDetails,
} from 'src/components/dashboard/shared';
import { useGetExecutions } from 'src/hooks/useGetExecutionsHook';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { Col, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { useTableSortAndPagination } from 'src/hooks/useTablePagination/useTableSortAndPagination';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { CommonContext } from 'src/components';
import { createCardRenderer } from 'src/utils/tableUtils';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';
import { getClickMetricDimensions } from 'src/utils';

const PAGE_SIZE = 10;
const GET_EXECUTIONS_ERROR_MESSAGE =
  'Unable to load assessments in the namespace due to some error. Please try reloading the page.';
const NO_EXECUTIONS_WARNING_MESSAGE = 'No assessments found for the selected workspace.';

interface PolicyExecutionTableProps {
  namespaceId: string;
}

const PolicyExecutionTable = ({ namespaceId }: PolicyExecutionTableProps) => {
  const navigate = useNavigate();
  const { permissions } = useContext(CommonContext).permissionsContext;
  const {
    data: executionsData,
    isLoading: isGetExecutionsLoading,
    isError: isGetExecutionsError,
  } = useGetExecutions({
    namespaceId: namespaceId,
  });

  const {
    visibleData: visibleExecutions,
    sortState,
    paginationState,
  } = useTableSortAndPagination({
    data: (executionsData?.executions || []).filter((execution) => execution.namespaceId === namespaceId),
    pageSize: PAGE_SIZE,
    defaultActiveSortId: 'createdAt',
  });

  const columns: TableColumn<string, CellComponentType>[] = [
    { header: 'Assessment Id', cellComponent: ExecutionIdText, width: '15%' },
    { header: 'Source Policy', cellComponent: SourcePolicy },
    { header: 'Reference Policies', cellComponent: ReferencePolicies, hasReducedSpacing: true, width: '25%' },
    { header: 'Status', sortId: 'executionStatus', cellComponent: ColoredText },
    {
      header: 'Created At',
      sortId: 'createdAt',
      cellComponent: DateText,
    },
    {
      header: 'Created By',
      sortId: 'requesterAlias',
      cellComponent: RequesterAlias,
    },
  ];

  const handleStartAssessmentOnClick = () => {
    logger.emitCountMetric({
      metricName: METRIC_NAME.CLICK,
      dimensions: getClickMetricDimensions(
        metricConfig.PolicyExecutionTable.componentName,
        metricConfig.PolicyExecutionTable.actions.START_ASSESSMENT_BUTTON_CLICK,
      ),
    });
    navigate(CREATE_EXECUTION);
  };

  if (isGetExecutionsError) {
    return <MessageBanner type={MessageBannerType.Error}>{GET_EXECUTIONS_ERROR_MESSAGE}</MessageBanner>;
  }

  if (isGetExecutionsLoading) {
    return (
      <Row width={'100%'} justifyContent={'center'}>
        <Spinner size={SpinnerSize.Small} />
      </Row>
    );
  }

  return (
    <>
      <Row justifyContent="right" margin={{ top: 'S500', bottom: 'S500' }}>
        <Button disabled={!permissions.edit} variant={ButtonVariant.Primary} onClick={handleStartAssessmentOnClick}>
          Start Assessment
        </Button>
      </Row>
      {visibleExecutions.length === 0 ? (
        <MessageBanner type={MessageBannerType.Warning}>{NO_EXECUTIONS_WARNING_MESSAGE}</MessageBanner>
      ) : (
        <Col display="flex">
          <Table
            aria-labelledby="policy-execution-table"
            maxHeight={'90vh'}
            columns={columns}
            data={visibleExecutions}
            hasStickyHeader={true}
            renderCardForRow={createCardRenderer(columns)}
            {...sortState}
          />
          <Spacer height={'S300'} />
          <Pagination aria-labelledby="policy-execution-table-pagination" {...paginationState} />
        </Col>
      )}
    </>
  );
};

export default PolicyExecutionTable;
