import React from 'react';
import { ButtonCard } from '@amzn/stencil-react-components/card';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import { Chip, ChipBackgroundColor, ChipSize } from '@amzn/stencil-react-components/chip';

interface DashboardCardProps {
  title: string;
  description?: string;
  chip?: string;
  count: number;
  isActive: boolean;
  onClick: () => void;
  isLoading?: boolean;
}

const DashboardCard: React.FC<DashboardCardProps> = ({
  title,
  description,
  chip,
  count,
  isActive,
  onClick,
  isLoading,
}) => {
  const buttonCardStyle = {
    width: '100%',
    height: '100%',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'neutral05',
    },
  };

  return (
    <ButtonCard
      onClick={onClick}
      aria-pressed={isActive}
      backgroundColor={isActive ? 'neutral05' : undefined}
      css={buttonCardStyle}
    >
      <Col width="100%" gridGap="S200">
        <Row justifyContent="right" height="S500">
          {chip && (
            <Chip size={ChipSize.ExtraSmall} backgroundColor={ChipBackgroundColor.Red}>
              {chip}
            </Chip>
          )}
        </Row>
        <Row alignItems="center" justifyContent="center">
          <Text fontSize="T400" fontWeight="medium">
            {title}
          </Text>
        </Row>
        {description && (
          <Row justifyContent="center">
            <Text fontSize="T100" fontWeight="light" style={{ whiteSpace: 'normal' }}>
              {description}
            </Text>
          </Row>
        )}
        <Row alignItems="center" justifyContent="center" padding="S300">
          {isLoading ? (
            <Spinner />
          ) : (
            <Text fontSize="T600" textAlign="center">
              {count !== null ? count : '0'}
            </Text>
          )}
        </Row>
      </Col>
    </ButtonCard>
  );
};

export default DashboardCard;
