import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@amzn/stencil-react-components/layout';
import { useParams } from 'react-router';
import { DetailsPage } from 'src/components';
import { ProgressTrackerStepStatus } from '@amzn/stencil-react-components/progress-tracker';
import { CommonContext } from 'src/components/navbar/CommonContext';
import { showWorkspaceMessageBanner, WorkspaceMessageBanner } from 'src/components/workspaces/WorkspacesUtils';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { PolicyAdvisorErrorBoundary } from 'src/components/error-boundary/PolicyAdvisorErrorBoundary';
import { ErrorBoundaryComponent } from 'src/constants/ErrorBoundaryConstants';
import { useScrollIntoView } from 'src/hooks/useScrollIntoView';
import { H1 } from '@amzn/stencil-react-components/text';
import WorkflowStepsContainer from '../shared/workflow-steps-container/WorkflowStepsContainer';
import { Step } from '../shared';
import { useCreateExecutionWorkflow } from './hooks';
import { CreateExecutionWorkflowContextProvider } from './context/CreateExecutionWorkflowContext';
import { CreateExecutionComplete } from './steps/CreateExecutionComplete';
import { CreateExecutionPreviewStep, SelectPoliciesStep } from './steps';

const SELECT_POLICIES_LABEL = 'Select Policies';
// const SELECT_SPECIFICATIONS_LABEL = 'Select Specifications';
const PREVIEW_ASSESSMENT_REQUEST_LABEL = 'Preview Assessment Request';
const ASSESSMENT_RESULTS_LABEL = 'Assessment Results';

export const CreateExecutionWorkflow = () => {
  const navigate = useNavigate();
  const workflowRef = useRef<{ onResetWorkflow: () => void }>();
  const containerRef = useScrollIntoView();
  const { executionId, version: executionVersion } = useParams<{ executionId: string; version: string }>();
  const { workspaceContext } = useContext(CommonContext);
  const {
    selectedNamespaceId,
    createExecutionState,
    createExecution,
    getPoliciesMetadataState,
    isNextDisabledForSelectPoliciesStep,
    selectedPolicies,
    updateSelectedPolicies,
    selectPoliciesValidationError,
    // additionalInstructions,
    // handleAdditionalInstructionsUpdate,
    // selectedParameters,
    // handleParameterChange,
    handleCreateExecutionOnSubmit,
    toggleMyTimeCheckbox,
    compareWithMyTimeCheckbox,
    handleCompareWithMyTimeCheckboxUpdate,
    submittingExecution,
    isModalOpen,
    openModal,
    closeModal,
    onModalSubmit,
    policyExecutionResponse,
    isWorkflowLoading,
  } = useCreateExecutionWorkflow({ executionId, version: Number(executionVersion) });

  const baseSteps: Step[] = [
    {
      labelText: SELECT_POLICIES_LABEL,
      renderStepComponent: () => (
        <PolicyAdvisorErrorBoundary
          componentName={ErrorBoundaryComponent.POLICY_ADVISOR_CREATE_EXECUTION_WORKFLOW_SELECT_POLICIES}
        >
          <SelectPoliciesStep />
        </PolicyAdvisorErrorBoundary>
      ),
      checkIfNextClickDisabled: isNextDisabledForSelectPoliciesStep,
      status: policyExecutionResponse ? ProgressTrackerStepStatus.Disabled : undefined,
    },
    // {
    //   labelText: SELECT_SPECIFICATIONS_LABEL,
    //   renderStepComponent: () => <SelectSpecificationsStep />,
    //   status: policyExecutionResponse ? ProgressTrackerStepStatus.Disabled : undefined,
    // },
    {
      labelText: PREVIEW_ASSESSMENT_REQUEST_LABEL,
      renderStepComponent: () => (
        <PolicyAdvisorErrorBoundary
          componentName={ErrorBoundaryComponent.POLICY_ADVISOR_CREATE_EXECUTION_WORKFLOW_PREVIEW_ASSESSMENT}
        >
          <CreateExecutionPreviewStep />
        </PolicyAdvisorErrorBoundary>
      ),
      stepOnNextClick: () => handleCreateExecutionOnSubmit(),
      showActions: !(policyExecutionResponse || createExecutionState.loading || submittingExecution),
      status: policyExecutionResponse ? ProgressTrackerStepStatus.Complete : undefined,
    },
    {
      labelText: ASSESSMENT_RESULTS_LABEL,
      renderStepComponent: () => (
        <PolicyAdvisorErrorBoundary componentName={ErrorBoundaryComponent.POLICY_ADVISOR_EXECUTION_DETAILS_PAGE}>
          <DetailsPage />
        </PolicyAdvisorErrorBoundary>
      ),
      stepOnNextClick: () => null,
      showActions: !(policyExecutionResponse || createExecutionState.loading || submittingExecution),
      status: policyExecutionResponse ? ProgressTrackerStepStatus.Current : undefined,
    },
  ];

  const getSteps = (): Step[] => {
    return baseSteps;
  };

  useEffect(() => {
    if (workflowRef.current && !policyExecutionResponse) {
      workflowRef.current.onResetWorkflow();
    }
  }, [workspaceContext.selectedWorkspace.namespaceId]);

  if (isWorkflowLoading) {
    return <Spinner size={SpinnerSize.Large}></Spinner>;
  }

  if (showWorkspaceMessageBanner(workspaceContext)) {
    return WorkspaceMessageBanner(workspaceContext);
  }
  return (
    <CreateExecutionWorkflowContextProvider
      createExecutionWorkflowInitial={{
        selectedNamespaceId: selectedNamespaceId,
        createExecutionState: createExecutionState,
        createExecution: createExecution,
        getPoliciesMetadataState: getPoliciesMetadataState,
        selectedPolicies: selectedPolicies,
        updateSelectedPolicies: updateSelectedPolicies,
        selectPoliciesValidationError: selectPoliciesValidationError,
        // additionalInstructions: additionalInstructions,
        // handleAdditionalInstructionsUpdate: handleAdditionalInstructionsUpdate,
        // selectedParameters: selectedParameters,
        // handleParameterChange: handleParameterChange,
        toggleMyTimeCheckbox: toggleMyTimeCheckbox,
        compareWithMyTimeCheckbox: compareWithMyTimeCheckbox,
        handleCompareWithMyTimeCheckboxUpdate: handleCompareWithMyTimeCheckboxUpdate,
        submittingExecution: submittingExecution,
        isModalOpen: isModalOpen,
        openModal: openModal,
        closeModal: closeModal,
        onModalSubmit: onModalSubmit,
      }}
    >
      <Container ref={containerRef}>
        {!isWorkflowLoading && (
          <>
            <H1 margin={{ bottom: 'S300', top: 'S300' }}>Start Assessment</H1>
            <WorkflowStepsContainer steps={getSteps()} onCancel={() => navigate('/')} onReset={workflowRef}>
              <CreateExecutionComplete />
            </WorkflowStepsContainer>
          </>
        )}
      </Container>
    </CreateExecutionWorkflowContextProvider>
  );
};
