import { Country, PolicyMetadata, PolicyMetadataAttributes, SourceType } from 'src/models';
import { FilterSelection } from '@amzn/stencil-react-components/filtering';

export interface FilterOption {
  label: string;
  value: string;
}

export interface PolicyFilterDefinition {
  key: keyof PolicyMetadata | keyof PolicyMetadataAttributes;
  label: string;
  generateOptions: (policies: PolicyMetadata[]) => FilterOption[];
}

type PolicyValueType = string | string[] | SourceType | undefined;

export const generateUniqueOptions = (policies: PolicyMetadata[], key: keyof PolicyMetadata): FilterOption[] => {
  const uniqueValues = new Set<string>();

  policies.forEach((policy) => {
    const fieldValue = policy[key];
    if (Array.isArray(fieldValue)) {
      fieldValue.forEach((item) => uniqueValues.add(String(item)));
    } else if (fieldValue) {
      uniqueValues.add(String(fieldValue));
    }
  });

  return Array.from(uniqueValues).map((value) => ({ label: value, value }));
};

export const policyFilterDefinitions: PolicyFilterDefinition[] = [
  {
    key: 'domains',
    label: 'Domains',
    generateOptions: (policies) => generateUniqueOptions(policies, 'domains'),
  },
  {
    key: 'country',
    label: 'Country',
    // TODO: Handling generating unique options for PolicyMetadataAttributes separately, Can create a generic function in future
    generateOptions: (policies: PolicyMetadata[]) => {
      const uniqueCountries = new Set<string>();
      policies.forEach((policy) => {
        const country = getPolicyMetadataCountry(policy.policyMetadataAttributes?.country as string);
        if (country) {
          uniqueCountries.add(country);
        }
      });
      return Array.from(uniqueCountries).map((country) => ({ label: country, value: country }));
    },
  },
  {
    key: 'sourceType',
    label: 'Source',
    generateOptions: (policies) => generateUniqueOptions(policies, 'sourceType'),
  },
];

export const applyPolicyFilters = (
  policies: PolicyMetadata[],
  activeFilters: Record<string, FilterSelection>,
): PolicyMetadata[] => {
  return policies.filter((policy) =>
    Object.entries(activeFilters).every(([key, selection]) => {
      if (!selection || (Array.isArray(selection) && selection.length === 0)) return true;

      const policyValue = getPolicyValue(policy, key);

      if (Array.isArray(policyValue)) {
        return Array.isArray(selection)
          ? selection.some((s) => policyValue.includes(s as string))
          : policyValue.includes(selection as string);
      }

      return Array.isArray(selection) ? selection.includes(policyValue as string) : policyValue === selection;
    }),
  );
};

const getPolicyValue = (policy: PolicyMetadata, key: string): PolicyValueType => {
  switch (key) {
    case 'country':
      return getPolicyMetadataCountry(policy.policyMetadataAttributes?.country as string);
    case 'states':
    case 'jobClasses':
      return policy.policyMetadataAttributes?.[key as keyof PolicyMetadataAttributes];
    default:
      return policy[key as keyof PolicyMetadata] as PolicyValueType;
  }
};

export const getPolicyMetadataCountry = (country: string): Country => {
  return Country[country as keyof typeof Country] || country;
};
