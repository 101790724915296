import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { InputFooter, Select } from '@amzn/stencil-react-components/form';
import { EditRiskState } from 'src/components/riskdetails/hooks';
import { Text } from '@amzn/stencil-react-components/text';

interface RiskNameProps {
  id: string;
  editRiskState: EditRiskState;
  setTitle: (title: string) => void;
  titleOptions: Array<string>;
}

const RiskNameElement = forwardRef(
  (
    {
      id,
      editRiskState: {
        isEditing,
        edits: { title },
        showErrors,
      },
      titleOptions,
      setTitle,
    }: RiskNameProps,
    ref,
  ) => {
    const localRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => localRef.current);

    useEffect(() => {
      if (isEditing) {
        localRef.current?.focus();
      }
    }, [isEditing]);

    const isError = showErrors && !title;
    return isEditing ? (
      <>
        <Select
          id={id}
          aria-required={true}
          aria-labelledby={`${id}-label ${id}-toggle-button`}
          aria-describedby={`${id}-footer`}
          options={titleOptions}
          value={title}
          error={isError}
          placeholder={'Enter name'}
          onChange={(value) => {
            setTitle(value);
          }}
          width="100%"
          ref={localRef}
        />
        {isError && (
          <InputFooter id={`${id}-footer`} error>
            Please select a risk name
          </InputFooter>
        )}
      </>
    ) : (
      <Text id={id} aria-labelledby={`${id}-label`} textAlign="justify">
        {title}
      </Text>
    );
  },
);

export default RiskNameElement;
