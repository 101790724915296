import React, { useContext, useState } from 'react';
import { CellComponentType, Table, TableColumn } from '@amzn/stencil-react-components/table';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { Spacer } from '@amzn/stencil-react-components/layout';
import { PolicyMetadata, SourceType } from 'src/models';
import { NavigateFunction } from 'react-router-dom';
import { POLICY_DETAILS } from 'src/constants';
import { H4, Text } from '@amzn/stencil-react-components/text';
import { getPolicyMetadataCountry } from 'src/components/policy-marketplace/utils/policyFilters';
import { DateText, getPhoneToolLink } from 'src/components/dashboard/shared';
import { CommonContext } from 'src/components';
import { POLICY_SOURCE_TYPE_DISPLAY_CONSTANT } from 'src/constants/DisplayConstants';
import { createCardRenderer } from 'src/utils/tableUtils';
import { RouterLink } from '../shared/router-link';

interface PolicyMarketplaceTableProps {
  data: PolicyMetadata[];
  navigate: NavigateFunction;
}

const PolicyMarketplaceTable: React.FC<PolicyMarketplaceTableProps> = ({ data }) => {
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const { workspaceContext } = useContext(CommonContext);

  const contentText = (contentValue: any) => {
    return <Text>{contentValue}</Text>;
  };

  const headerText = (headerValue: string) => {
    return <H4>{headerValue}</H4>;
  };

  const columns: TableColumn<PolicyMetadata, CellComponentType>[] = [
    {
      header: headerText('Policy Name'),
      cellComponent: ({ data }) => (
        <RouterLink to={`${POLICY_DETAILS}/${workspaceContext.selectedWorkspace.namespaceId}/${data.policyId}`}>
          {data.name}
        </RouterLink>
      ),
      width: '25%',
    },
    {
      header: headerText('Domains'),
      cellComponent: ({ data }) => contentText(data.domains?.join(', ') || '-'),
    },
    {
      header: headerText('Source'),
      cellComponent: ({ data }) => contentText(POLICY_SOURCE_TYPE_DISPLAY_CONSTANT[data.sourceType as SourceType]),
    },
    {
      header: headerText('Country'),
      cellComponent: ({ data }) =>
        contentText(getPolicyMetadataCountry(data.policyMetadataAttributes?.country as string)),
    },
    {
      header: headerText('Created At'),
      cellComponent: DateText,
    },
    {
      header: headerText('Last Updated By'),
      cellComponent: ({ data }) => getPhoneToolLink(data.updatedBy),
    },
  ];

  const totalPages = Math.ceil(data.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = data.slice(startIndex, endIndex);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Table
        aria-labelledby={'policy-marketplace-table'}
        maxHeight={'50vh'}
        columns={columns}
        data={currentPageData}
        hasStickyHeader={true}
        renderCardForRow={createCardRenderer(columns)}
        isStriped={true}
      />
      <Spacer height={'S300'} />
      <Pagination
        aria-labelledby={'policy-marketplace-table-pagination'}
        numberOfPages={totalPages}
        onPageSelect={handlePageChange}
        selectedPage={currentPage}
      />
    </>
  );
};

export default PolicyMarketplaceTable;
