import { RiskActionType } from 'src/models';

export enum RiskTabs {
  DETAILS = 'Details',
  AUDIT_HISTORY = 'Audit History',
}

export enum RiskItems {
  RISK_NAME = 'Risk Name',
  RISK_DETAIL = 'Risk Detail',
  INTERNAL_POLICY = 'Internal Policy',
  REFERENCE_POLICIES = 'Reference Policies',
  ASSESSMENT = 'Assessment',
  CREATION_DATE = 'Creation Date',
  CREATED_BY = 'Created By',
  LAST_UPDATE_DATE = 'Last Update Date',
  LAST_UPDATED_BY = 'Last Updated By',
  STATUS = 'Status',
  PRIORITY = 'Priority',
  ETA = 'ETA',
}

export enum AuditView {
  TITLE = 'Title',
  DETAILS = 'Details',
  STATUS = 'Status',
  PRIORITY = 'Priority',
  FIX_BY_DATE = 'Fix By Date',
}

export enum AuditTableHeaders {
  FIELD = 'Field',
  ACTION = 'Action',
  ORIGINAL_VALUE = 'Original Value',
  UPDATED_VALUE = 'Updated Value',
  UPDATED_BY = 'Updated By',
  UPDATED_AT = 'Updated At',
}

export interface AuditTableDataType {
  type: RiskActionType;
  updatedBy: string;
  updatedAt: string;
  field?: AuditView;
  oldValue?: string;
  newValue?: string;
}

export const DEFAULT_DATE_RANGE = 30;

export const COLON_SEPARATOR = ' : ';
export const EMPTY_SEPARATOR = ' - ';

export const POLICY_DELIMITER = ', ';
