import KatalLogger, { Level } from '@amzn/katal-logger';
import { DIMENSION_TYPE, EMFMetric, MetaDataMetric, METRIC_NAME, METRIC_UNIT } from 'src/constants/LoggerConstants';
import { getAuthorizationTokenFromStorage } from 'src/utils';

const NAMESPACE = 'PolicyAdvisorUI';
const LOGGER_ERROR_MESSAGE = 'Error occurred while sending metric';

export class PolicyAdvisorAppLogger extends KatalLogger {
  async logError(
    message: string,
    error: Error,
    context?: Record<string, unknown>,
    includeStandardContext: boolean = true,
  ): Promise<void> {
    try {
      const loggingContext = includeStandardContext ? this.applyStandardContext({ error, context }) : context;
      await this.error(message, loggingContext);
    } catch (error: any) {
      console.log(LOGGER_ERROR_MESSAGE);
    }
  }

  /**
   * Logs an EMF metric using the Katal logger (with EMF payload added to the log)
   */
  public logEmfMetric = (metricPayload: MetaDataMetric): void => {
    const emfLog = this.createEmfLog(metricPayload);
    void this.info(metricPayload.metricName, { emfLog: emfLog });
  };

  /**
   * Helper method to log count metric with count = 1
   */
  public emitCountMetric = (
    { metricName, dimensions }: Omit<MetaDataMetric, 'metricValue' | 'metricUnit'>,
    metricsValue?: number,
  ): void => {
    this.logEmfMetric({
      metricName: metricName,
      metricValue: metricsValue ? metricsValue : 1,
      metricUnit: METRIC_UNIT.COUNT,
      dimensions,
    });
  };

  /**
   * Helper method to log latency metric (in milliseconds)
   */
  public emitLatencyMetric = ({ metricName, metricValue, dimensions }: Omit<MetaDataMetric, 'metricUnit'>): void => {
    this.logEmfMetric({
      metricName: metricName,
      metricValue: metricValue,
      metricUnit: METRIC_UNIT.MILLISECONDS,
      dimensions,
    });
  };

  /**
   * Given metric/dimension values, creates a EMF payload in the expected
   * AWS syntax.
   *
   * More Info: https://docs.aws.amazon.com/AmazonCloudWatch/latest/monitoring/CloudWatch_Embedded_Metric_Format_Specification.html
   */
  private readonly createEmfLog = ({
    metricName,
    metricValue,
    metricUnit,
    dimensions,
    namespace = NAMESPACE,
  }: MetaDataMetric): EMFMetric => {
    const allDimensions: DIMENSION_TYPE = {
      Application: 'Policy Advisor',
      ...dimensions,
    };
    return {
      _aws: {
        Timestamp: Date.now(),
        CloudWatchMetrics: [
          {
            Namespace: namespace,
            Dimensions: [Object.keys(allDimensions)],
            Metrics: [
              {
                Name: metricName,
                Unit: metricUnit,
              },
            ],
          },
        ],
      },
      ...allDimensions,
      [metricName]: metricValue,
    };
  };

  /**
   * Used to add standard info that we want in every log
   * @param additionalContext
   */

  private applyStandardContext(additionalContext?: Record<string, unknown>): Record<string, unknown> {
    // Add additional information if required
    return {
      ...additionalContext,
    };
  }
}

export const katalLoggerConfig = {
  url: 'https://9ewp6t2xj5.execute-api.us-west-2.amazonaws.com/prod/v1/log',
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
  headers: {
    Authorization: `Bearer ${getAuthorizationTokenFromStorage()}`,
  },
};

export const logger = new PolicyAdvisorAppLogger(katalLoggerConfig);
logger.addErrorListener(() => {
  // We are only emitting the error count metric. Since the helper method is returning true,
  // Katal listener will already log this to the logs.
  logger.emitCountMetric({ metricName: METRIC_NAME.APP_ERROR_UNKNOWN });
  return true;
});
