export enum DomainType {
  OVERTIME = 'OVERTIME',
}

export enum Country {
  IND = 'India',
  UAE = 'United-Arab-Emirates',
}

export enum JobClass {
  F = 'F',
  R = 'R',
  H = 'H',
  X = 'X',
  Q = 'Q',
  I = 'I',
  M = 'M',
  S = 'S',
}

export interface TrackingSources {
  referenceId: string;
  sourceType: SourceType;
}

export interface PolicyMetadataAttributes {
  country?: string;
  states?: string[];
  jobClasses?: string[];
}

export enum SourceType {
  FILE = 'FILE',
  GOVT = 'GOVT',
  INSIDE = 'INSIDE',
  LINK = 'LINK',
  MY_TIME = 'MY_TIME',
  POLICY_2_0 = 'POLICY_2_0',
}

export enum ComparisonGroupType {
  A = 'A',
  B = 'B',
}

export interface Policy {
  policyId: string;
  version: number;
  domains: string[];
  editedS3Path?: string;
  name: string;
  s3Path?: string;
  comparisonGroup: ComparisonGroupType;
  sourceType: SourceType;
}
export interface UserInput {
  aspectIds?: string[];
  definitionIds?: string[];
  additionalResources?: AdditionalResources[];
  additionalInstructions?: string;
}

export enum AdditionalResourceType {
  FILE = 'FILE',
  LINK = 'LINK',
}

export interface AdditionalResources {
  type: AdditionalResourceType;
  group: ComparisonGroupType;
  path?: string;
  link?: string;
}

export enum RiskPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum RiskStatus {
  ACKNOWLEDGED = 'ACKNOWLEDGED',
  INVALIDATED = 'INVALIDATED',
  WORK_IN_PROGRESS = 'WORK_IN_PROGRESS',
  UNACKNOWLEDGED = 'UNACKNOWLEDGED',
  RESOLVED = 'RESOLVED',
}

export interface RiskAction {
  date: string;
  type: RiskActionType;
  fieldAudits: RiskFieldAudit[];
  performedBy: string;
}

export enum RiskActionType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
}

export interface RiskFieldAudit {
  field: string;
  oldValue: string;
  newValue: string;
}

export interface Risk {
  riskId: string;
  title: string;
  details: string;
  executionId?: string;
  status: RiskStatus;
  createdBy: string;
  namespaceId: string;
  policyId: string;
  referencePolicyIds: string[];
  createdAt: string;
  updatedAt: string;
  priority?: RiskPriority;
  fixByDate?: string;
  riskActions?: RiskAction[];
  lastAcknowledgedDate?: string;
}

export interface Aspect {
  aspect: string;
  aspectDefinition: string;
}

export enum FeedbackUseCase {
  POLICY_TAGS = 'POLICY_TAGS',
  ASSESSMENT_DETAILS = 'ASSESSMENT_DETAILS',
  RISK_DETAILS = 'RISK_DETAILS',
}
