import React from 'react';
import { Flex, Row } from '@amzn/stencil-react-components/layout';
import { AvatarSize, PersonalDetailsAvatar } from '@amzn/stencil-react-components/avatar';
import { Text } from '@amzn/stencil-react-components/text';
import { formatEnumString, getBorderColor, mapExecutionStatusToDisplayText } from 'src/components/utils';
import { Chip, ChipBackgroundColor, ChipSize } from '@amzn/stencil-react-components/chip';
import { differenceInDays, isBefore } from 'date-fns';
import {
  IconAlertCircleFill,
  IconAlertTriangleFill,
  IconCalendar,
  IconChevronRight,
  IconExternalLink,
  IconSize,
} from '@amzn/stencil-react-components/icons';
import { Badge, BadgeType } from '@amzn/stencil-react-components/badge';
import { NUMBER_OF_MILLISECONDS_IN_A_SECOND } from 'src/utils';
import { ExecutionDetails } from 'src/models/api-models/getExecutions';
import { Link } from '@amzn/stencil-react-components/link';
import { EXECUTION_DETAILS, POLICY_DETAILS } from 'src/constants';
import { ComparisonGroupType } from 'src/models';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { SYSTEM_USER } from 'src/constants/CommonConstants';
import { RouterLink } from 'src/components/shared/router-link';

export const TitleText = ({ data }: { data: any }) => {
  return <Text>{data?.title}</Text>;
};

export const SourcePolicy = ({ data: { policies, namespaceId } }: { data: ExecutionDetails }) => {
  const sourcePolicy = policies?.find((policy) => policy.comparisonGroup === ComparisonGroupType.A);
  return sourcePolicy ? (
    <RouterLink to={`${POLICY_DETAILS}/${namespaceId}/${sourcePolicy.policyId}`}>{sourcePolicy.name}</RouterLink>
  ) : (
    <>-</>
  );
};

export const ReferencePolicies = ({ data: { policies, namespaceId } }: { data: ExecutionDetails }) => {
  const referencePolicies = policies?.filter((policy) => policy.comparisonGroup !== ComparisonGroupType.A);
  return referencePolicies ? (
    <>
      {referencePolicies.map((referencePolicy, index) => (
        <>
          <RouterLink
            key={referencePolicy.policyId}
            to={`${POLICY_DETAILS}/${namespaceId}/${referencePolicy.policyId}`}
          >
            {referencePolicy.name}
          </RouterLink>
          {index !== referencePolicies.length - 1 && ', '}
        </>
      ))}
    </>
  ) : (
    <>-</>
  );
};

//TODO: Need confirmation executionId is clickable link or click on row to redirect
export const ExecutionIdText = ({ data }: { data: any }) => {
  return (
    <RouterLink to={`${EXECUTION_DETAILS}/${data.executionId}/version/${data.version}`}>{data.executionId}</RouterLink>
  );
};

export const VersionComponent = ({ data }: { data: any }) => {
  return (
    <Row padding="S200" alignItems="center">
      <Badge value={data?.version} type={BadgeType.Primary} />
    </Row>
  );
};

export const ExecutionVersionComponent = ({ data }: { data: any }) => {
  return (
    <Row padding="S200" alignItems="center">
      <Badge value={data?.executionVersion} type={BadgeType.Primary} />
    </Row>
  );
};

export const AvatarCell = ({ data }: { data: any }) => (
  <PersonalDetailsAvatar username={data?.requesterAlias} size={AvatarSize.Small} />
);

export const DateText = ({ data }: { data: any }) => {
  const dateObject = new Date(
    typeof data.createdAt === 'string' ? data?.createdAt : data?.createdAt * NUMBER_OF_MILLISECONDS_IN_A_SECOND,
  ); // TODO: standardise date based data in API response and move logic of date conversion at API layer
  return (
    <Flex gridGap="S200" flexDirection="row">
      <Text fontSize="T100">
        {dateObject.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
      </Text>
      <Text fontSize="T100">
        {dateObject.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}
      </Text>
    </Flex>
  );
};

export const ColoredText = ({ data }: { data: any }) => {
  return (
    <Text color={getBorderColor(data?.reviewStatus || data?.executionStatus)}>
      {formatEnumString(data?.reviewStatus || mapExecutionStatusToDisplayText(data?.executionStatus))}
    </Text>
  );
};

interface DateStatusInfo {
  background: ChipBackgroundColor;
  icon: JSX.Element;
}

function getDateStatusInfo(unixTimestamp: number): DateStatusInfo {
  const today = new Date();
  const dueDate = new Date(unixTimestamp);
  if (isBefore(dueDate, today)) {
    return {
      background: ChipBackgroundColor.Red,
      icon: <IconAlertCircleFill size={IconSize.ExtraSmall} color="red70" />,
    };
  }

  const daysDifference = differenceInDays(dueDate, today);

  if (daysDifference < 5) {
    return {
      background: ChipBackgroundColor.Yellow,
      icon: <IconAlertTriangleFill size={IconSize.ExtraSmall} color="yellow70" />,
    };
  }

  return {
    background: ChipBackgroundColor.Default,
    icon: <IconCalendar color="neutral70" />,
  };
}

export const DateTextBanner = ({ data }: { data: any }) => {
  const { background, icon } = getDateStatusInfo(data?.needByDate * NUMBER_OF_MILLISECONDS_IN_A_SECOND);
  const dateObject = new Date(data?.needByDate * NUMBER_OF_MILLISECONDS_IN_A_SECOND);
  return (
    <Chip size={ChipSize.Small} backgroundColor={background} icon={icon}>
      <Flex gridGap="S200" flexDirection="row">
        <Text fontSize="T100">
          {dateObject.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
        </Text>
        <Text fontSize="T100">
          {dateObject.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}
        </Text>
      </Flex>{' '}
    </Chip>
  );
};

export const ViewDetails = ({ data: { executionId, version } }: { data: ExecutionDetails }) => {
  const navigate = useNavigate();
  return (
    <Button
      icon={<IconChevronRight />}
      onClick={() => navigate(`${EXECUTION_DETAILS}/${executionId}/version/${version}`)}
      variant={ButtonVariant.Tertiary}
    />
  );
};

export const RequesterAlias = ({ data: { requesterAlias } }: { data: ExecutionDetails }) => {
  return getPhoneToolLink(requesterAlias);
};

export const getPhoneToolLink = (alias: string) => {
  return alias.toLowerCase() === SYSTEM_USER ? (
    <Text>{alias}</Text>
  ) : (
    <Link
      href={`https://phonetool.amazon.com/users/${alias}`}
      target="_blank"
      icon={<IconExternalLink size={IconSize.ExtraSmall} title="Opens in a phone tool" />}
    >
      {alias}
    </Link>
  );
};
