import React from 'react';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H1, H2, Text } from '@amzn/stencil-react-components/text';
import { useDetailsPage } from 'src/components';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { IconDocument, IconSize } from '@amzn/stencil-react-components/icons';
import { Chip, ChipBackgroundColor, ChipSize } from '@amzn/stencil-react-components/chip';
import { Card } from '@amzn/stencil-react-components/card';
import { ComparisonGroupType, ExecutionType } from 'src/models';
import { Link } from '@amzn/stencil-react-components/link';
import { useParams } from 'react-router';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { ExecutionStatus } from 'src/models/api-models/getExecutions';
import ExecutionRisksTable from 'src/components/policy-details/ExecutionRisksTable';
import { useNavigate } from 'react-router-dom';
import { useComponentLoadedLatencyMetric } from 'src/hooks';
import { metricConfig } from 'src/constants';
import PolicyAuditDetailsPage from './PolicyAuditDetailsPage';

const DetailsPage = () => {
  const { executionId, version } = useParams<{ executionId: string; version: string }>();
  const navigate = useNavigate();
  const {
    responseState: { getExecutionResponse },
    isLoading,
    groupBasedPoliciesMap,
  } = useDetailsPage({ executionId: executionId!, version: Number(version) });

  useComponentLoadedLatencyMetric({
    componentName: metricConfig.ExecutionDetailsPage.componentName,
    latencyAction: metricConfig.ExecutionDetailsPage.actions.EXECUTION_DETAILS_PAGE_LOAD_LATENCY,
    isComponentLoaded: !isLoading,
  });

  const renderContentBasedOnExecutionStatus = () => {
    switch (getExecutionResponse?.executionStatus) {
      case ExecutionStatus.COMPARISON_FAILED:
      case ExecutionStatus.POLICY_COLLECTION_FAILED:
        return (
          <MessageBanner type={MessageBannerType.Error}>
            <Text fontSize={'T200'}>The execution has failed. Please create another execution request.</Text>
          </MessageBanner>
        );
      case ExecutionStatus.POLICY_COLLECTION_NOT_STARTED:
      case ExecutionStatus.POLICY_COLLECTION_IN_PROGRESS:
      case ExecutionStatus.POLICY_COLLECTION_COMPLETED:
      case ExecutionStatus.COMPARISON_NOT_STARTED:
      case ExecutionStatus.COMPARISON_IN_PROGRESS:
        return (
          <MessageBanner type={MessageBannerType.Informational}>
            <Text fontSize={'T200'}>
              The execution is still in progress with the following status: In Progress. Please refresh after sometime.
            </Text>
          </MessageBanner>
        );
      case ExecutionStatus.COMPARISON_COMPLETED:
        return renderExecutionTypeComponent();
      default:
        return renderExecutionTypeComponent();
    }
  };

  const renderExecutionTypeComponent = () => {
    switch (getExecutionResponse?.executionType) {
      case ExecutionType.ASYNCHRONOUS_COMPARISON:
        return (
          <PolicyAuditDetailsPage
            comparisonData={getExecutionResponse?.policyComparisonData?.result ?? ''}
            executionId={executionId}
          />
        );
      case ExecutionType.RISK_GENERATION:
        return (
          <ExecutionRisksTable
            riskComparisonData={getExecutionResponse?.policyComparisonData?.risks ?? []}
            navigate={navigate}
            executionId={executionId}
          />
        );
      default:
        return <H2 textAlign={'center'}>Sorry!! Unknown execution type</H2>;
    }
  };

  const isFailureStatus = (): boolean => {
    return (
      getExecutionResponse?.executionStatus === ExecutionStatus.COMPARISON_FAILED ||
      getExecutionResponse?.executionStatus === ExecutionStatus.POLICY_COLLECTION_FAILED
    );
  };

  // TODO: Showing only first policy details for now.
  return (
    <Container>
      {isLoading ? (
        <Spinner size={SpinnerSize.Large} />
      ) : (
        <>
          <Row gridGap={'S300'} justifyContent={'space-between'} margin={{ top: 'S500', bottom: 'S300' }}>
            <Row padding={'S100'} gridGap={'S200'} alignItems={'center'}>
              <IconDocument size={IconSize.Large} color={'primary50'} aria-hidden={true} />
              <H1>{groupBasedPoliciesMap ? `${groupBasedPoliciesMap[ComparisonGroupType.A]?.[0]?.policyName}` : ''}</H1>
              {groupBasedPoliciesMap &&
                groupBasedPoliciesMap[ComparisonGroupType.A]?.[0]?.domains.map((domain) => (
                  <>
                    <Chip key={domain} backgroundColor={ChipBackgroundColor.Blue} size={ChipSize.Small}>
                      {domain}
                    </Chip>
                    <Spacer width={'S200'} />
                  </>
                ))}
              <Chip
                backgroundColor={isFailureStatus() ? ChipBackgroundColor.Red : ChipBackgroundColor.Green}
                size={ChipSize.Small}
              >
                {getExecutionResponse?.executionStatus}
              </Chip>
            </Row>
          </Row>
          <Col gridGap={'S300'}>
            <Card isElevated={true}>
              <Row gridGap={'S300'} justifyContent={'space-between'} width={'100%'}>
                <Col alignItems={'center'}>
                  <Text fontSize={'T200'} fontWeight={'bold'}>
                    Group A Source
                  </Text>
                  {groupBasedPoliciesMap &&
                    groupBasedPoliciesMap[ComparisonGroupType.A]?.map((policyContentData) => {
                      return (
                        <Link
                          href={policyContentData.originalFilePath}
                          target={'_blank'}
                          key={policyContentData.policyId}
                        >
                          {`${policyContentData.policyName} [${policyContentData.type}]`}
                        </Link>
                      );
                    })}
                </Col>
                <Col alignItems={'center'}>
                  <Text fontSize={'T200'} fontWeight={'bold'}>
                    Group B Source
                  </Text>
                  {groupBasedPoliciesMap &&
                    groupBasedPoliciesMap[ComparisonGroupType.B]?.map((policyContentData) => {
                      return (
                        <Link
                          href={policyContentData.originalFilePath}
                          target={'_blank'}
                          key={policyContentData.policyId}
                        >
                          {`${policyContentData.policyName} [${policyContentData.type}]`}
                        </Link>
                      );
                    })}
                </Col>
                <Col alignItems={'center'}>
                  <Text fontSize={'T200'} fontWeight={'bold'}>
                    Requested at
                  </Text>
                  <Text>{getExecutionResponse?.createdAt?.toLocaleString()}</Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col margin={{ top: 'S400' }}>{renderContentBasedOnExecutionStatus()}</Col>
        </>
      )}
    </Container>
  );
};

export default DetailsPage;
