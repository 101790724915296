import { AxiosResponse } from 'axios';
import { PAPI_ENDPOINT_MAP, PAPI_IAM_ROLE_MAP, REGION } from 'src/constants';
import { Stage } from 'src/hooks';
import { SearchEmployeeV2Input } from 'src/models';
import { getFederateAccessTokenFromStorage } from 'src/utils';
import { AWSHttpClient } from './AWSHttpClient';

/**
 * Class to query PAPI.
 * @param region One of the regions in which PAPI resources are available.
 * @param stage Either `Gamma` for fabricated data and `Prod` for real data.
 * @param papiRoleArn Role which has permissions to query PAPI `stage` resources in `region`. Credentials will be automatically refreshed.
 * @returns client to make requests
 */
export class PAPI {
  private static singleton: Record<string, PAPI>;

  /**
   * Get singleton instance of PAPI client
   */
  static async getInstance(region: REGION, stage: Stage): Promise<PAPI> {
    if (PAPI.singleton === undefined) {
      PAPI.singleton = {};
    }
    const papiRoleArn = PAPI_IAM_ROLE_MAP.get(stage)?.get(region);
    const papiInvocationHost = PAPI_ENDPOINT_MAP.get(stage)?.get(region);
    if (!papiRoleArn || !papiInvocationHost) {
      throw Error(`PAPI Role or endpoint not found for given stage: ${stage} and region: ${region}`);
    }
    return (PAPI.singleton[region] =
      PAPI.singleton[PAPI.getSingletonKey([region, stage])] ??
      (await PAPI.createInstance(region, papiInvocationHost, papiRoleArn)));
  }

  /**
   * Method to be used to create an instance of this class.
   */
  private static async createInstance(region: REGION, papiInvocationHost: string, papiRoleArn: string) {
    const awsHttpClient = await AWSHttpClient.createInstance(papiInvocationHost, region, papiRoleArn);
    return new PAPI(awsHttpClient);
  }

  /**
   * Http client to make signed requests to PAPI.
   */
  private readonly awsHttpClient: AWSHttpClient;

  constructor(awsHttpClient: AWSHttpClient) {
    this.awsHttpClient = awsHttpClient;
  }

  private unpackAxiosResponse(response: AxiosResponse<any, any>) {
    if (response.status === 200 && response.data) {
      return response.data;
    }
    throw new Error(`${response.status}: ${response.statusText}`);
  }

  async getEmployeeByLogin(login: string, expand?: string) {
    const response = await this.awsHttpClient.get(
      `/v2/employee/login:${login}`,
      PAPI.getPAPIBrowserHeaders(),
      expand ? { expand } : {},
    );
    return this.unpackAxiosResponse(response);
  }

  async getEmployeeByPersonId(personId: string, expand?: string) {
    const response = await this.awsHttpClient.get(
      `/v2/employee/${personId}`,
      PAPI.getPAPIBrowserHeaders(),
      expand ? { expand } : {},
    );
    return this.unpackAxiosResponse(response);
  }

  async getJobHistoryByPersonId(personId: string) {
    const response = await this.awsHttpClient.get(
      `/v2/employee/${personId}/job-history`,
      PAPI.getPAPIBrowserHeaders(),
      {},
    );
    return this.unpackAxiosResponse(response);
  }

  async peopleSearchByLogin(login: string) {
    // TODO: Setting hard limit for maintain size of modal.
    const response = await this.awsHttpClient.get(`/v2/search/autocomplete/login`, PAPI.getPAPIBrowserHeaders(), {
      fragment: login,
      size: '5',
    });
    return this.unpackAxiosResponse(response);
  }

  async searchEmployeeV2(input: SearchEmployeeV2Input) {
    const { nextToken, searchTerm, size = 5 } = input;
    const response = await this.awsHttpClient.post('/v2/search',
        PAPI.getPAPIBrowserHeaders(),
        {
      query: {
        occurrence: 'MUST',
        expressions: [
          {
            term: {
              attribute: 'basicInfo.jobStatus',
              // Only look for active employees
              value: 'A',
            },
          },
          {
            template: {
              type: 'NAME',
              params: {
                value: searchTerm,
              },
            },
          },
        ],
      },
      sortBy: 'WEIGHT_THEN_LOGIN',
      nextToken,
      size,
    });
    return this.unpackAxiosResponse(response);
  }

  private static getSingletonKey(params: string[]) {
    return params.join('_');
  }

  private static getPAPIBrowserHeaders() {
    return {
      'x-amz-papi-browser-token': `Bearer ${getFederateAccessTokenFromStorage()}`,
    };
  }
}
