import { useContext, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuthentication } from 'src/components';
import { useCreateExecution, useGetExecution, useGetPoliciesMetadata } from 'src/hooks';
import { useBulkCreatePolicyMetadata } from 'src/hooks/useCreatePolicyMetadata';
import { ComparisonGroupType, ExecutionType, Policy, PolicyMetadata, SourceType } from 'src/models';
import { metricConfig, EXECUTION_DETAILS } from 'src/constants';
import { useNavigate } from 'react-router-dom';
import { CommonContext } from 'src/components/navbar/CommonContext';
import { logger } from 'src/logger';
import { METRIC_NAME } from 'src/constants/LoggerConstants';
import { getClickMetricDimensions } from 'src/utils';

interface CreateExecutionWorkflowProps {
  executionId?: string;
  version?: number;
}

export interface ParameterType {
  id: string;
  comparisonAspect: string;
  definition: string;
}

// TODO: Remove hardcoded parameters[aspect Ids] in future.
// export const parameterOptions: ParameterType[] = [
//   {
//     id: '1',
//     comparisonAspect: 'Normal Working Hours',
//     definition: 'The normal expected number of working hours',
//   },
//   {
//     id: '2',
//     comparisonAspect: 'Break Periods',
//     definition: 'Amount of time to take break during a shift',
//   },
//   {
//     id: '3',
//     comparisonAspect: 'Salary',
//     definition: 'The amount of pay compensation for working hours',
//   },
//   {
//     id: '4',
//     comparisonAspect: 'Overtime Pay',
//     definition: 'The amount of overtime pay provided based on work hours beyond normal working hours',
//   },
// ];

export type SelectedPoliciesType = Record<ComparisonGroupType, PolicyMetadata[]>;

export enum AdditionalResourceType {
  PARAMETERS, // Aspect Ids to be taken as parameter.
  INSTRUCTIONS,
}

export const useCreateExecutionWorkflow = ({ executionId, version }: CreateExecutionWorkflowProps) => {
  // TODO: Remove this context as it is being used for getting userId which will be passed in headers.
  const { authenticatedUser } = useAuthentication();
  const { workspaceContext } = useContext(CommonContext);
  const { createExecutionState, createExecution } = useCreateExecution();
  const { getPoliciesMetadataState, getPoliciesMetadata } = useGetPoliciesMetadata();
  const { createPoliciesInBulk, bulkCreateState } = useBulkCreatePolicyMetadata();
  const navigate = useNavigate();

  const selectedNamespaceId = workspaceContext.selectedWorkspace.namespaceId;

  const {
    data: policyExecutionResponse,
    isLoading: isLoadingExecution,
    isError: isErrorExecution,
    error: executionError,
  } = useGetExecution({
    executionId,
    version,
  });

  const [selectedPolicies, setSelectedPolicies] = useState<SelectedPoliciesType>({
    [ComparisonGroupType.A]: [],
    [ComparisonGroupType.B]: [],
  });

  const [selectPoliciesValidationError, setSelectPoliciesValidationError] = useState<string>();

  // const [additionalInstructions, setAdditionalInstructions] = useState<string>();

  // const [selectedParameters, setSelectedParameters] = useState<ParameterType[]>(parameterOptions);

  const [compareWithMyTimeCheckbox, setCompareWithMyTimeCheckbox] = useState(false);

  const [submittingExecution, setSubmittingExecution] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!policyExecutionResponse) return;

    const groupAPolicies = policyExecutionResponse.policies
      .filter((policy) => policy.comparisonGroup === ComparisonGroupType.A)
      .map(
        (policy) =>
          ({
            policyId: policy.policyId,
            versionNumber: policy.version,
            name: policy.name,
            sourceType: policy.sourceType,
            domains: policy.domains,
            // Not adding other fields as it is not present in policy object.
          } as PolicyMetadata),
      );

    const groupBPolicies = policyExecutionResponse.policies
      .filter((policy) => policy.comparisonGroup === ComparisonGroupType.B)
      .map(
        (policy) =>
          ({
            policyId: policy.policyId,
            versionNumber: policy.version,
            name: policy.name,
            sourceType: policy.sourceType,
            domains: policy.domains,
            // Not adding other fields as it is not present in policy object.
          } as PolicyMetadata),
      );

    setSelectedPolicies({
      [ComparisonGroupType.A]: groupAPolicies,
      [ComparisonGroupType.B]: groupBPolicies,
    });

    // setAdditionalInstructions(policyExecutionResponse.userInput.additionalInstructions || '');

    // if (policyExecutionResponse.userInput.aspectIds) {
    //   const selectedParams = parameterOptions.filter((param) =>
    //     policyExecutionResponse.userInput.aspectIds?.includes(param.id),
    //   );
    //   setSelectedParameters(selectedParams);
    // }

    // TODO: Add a more robust check to figure out my time policies and set the checkbox.
    const hasMyTimePolicies = groupBPolicies.some((policy) => policy.sourceType === SourceType.MY_TIME);
    setCompareWithMyTimeCheckbox(hasMyTimePolicies);
  }, [policyExecutionResponse?.executionId]);

  useEffect(() => {
    handleNamespaceIdSelection(workspaceContext.selectedWorkspace.namespaceId);
    // setAdditionalInstructions(undefined);
    // setSelectedParameters(parameterOptions);
  }, [workspaceContext.selectedWorkspace]);

  useEffect(() => {
    if (createExecutionState?.data?.executionId && createExecutionState?.data?.version) {
      navigate(
        `${EXECUTION_DETAILS}/${createExecutionState.data.executionId}/version/${createExecutionState.data.version}`,
      );
    }
  }, [createExecutionState?.data?.executionId, createExecutionState?.data?.version]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onModalSubmit = () => {
    getPoliciesMetadata({
      userId: authenticatedUser?.userName || '',
      namespaceId: selectedNamespaceId || '',
    });
    closeModal();
  };

  // Utility functions to perform actions on use states.
  // const handleAdditionalInstructionsUpdate = (instructions: string) => {
  //   setAdditionalInstructions(instructions);
  // };

  // const handleParameterChange = (parameter: ParameterType, isChecked: boolean) => {
  //   setSelectedParameters((prev) => (isChecked ? [...prev, parameter] : prev.filter((p) => p.id !== parameter.id)));
  // };

  const updateSelectedPolicies = (group: ComparisonGroupType, policy: PolicyMetadata, isSelected: boolean) => {
    setSelectedPolicies((prev) => ({
      ...prev,
      [group]: isSelected ? [...prev[group], policy] : prev[group].filter((p) => p.policyId !== policy.policyId),
    }));
  };

  const handleNamespaceIdSelection = (namespaceId: string) => {
    getPoliciesMetadata({
      userId: authenticatedUser?.userName || '',
      namespaceId: namespaceId,
    });
    setSelectedPolicies({
      [ComparisonGroupType.A]: [],
      [ComparisonGroupType.B]: [],
    });
  };

  const toggleMyTimeCheckbox = (): boolean => {
    let shouldDisable = false;
    if (selectedPolicies[ComparisonGroupType.A].length === 0) {
      shouldDisable = true;
    } else {
      const firstPolicy: PolicyMetadata = selectedPolicies[ComparisonGroupType.A][0];
      const firstDomain = firstPolicy?.domains?.[0];
      const firstCountry = firstPolicy.policyMetadataAttributes?.country;
      const allSameDomainAndCountry = selectedPolicies[ComparisonGroupType.A].every(
        (policy) => policy?.domains?.[0] === firstDomain && policy.policyMetadataAttributes?.country === firstCountry,
      );
      shouldDisable = !allSameDomainAndCountry;
    }
    if (shouldDisable && compareWithMyTimeCheckbox) {
      setCompareWithMyTimeCheckbox(false);
    }
    return shouldDisable;
  };

  const handleCompareWithMyTimeCheckboxUpdate = (): void => {
    if (selectedPolicies[ComparisonGroupType.B].length > 0) {
      setSelectedPolicies((prev) => ({ ...prev, [ComparisonGroupType.B]: [] }));
    }
    setCompareWithMyTimeCheckbox((prev) => !prev);
  };

  // Use Effect Hooks
  useEffect(() => {
    setSelectPoliciesValidationError(undefined);
    const groupAPolicyIds = selectedPolicies[ComparisonGroupType.A].map((p) => p.policyId);
    const groupBPolicyIds = selectedPolicies[ComparisonGroupType.B].map((p) => p.policyId);

    const duplicates = groupAPolicyIds.filter((id) => groupBPolicyIds.includes(id));

    if (duplicates.length > 0) {
      const duplicatePolicyNames = duplicates
        .map((id) => selectedPolicies[ComparisonGroupType.A].find((p) => p.policyId === id)?.name || id)
        .join(', ');

      setSelectPoliciesValidationError(`The following policies are selected in both groups: ${duplicatePolicyNames}`);
    }
  }, [selectedPolicies]);

  const isNextDisabledForSelectPoliciesStep = () => {
    if (
      selectedNamespaceId === undefined ||
      getPoliciesMetadataState.loading ||
      getPoliciesMetadataState.error ||
      selectPoliciesValidationError
    ) {
      return true;
    }

    if (compareWithMyTimeCheckbox) {
      return false;
    }

    if (selectedPolicies[ComparisonGroupType.A].length === 0 || selectedPolicies[ComparisonGroupType.B].length === 0) {
      return true;
    }
    return false;
  };

  const convertPolicyMetadataToPolicy = (): Policy[] => {
    const policies: Policy[] = [];

    Object.entries(selectedPolicies).forEach(([group, policyMetadataList]) => {
      const comparisonGroup = group as ComparisonGroupType;

      policyMetadataList.forEach((policyMetadata) => {
        const policy: Policy = {
          policyId: policyMetadata.policyId,
          version: policyMetadata.versionNumber,
          domains: policyMetadata.domains,
          name: policyMetadata.name,
          comparisonGroup: comparisonGroup,
          sourceType: policyMetadata.sourceType,
        };
        policies.push(policy);
      });
    });
    return policies;
  };

  const handleCreateExecutionOnSubmit = async (): Promise<void> => {
    logger.emitCountMetric({
      metricName: METRIC_NAME.CLICK,
      dimensions: getClickMetricDimensions(
        metricConfig.CreateExecutionWorkflow.componentName,
        metricConfig.CreateExecutionWorkflow.actions.CREATE_EXECUTION_SUBMIT,
      ),
    });
    if (compareWithMyTimeCheckbox) {
      setSubmittingExecution(true);
      const createPolicyMetadataRequests = selectedPolicies[ComparisonGroupType.A].map((policy) => ({
        namespaceId: selectedNamespaceId || '',
        name: `MyTime - ${policy.name}`,
        description: `MyTime policy created for comparison with ${policy.name}`,
        sourceType: SourceType.MY_TIME,
        domains: policy.domains,
        // TODO: Currently we are not adding any sources in my time policy, thats's why added a dummy UUID.
        trackingSources: [
          {
            referenceId: uuidv4().toString(),
            sourceType: SourceType.MY_TIME,
          },
        ],
        policyMetadataAttributes: policy.policyMetadataAttributes!,
        requesterAlias: authenticatedUser?.userName || '',
      }));

      try {
        const myTimePolicies: PolicyMetadata[] = await createPoliciesInBulk(createPolicyMetadataRequests);
        selectedPolicies.B = myTimePolicies; // TODO: Need to look into it, Not updating it via setState as the function implementation is already going on.
        setSubmittingExecution(false);
      } catch (error) {
        console.error('Failed to create MyTime policies:', error);
        setSubmittingExecution(false); // TODO: Add more robust error handling in case of failures.
        return;
      }
    }
    createExecution({
      namespaceId: selectedNamespaceId || '',
      namespaceVersion: workspaceContext.selectedWorkspace.namespaceVersion || 1,
      requesterAlias: authenticatedUser?.userName || '',
      policies: convertPolicyMetadataToPolicy(),
      executionType: workspaceContext.selectedWorkspace.isRisksEnabled
        ? ExecutionType.RISK_GENERATION
        : ExecutionType.ASYNCHRONOUS_COMPARISON,
      userInput: {
        // aspectIds: selectedParameters.map((parameter) => parameter.id),
        definitionIds: [], // TODO: This field is passed as empty, this will be handled in backend.
        additionalResources: [], // TODO: Add normal file upload option without creating policy metadata in create execution workflow.
        // additionalInstructions: additionalInstructions,
      },
    });
  };

  const isCreateExecutionAttempted = () => createExecutionState.isSuccess || createExecutionState.error;

  return {
    isModalOpen,
    openModal,
    closeModal,
    onModalSubmit,
    submittingExecution,
    selectedNamespaceId,
    createExecutionState,
    createExecution,
    getPoliciesMetadataState,
    handleNamespaceIdSelection,
    isNextDisabledForSelectPoliciesStep,
    selectedPolicies,
    updateSelectedPolicies,
    selectPoliciesValidationError,
    // additionalInstructions,
    // handleAdditionalInstructionsUpdate,
    // selectedParameters,
    // handleParameterChange,
    handleCreateExecutionOnSubmit,
    toggleMyTimeCheckbox,
    compareWithMyTimeCheckbox,
    handleCompareWithMyTimeCheckboxUpdate,
    policyExecutionResponse,
    isWorkflowLoading: executionId && version && !isCreateExecutionAttempted() ? isLoadingExecution : false,
  };
};
