import { HttpError, postData } from 'src/clients';
import { useMutation } from '@tanstack/react-query';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { GENERATE_TAGS_API } from 'src/paths';
import { GenerateTagsRequest, GenerateTagsResponse } from 'src/models/api-models/generateTags';

export const useGenerateTags = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
    reset,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());

  const [retryParams, setRetryParams] = useState<GenerateTagsRequest | undefined>(undefined);
  const path = GENERATE_TAGS_API();

  const generateTags = (generateTagsRequest: GenerateTagsRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path,
        method: 'POST',
        body: generateTagsRequest,
      },
      {
        onError: (err) => {
          setRetryParams({ ...generateTagsRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    generateTags(retryParams);
  };

  const resetGenerateTags = () => {
    reset();
    setRetryParams(undefined);
  };

  return {
    generateTagsState: {
      data: responseData as GenerateTagsResponse,
      loading,
      error: retryParams ? error : null,
      retry,
    },
    generateTags,
    resetGenerateTags,
  };
};
